import React, { useState } from "react";
import "./css/uiButton.css";

//icons
import ButtonWhiteOff from '../../../asset/svg/ButtonWhiteOff'
import ButtonWhiteOn from '../../../asset/svg/ButtonWhiteOn'


const UiButton = (props) => {
    const [show, setShow] = useState(false)
    return (
        <div className="buttonContainer"
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
        >
            <button
                className='buttonItem'
                style={{ backgroundColor: props.disabled ? 'rgb(255, 154, 154)' : 'red' , cursor: props.disabled && 'initial'}}
                onClick={props.press}
                type={'primary'}
                disabled={props.disabled}
            >
                {props.title}
                {
                    !props.disabled ?
                        !show ?
                            <ButtonWhiteOff className='buttonIcon'/>
                            :
                            <ButtonWhiteOn className='buttonIcon'/>
                        :
                        <ButtonWhiteOff className='buttonIcon'/>
                }
            </button>
        </div>
    );
}
export default UiButton;