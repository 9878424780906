import { useState } from "react";
import "./css/uiPromoImage.css"
import ButtonCloseOff from "../../../asset/svg/ButtonCloseOff";
import i18n from "../../../I18next";

export default function UiPromoImage(props){

    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);

    const getErrorImage = () => {
        return 'error ' + i18n.language.toUpperCase();
    }
  
    return (
        <div className={`uiPromoCarousel_container ${imageError ? getErrorImage() : (imageLoaded ? '' : 'loading')}`}
            onClick={() => props.onPromoClick()}
            >
            <img 
                className={`promoImage ${imageError || !imageLoaded ? 'hidden' : ''}`}
                key={props.key}
                src={props.src}
                onLoad={() => { 
                    setImageLoaded(true);
                }}
                onError={() => {
                    setImageError(true);
                }}
            />
            <div className={"closeOffButton"}>
                <ButtonCloseOff onClick={(event) => {
                    event.stopPropagation();
                    if(imageError){
                        setImageError(false);
                    }
                    setImageLoaded(false);
                    props.onDeletePromo();
                }}></ButtonCloseOff>
            </div>
        </div>
    )

}