import { PROPERTIES } from "../utils/properties";
//GENERIC SERVICES
import GenericServices from "../services/GenericServices";
import Utilities from "./utilities";

const bcAuthorize = {
  call: async (scope, acr) => {
    var querystring = require("querystring");
    let cid= Utilities.getCardId()
    let properties=[{"nam":"card_id","val":cid}]
    let bca=await GenericServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/bc-authorize",
      querystring.stringify({ scope: scope, acr_values: acr, properties:JSON.stringify(properties) })
    )
    return bca
  },
};

export default bcAuthorize;
