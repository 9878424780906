//Actions
const SET_TRANSACTIONS='web/transactions/SET_TRANSACTIONS'
const SET_LOADER='web/transactions/SET_LOADER'

//Actiond Set_Transactions creator
export function setTransactions(valueObj){
    return{
        type: SET_TRANSACTIONS,
        paylod:{
            transactionList:valueObj
        }
    }
}
export function setLoader(value){
    return {
        type: SET_LOADER,
        paylod:{
            isLoading:value
        }
    }
}
const INIT_STATE = {
    transactionList:null,
    isLoading:false,
}

//Reducer
export default function transactionsDuck(state = INIT_STATE, action){
    var newState
    switch (action.type) {
        case SET_TRANSACTIONS:
            newState = Object.assign({}, state)
            newState.transactionList = action.paylod.transactionList
            return newState;
        case SET_LOADER:
            newState = Object.assign({}, state)
            newState.isLoading = action.paylod.isLoading
            return newState;
        default:
            return state;
    }
}