import * as React from "react"

function MenuLogoutOffMobile(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#FFF" fillRule="nonzero" stroke="#FFF" strokeWidth={0.5}>
        <path d="M15.164 18.778a.523.523 0 00-.6.517v3.66H2.044V2.045h12.52V5.69c0 .261.188.496.444.533.323.047.6-.204.6-.517V1.523A.524.524 0 0015.086 1H1.522A.524.524 0 001 1.523v21.954c0 .288.235.523.522.523h13.564a.524.524 0 00.522-.523v-4.166a.535.535 0 00-.444-.533z" />
        <path d="M24.847 12.134L19.63 6.907a.524.524 0 00-.735.01.528.528 0 000 .727l4.325 4.333H7.276a.528.528 0 00-.527.434.521.521 0 00.517.612h15.959l-4.33 4.333a.517.517 0 00-.01.737c.198.21.531.214.735.01l.01-.01 5.217-5.227a.515.515 0 000-.732z" />
      </g>
    </svg>
  )
}

export default MenuLogoutOffMobile
