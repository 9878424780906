import React, { Component } from "react";
import axios from "axios";
import "./css/mfaLoginPopUp.css";

//IMAGE
import biometry from "../../../asset/img/biometry.png";
//UI COMPONENT
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
//PROPERTIES
import { PROPERTIES } from "../../../utils/properties";
//TRANSLATIONS
import i18n from "../../../I18next";
//MOMENTJS
import moment from "moment";

export default class MFALoginPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pollingBreak = false;
  }

  goToOTP = async () => {
    if (this.props.isFromEPin) {
      this.props.ePinGoToOTP();
    } else {
      this.props.isLoading(true);
      let querystring = require("querystring");
      await axios({
        method: "post",
        url: "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/user/login",
        data: querystring.stringify({
          camsMember: PROPERTIES.FLEXI_ID_PREFIX + this.props.flexiGelId,
          code: this.props.pw,
          acr: "sms",
        }),
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          OriginApp: "SKP",
          Language: "" + i18n.language.toUpperCase(),
        },
      })
        .then((response) => {
          if (response?.status === 200) {
            this.props.isLoading(false);
            this.props.setProgressive(
              "_succes_Login",
              "sms",
              response.data.auth_req_id,
              response.data.phoneNumber
            );
            let obj = {
              access_token: response.data.enroll_token,
            };
            // Set session Storage:
            //Token
            window.sessionStorage.setItem("logged", JSON.stringify(obj));
            // //Time to Live
            // let date = moment().format('DD')
            // let time = moment().add(5, 'minutes').format('LTS')
            // obj = {
            //   DAY: date,
            //   TTL: time
            // }
            // window.sessionStorage.setItem("TTL", JSON.stringify(obj));
          }
        })
        .catch((error) => {
          if (typeof error.response === "undefined") {
            this.props.isLoading(false);
            //Cors error detect
            let isCorse = true;
            this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
          } else {
            if (
              error?.response.status === 500 ||
              (error?.response.status > 399 && error?.response.status < 405)
            ) {
              this.props.isLoading(false);
              let title = "";
              let modalText = "";

              //Omega control
              if (error?.response.data?.errorMessage?.split("Ω").length === 2) {
                let message = error?.response.data?.errorMessage.split("Ω");
                title = message[0];
                modalText = message[1];
              } else {
                //"500 null" CONTROLL message
                if (
                  error?.response.data.errorMessage === "500 null" ||
                  error?.response.data?.errorMessage === undefined ||
                  error?.response.data?.errorMessage.substring(0, 21) ===
                    "Internal server error"
                ) {
                  modalText = i18n.t("Error.NewOtp_generic");
                } else {
                  modalText = error?.response.data.errorMessage;
                }
              }
              let isCorse = false;
              this.props.setError(modalText, isCorse, title);
            }
          }
        });
    }
  };

  pollingToken = async () => {
    if (this.pollingBreak === false) {
      let querystring = require("querystring");
      let TokenPollingCall = await ActivationServices.post(
        "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
        querystring.stringify({
          auth_req_id: this.props.auth_req_id,
          grant_type: "auth_req_id",
        })
      );
      if (TokenPollingCall.status === 667) {
        let isCorse = true;
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
      }
      if (TokenPollingCall.status === 200) {
        //Not confirmed
        if (TokenPollingCall.data?.pending_response) {
          //Pending
          if (TokenPollingCall.data.pending_response.message === "PENDING") {
            setTimeout(() => {
              this.pollingToken();
            }, 200);
          }
          //Expired
          if (TokenPollingCall.data.pending_response.message === "EXPIRED") {
            let isCorse = true;
            this.props.setError(
              i18n.t("Error.TimeIsUp"),
              isCorse,
              i18n.t("Error.ExpiredRequest")
            );
          }
        }
        //Confirmed
        else {
          if (this.props.isFromEPin) {
            this.props.setEPinCall();
          } else {
            let obj = {
              access_token: TokenPollingCall.data.access_token,
              refresh_token: TokenPollingCall.data.refresh_token,
            };
            window.sessionStorage.setItem("logged", JSON.stringify(obj));
            //Time to Live
            let date = moment().format("DD");
            let time = moment().add(5, "minutes").format("LTS");
            obj = {
              DAY: date,
              TTL: time,
            };
            window.sessionStorage.setItem("TTL", JSON.stringify(obj));
            await this.props.setEPinTest();
          }
        }
      }
    }
  };

  componentDidMount() {
    this.pollingToken();
  }
  componentWillUnmount() {
    this.pollingBreak = true;
  }

  render() {
    return (
      <div
        className="otpInputCardContainer"
        style={{ display: this.props.display && "none" }}
      >
        <div style={{ textAlign: "left" }}>
          <UiCardTitle text={i18n.t("PopUp.SignIn")} />
          <label
            className="FMApopDescription"
            style={{ marginBottom: "1rem", fontFamily: "Sparkasse_Rg" }}
          >
            {i18n.t("PopUp.mfaLoginDescription")}
          </label>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <img alt="Biometry" className="MFAImgContainer" src={biometry} />
        </div>
        <button
          className="link_Button  "
          onClick={() => {
            this.pollingBreak = true;
            this.goToOTP();
          }}
          style={{ margin: "1rem 0" }}
        >
          {i18n.t("PopUp.mfaLink")}
        </button>
      </div>
    );
  }
}
