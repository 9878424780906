import * as React from "react"

function ImgOk(props) {
  return (
    <svg
      width={90}
      height={90}
      viewBox="0 0 90 90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" stroke="red" fill="none">
        <path
          d="M44.967 2.5c11.708 0 22.324 4.764 30.013 12.453 7.69 7.69 12.453 18.306 12.453 30.014 0 11.708-4.763 22.324-12.453 30.013-7.689 7.69-18.305 12.453-30.013 12.453-11.708 0-22.324-4.763-30.014-12.453C7.263 67.29 2.5 56.675 2.5 44.967c0-11.708 4.764-22.324 12.453-30.014C22.643 7.263 33.26 2.5 44.967 2.5z"
          strokeWidth={5}
          fill="#FFF"
        />
        <path
          d="M40.375 58.56l-12.9-12.9a1.73 1.73 0 012.445-2.446l11.87 11.87h0L64.143 32.73a1.73 1.73 0 112.446 2.445L43.204 58.56a2 2 0 01-2.829 0s0 0 0 0z"
          strokeWidth={2}
          fill="red"
        />
      </g>
    </svg>
  )
}

export default ImgOk
