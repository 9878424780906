const BASE_URL_NPC = "siabridge.npc.sia.eu/sb";
const BASE_URL_OBP = "siabridge.obp.sia.eu/sb";
const BASE_URL_PROMO = "https://" + BASE_URL_NPC + "/sbpromo/";
const BASE_URL_PROMO_SEARCH_PROMOTION = BASE_URL_PROMO + "v1/promotion-banners";
const BASE_URL_PROMO_DELETE_PROMOTION = BASE_URL_PROMO + "v1/promotion-banners-closed/";
const TOKEN_REFRESHED = "token_refreshed";
const CARD_FRAME_NAME = "card_frame";
const FLEXI_ID_PREFIX = "5";



const PROPERTIES = {
    BASE_URL_NPC: BASE_URL_NPC,
    BASE_URL_OBP: BASE_URL_OBP,

    //siabridge-promotion
    BASE_URL_PROMO: BASE_URL_PROMO,
    BASE_URL_PROMO_SEARCH_PROMOTION: BASE_URL_PROMO_SEARCH_PROMOTION,
    BASE_URL_PROMO_DELETE_PROMOTION: BASE_URL_PROMO_DELETE_PROMOTION,

    TOKEN_REFRESHED:TOKEN_REFRESHED,
    CARD_FRAME_NAME:CARD_FRAME_NAME,
    FLEXI_ID_PREFIX: FLEXI_ID_PREFIX
};


export {PROPERTIES};
  