import * as React from "react"

function MenuHomeOn(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"MenuHomeOn"}</title>
      <path
        d="M12.732 3.172l9.31 9.724H20.79c-.48 0-.873.405-.873.9V22.2H15.99v-6.152c0-.495-.393-.9-.873-.9H9.736c-.48 0-.873.405-.873.9v6.152H4.645v-8.403c0-.495-.392-.9-.872-.9h-.757l9.716-9.724zM.864 14.696H2.9V23.1c0 .495.393.9.873.9h5.963c.48 0 .873-.405.873-.9v-6.153h3.636V23.1c0 .495.393.9.873.9h5.673c.48 0 .872-.405.872-.9v-8.404h2.473c.35 0 .669-.225.8-.555a.925.925 0 00-.19-.975l-11.374-11.9a.85.85 0 00-1.221-.014L.267 13.15a.921.921 0 00-.203.99.85.85 0 00.8.555h0z"
        fill="red"
        stroke="#FFF"
        strokeWidth={0.5}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default MenuHomeOn
