import * as React from "react"

function ButtonOnTop(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"ButtonOnTop"}</title>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle
          stroke="red"
          strokeWidth={1.4}
          fill="red"
          cx={10}
          cy={10}
          r={10}
        />
        <path
          d="M6.54 11.024a.261.261 0 00-.072.184c0 .07.024.133.072.184a.24.24 0 00.35 0l3.078-3.233 3.077 3.233a.24.24 0 00.35 0 .27.27 0 000-.368l-3.252-3.416a.24.24 0 00-.35 0L6.54 11.024z"
          stroke="#FFF"
          strokeWidth={0.9}
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default ButtonOnTop
