//Action
const SET_SMS = "web/iframeComunication/SET_SMS";
const INIT_SMS = "web/iframeComunication/INIT_SMS";
const SET_CLOSE = "web/iframeComunication/SET_CLOSE";
const INIT_CLOSE = "web/iframeComunication/INIT_CLOSE";
const SET_LOADING = "web/iframeComunication/SET_LOADING";
const INIT_LOADING = "web/iframeComunication/INIT_LOADING";
const SET_NEWCODE = "web/iframeComunication/SET_NEWCODE";
const INIT_NEWCODE = "web/iframeComunication/INIT_NEWCODE";

//Action Set_ScopeList Creators
export function setSMS(value) {
  return {
    type: SET_SMS,
    paylod: {
      sms: value,
    },
  };
}

//Action init_ScopeList Creators
export function initSMS() {
  return {
    type: INIT_SMS,
    paylod: {
      sms: false,
    },
  };
}

export function setCloseBiometry(value) {
  return {
    type: SET_CLOSE,
    paylod: {
      close: value,
    },
  };
}

export function initCloseBiometry() {
  return {
    type: INIT_CLOSE,
    paylod: {
      close: false,
    },
  };
}
export function setLoading(value) {
  return {
    type: SET_LOADING,
    paylod: {
      loading: value,
    },
  };
}

export function initLoading() {
  return {
    type: INIT_LOADING,
    paylod: {
      loading: false,
    },
  };
}
export function setNewCode(value) {
  return {
    type: SET_NEWCODE,
    paylod: {
      sendNewCode: value,
    },
  };
}
export function initNewCode() {
  return {
    type: INIT_NEWCODE,
    paylod: {
      sendNewCode: false,
    },
  };
}

const INIT_STATE = {
  sms: false,
  close: false,
  loading: false,
  sendNewCode: false,
};

//Reducer
export default function iframeComunicationDuck(state = INIT_STATE, action) {
  var newState;
  switch (action.type) {
    case SET_SMS:
      newState = Object.assign({}, state);
      newState.sms = action.paylod.sms;
      return newState;
    case INIT_SMS:
      newState = Object.assign({}, state);
      newState.sms = false;
      return newState;
    case SET_CLOSE:
      newState = Object.assign({}, state);
      newState.close = action.paylod.close;
      return newState;
    case INIT_CLOSE:
      newState = Object.assign({}, state);
      newState.close = false;
      return newState;
    case SET_LOADING:
      newState = Object.assign({}, state);
      newState.loading = action.paylod.loading;
      return newState;
    case INIT_LOADING:
      newState = Object.assign({}, state);
      newState.loading = false;
      return newState;
    case SET_NEWCODE:
      newState = Object.assign({}, state);
      newState.sendNewCode = action.paylod.sendNewCode;
      return newState;
    case INIT_NEWCODE:
      newState = Object.assign({}, state);
      newState.sendNewCode = false;
      return newState;

    default:
      return state;
  }
}
