import React, { useRef, useEffect, useState } from "react";
import axios from "axios/index";

// REDUX
import { connect } from "react-redux";
import { initBcAuth, setBcAuth } from "../../redux/ducks/bcAuthDuck";
import { setChangePinView } from "../../redux/ducks/navigationDuck";
import {
  initCloseBiometry,
  initSMS,
  initLoading,
  setLoading,
} from "../../redux/ducks/iframeComunicationDuck";
//TRANSLATIONS
import i18n from "../../I18next";
//Utils
import Utilities from "../../utils/utilities";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
import Spinner1 from "./Spinner1";
import "./css/iframePinParent.css";

const IframePinParent = (props) => {
  const iFrameRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [iframe, setIframe] = useState(props.iframePinData);

  // linsten message FROM iframe Child
  useEffect(async () => {
    window.addEventListener("message", function (e) {
      props.dispatch(initBcAuth());
      if (e.data === "CloseBiometry") {
        props.dispatch(initSMS());
      } else if (e.data === "pollingExpired") {
        // polling expired
        props.setError(
          i18n.t("Error.TimeIsUp"),
          false,
          i18n.t("Error.ExpiredRequest")
        );

      } else if (e.data === "expiredToken") {
        props.setError(i18n.t("Error.NewOtp_generic"));
        props.dispatch(initLoading());
        //Close changepin View
        props.dispatch(setChangePinView(false));
      } else if (e.data === "Success") {
        //Success
        props.dispatch(setChangePinView(false));
        props.onSuccess();
        props.dispatch(initLoading());
      } else if (e.data === "DifferentPIN") {
        //Errors
        props.setError(
          i18n.t("Error.DifferentPIN"),
          false,
          i18n.t("Error.IncorrectEntries")
        );
      } else if (e.data === "ShortPIN") {
        props.setError(
          i18n.t("Error.ShortPIN"),
          false,
          i18n.t("Error.IncorrectEntries")
        );
      } else if (e.data === "genericError") {
        props.setError(i18n.t("Error.NewOtp_generic"));

      } else if (e.data === "postError") {
        props.setError(i18n.t("Error.NewOtp_generic"));
      } else if (e.data === "startLoading") {
        props.dispatch(setLoading(true));
      } else {
        e.data.type = "ViewPin";
        e.data.title = i18n.t("PopUp.GetChangeCardPin");
        e.data.description = i18n.t("PopUp.mfaGetChangeCardPinDescription");
        // open biometryPopUp whith data (acr, athreq, scope)
        props.dispatch(setBcAuth(e.data));
        props.dispatch(initLoading());
      }
    });
  }, []);

  // listen message TO SEND to ifame Child
  useEffect(() => {
    if (props.iframeComunicationDuck.sms) {
      //acr changed by BiometryPopUp
      let data = {
        acr: "sms",
      };
      window.frames[0].frameElement.contentWindow.postMessage(data, "*"); // comunication with iframe
      props.dispatch(initSMS());
    }
    if (props.iframeComunicationDuck.close) {
      // BiometryPopUP closed
      let data = {
        close: true,
      };
      window.frames[0].frameElement.contentWindow.postMessage(data, "*"); // comunication with iframe
      props.dispatch(initCloseBiometry());
    }
  });

  //Change Language behavior
  useEffect(async () => {
    // console.log("Inside change card pin change language logic")
    setIsReady(false);

    await axios({
      method: "get",
      url:
        "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcardimage/v1/getPinBlockChangePage/" +
        props.cardId,
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${props.changePinSpecialToken}`,
        Language: "" + i18n.language.toUpperCase(),
      },
    })
      .catch((error) => {
        if (typeof error.response === "undefined") {
          props.setError(i18n.t("Error.CorsRedirect"), true);
        } else {
          let testError = Utilities.callErrorTest(error.response);
          if (testError !== false)
            props.setError(
              testError.textMessage,
              testError.isCorse,
              testError.title
            );
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          setIframe(response.data);
          setIsReady(true);
        }
      });
  }, [i18n.language.toUpperCase()]);

  return (
    <div className="iframePinContainer">
      {!isReady ? (
        <Spinner1 className="cs_secondondarySpinner_Continer" />
      ) : (
        <iframe
          ref={iFrameRef}
          onLoad={() => setIsReady(true)}
          srcDoc={iframe}
          title="Child  Pin iframe"
          className="iframePinWindow"
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  bcAuthDuck: state.bcAuthDuck,
  iframeComunicationDuck: state.iframeComunicationDuck,
});

export default connect(mapStateToProps)(IframePinParent);
