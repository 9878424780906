import React, { Component } from "react";
//LOGIN VIEW COMPONENT
import Welcome from "../component/classComponent/LoginView/Welcome";
import CreatePassword from "../component/classComponent/LoginView/CreatePassword";
import Activation from "../component/classComponent/LoginView/Activation";
import SendNewCode from "../component/classComponent/LoginView/SendNewCode";
import CorrectOTP from "../component/classComponent/LoginView/CorrectOTP";
import SendNewEmail from "../component/classComponent/LoginView/SendNewEmail";
import Congratulation from "../component/classComponent/LoginView/Congratulation";
//LOGIN POP-UP COMPONENT
import MFALoginPopUp from "../component/classComponent/LoginView/MFALoginPopUp";
import SMSLoginPopUp from "../component/classComponent/LoginView/SMSLoginPopUp";
//LOGIN BANNER COMPONENT
import MobileBanner from "../component/funcComponent/MobileBanner";
// USELESS LOGIN VIEW COMPONENT
import EPIN from "../component/classComponent/LoginView/useless/EPIN";
import SuccessEPIN from "../component/classComponent/LoginView/useless/SuccessEPIN";
import LogTermAndCondition from "../component/classComponent/LoginView/useless/LogTermAndCondition";
//FOOTER COMPONENT
import Footer from "../component/funcComponent/UiComponents/UiFooter";
// REDUX
import { connect } from "react-redux";
import { setScopeList } from "../redux/ducks/scopeListDuck";
//TRANSLATIONS
import { withTranslation } from "react-i18next";
import LangButton from "../component/funcComponent/UiComponents/UiLangButton";
//STYLE
import "./css/login.css";
//IMAGES
import LogoHome from "../asset/svg/LogoHome";
//Utils
import Utilities from "../utils/utilities";
//GENERICSERVICES
import GenericServices from "../services/GenericServices";
import { PROPERTIES } from "../utils/properties";
// AXIOS
import axios from "axios";
// BCAUTHORIZE
import bcAuthorize from "../utils/bcAuthorize";
//UICOMPONENT
import UiModal from "../component/funcComponent/UiComponents/UiModal";
import i18n from "../I18next";
//IMG
import SpinnerLogin from "../component/funcComponent/SpinnerLogin";
import ServiceInformationPopup from "../component/funcComponent/ServiceInformationPopup";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      isMobile: false,
      size:window.innerWidth,
      url: "",
      mobilePhone: "",
      email: "",
      auth_req_id: "",
      acr: "",
      popUpModal: false,
      flexiGelId: "",
      pw: "",
      isForget: "",
      //E-Pin states
      cardVbv: "",
      cardID: "",
      ePinData: "",
      cardInfoCall: "",
      isFromEPin: false,
      //Spinner
      pageIsLoading: false,
      TCisLoading: false,
      //UiModal error
      showModalError: false,
      textModalError: "",
      titleModalError: "",
      forceLogOut: false,
      //BackModalError
      showModalBack: false,
      //Card status modal
      showCardStatusModal: false,
      cardStatus: "",
      //PrevTest
      prevProgress: 0,
      //CertifyContacts
      certifyContacts_Modal: false,
      certifyContacts_trigger:false,
      isCertifyContacts:false,
      reloadLanguage: false
    };
  }

  detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  componentDidMount = async () => {
    window.sessionStorage.clear();
    if (!Utilities.sessionStorageControl()) {
      this.setState({ isMobile: this.detectMob() });
    } else {
      // this.props.history.push("/home");
    }
  };
  componentDidUpdate = () => {
    //GoBack Browser Controll
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = (e) => {
      e.preventDefault();
      if (this.state.progress !== 0) {
        this.setState({
          showModalBack: true,
        });
        window.sessionStorage.clear();
      }
    };
  };

  getScopeList = async () => {
    //Scope List to save in redux for OTP Controll CAll
    let contentId = "9938e9dc-3f1c-4f2f-bbfb-641658ec05b2";
    let scopeListCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_NPC +
      "/sbcontent/v1/content/" +
      contentId +
      "/digest/1234"
    );
    if (scopeListCall.status === 200) {
      let scopeListOBJ = JSON.parse(scopeListCall.data.content);
      this.props.dispatch(setScopeList(scopeListOBJ.SKP));
    }
    if (scopeListCall.status === 400 || scopeListCall.status === 500) {
      let title = ""
      let modalText = "";

      //Omega control
      if (scopeListCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = scopeListCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          scopeListCall.data.errorMessage === "500 null" ||
          scopeListCall.data?.errorMessage === undefined ||
          scopeListCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = scopeListCall.data.errorMessage;
        }
      }
      this.setState({
        textModalError: modalText,
        titleModalError: title,
        showModalError: true,
        forceLogOut: true,
      });
    }
    if (scopeListCall.status === 667) {
      this.setState({
        textModalError: i18n.t("Error.CorsRedirect"),
        showModal: true,
        forceLogOut: true,
      });
    }
  };

  setEPinTest = async () => {
    this.setState({
      pageIsLoading: true,
    });
    if (!this.props.scopeListDuck.scopeList) {
      this.getScopeList();
    }

    //Card CAll
    let cardInfoCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbcams/v1/card?accountNumber="+ PROPERTIES.FLEXI_ID_PREFIX +
      this.state.flexiGelId
    );
    if (cardInfoCall.status === 667 || cardInfoCall.status === 666) {
      this.setState({
        textModalError: cardInfoCall.data.errorMessage,
        showModalError: true,
        forceLogOut: true,
        pageIsLoading: false,
      });
    }
    if (cardInfoCall.status === 200) {
      let cardID = cardInfoCall.data.card[0].cardInfo[0].cardId;
      window.sessionStorage.setItem("CID", JSON.stringify({ cid: cardID }));

      //Specific card status call
      let cardStatus
      let cardStatusCall = await GenericServices.get(
          "https://" +
            PROPERTIES.BASE_URL_OBP +
            "/sbcams/v1/card/" +
            cardID +
            "/state"
        );
        if (cardStatusCall.status === 200) {
          cardStatus = cardStatusCall.data.state;
        }else{
          cardStatus = cardInfoCall.data.card[0].cardInfo[0].cardStatus;
        }
        if (cardStatusCall.status === 667 || cardStatusCall.status === 666) {
          this.setState({
            textModalError: cardInfoCall.data.errorMessage,
            showModalError: true,
            forceLogOut: true,
            pageIsLoading: false,
          });
        }
        if (cardStatusCall.status === 400 || cardStatusCall.status === 500) {
          let title = ""
          let modalText = "";
    
          //Omega control
          if (cardStatusCall.data?.errorMessage?.split("Ω").length === 2) {
            let message = cardStatusCall.data?.errorMessage.split("Ω")
            title = message[0]
            modalText = message[1]
          } else {
            //"500 null" CONTROLL message for cardStatusCall
            if (
              cardStatusCall.data.errorMessage === "500 null" ||
              cardStatusCall.data?.errorMessage === undefined ||
              cardStatusCall.data?.errorMessage.substring(0, 21) === "Internal server error"
            ) {
              modalText = i18n.t("Error.NewOtp_generic");
            } else {
              modalText = cardStatusCall.data.errorMessage;
            }
          }
          this.setState({
            textModalError: modalText,
            titleModalError: title,
            showModalError: true,
            forceLogOut: true,
            pageIsLoading: false,
          });
        }
       

      //Card status control to detect UK, CC and FP values
      let test = Utilities.cardStatusController(cardStatus);
      if (test.isNoAccess) {
        this.setState({
          showCardStatusModal: true,
          cardStatus: cardStatus
        });
      } else {
        //CardStatus for e-Pin  Verification
        let ePinStatus = await GenericServices.get(
          "https://" +
          PROPERTIES.BASE_URL_OBP +
          "/sbacs/v1/" +
          cardID +
          "/cardstatus"
        );
        if (ePinStatus.status === 200) {
          //Align card info with card status 
          let cardInfo=cardInfoCall.data
          cardInfo.card[0].cardInfo[0].cardStatus=cardStatus

          if (!ePinStatus.data.setPin) {
            this.setState({
              isFromEPin: true,
              cardVbv: ePinStatus.data.cardVbvStatus,
              cardID,
              cardInfoCall: cardInfo,
              progress: "_set_EPIN",
              pageIsLoading: false,
            });
          } else {
            this.setState({ pageIsLoading: false });
            this.props.history.push("/home", { data: cardInfo });
          }
        }
        if (ePinStatus.status === 400 || ePinStatus.status === 500) {
          let title = ""
          let modalText = "";

          //Omega control
          if (ePinStatus.data?.errorMessage?.split("Ω").length === 2) {
            let message = ePinStatus.data?.errorMessage.split("Ω")
            title = message[0]
            modalText = message[1]
          } else {
            //"500 null" CONTROLL message for epinstatus call

            if (
              ePinStatus.data.errorMessage === "500 null" ||
              ePinStatus.data?.errorMessage === undefined ||
              ePinStatus.data?.errorMessage.substring(0, 21) === "Internal server error"
            ) {
              modalText = i18n.t("Error.NewOtp_generic");
            } else {
              modalText = ePinStatus.data.errorMessage;
            }
          }
          this.setState({
            textModalError: modalText,
            titleModalError: title,
            showModalError: true,
            forceLogOut: true,
            pageIsLoading: false,
          });
        }
        if (ePinStatus.status === 667 || ePinStatus.status === 666) {
          this.setState({
            textModalError: ePinStatus.data.errorMessage,
            showModalError: true,
            forceLogOut: true,
            pageIsLoading: false,
          });
        }
      }
    }
    if (cardInfoCall.status === 400 || cardInfoCall.status === 500) {
      let title = ""
      let modalText = "";

      //Omega control
      if (cardInfoCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = cardInfoCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message for cardInfocall
        if (
          cardInfoCall.data.errorMessage === "500 null" ||
          cardInfoCall.data?.errorMessage === undefined ||
          cardInfoCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = cardInfoCall.data.errorMessage;
        }
      }
      this.setState({
        textModalError: modalText,
        titleModalError: title,
        showModalError: true,
        forceLogOut: true,
        pageIsLoading: false,
      });
    }
  };

  ePinGoToOTP = async () => {
    this.setState({ pageIsLoading: true });
    let BCACall = await bcAuthorize.call(this.state.ePinData.scope, "sms");
    if (BCACall.status === 200) {
      this.setState({
        progress: 108,
        acr: BCACall.data.acr,
        auth_req_id: BCACall.data.auth_req_id,
        mobilePhone: BCACall.data.phoneNumber,
        pageIsLoading: false,
      });
    }
    if (BCACall.status === 400 || BCACall.status === 500) {
      let title = ""
      let modalText = "";

      //Omega control
      if (BCACall.data?.errorMessage?.split("Ω").length === 2) {
        let message = BCACall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          BCACall.data.errorMessage === "500 null" ||
          BCACall.data?.errorMessage === undefined ||
          BCACall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = BCACall.data.errorMessage;
        }
      }
      this.setState({
        textModalError: modalText,
        titleModalError: title,
        showModalError: true,
        forceLogOut: true,
        pageIsLoading: false,
      });
    }
  };

  setEPinCall = async () => {
    const { access_token } = Utilities.getToken();
    let ePinCall = await axios({
      method: this.state.ePinData.method,
      url: this.state.ePinData.url,
      data: this.state.ePinData.data,
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (ePinCall.status === 200) {
      this.setState({
        progress: 109,
        pageIsLoading: false,
      });
    }
    if (ePinCall.status === 400 || ePinCall.status === 500) {
      let title = ""
      let modalText = "";

      //Omega control
      if (ePinCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = ePinCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          ePinCall.data.errorMessage === "500 null" ||
          ePinCall.data?.errorMessage === undefined ||
          ePinCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = ePinCall.data.errorMessage;
        }
      }
      this.setState({
        textModalError: modalText,
        titleModalError: title,
        showModalError: true,
        pageIsLoading: false,
      });
    }
    if (ePinCall.status === 667) {
      this.setState({
        textModalError: i18n.t("Error.CorsRedirect"),
        showModalError: true,
        forceLogOut: true,
        pageIsLoading: false,
      });
    }
  };
  certifyContactsFunc = () => {
    this.setState({
      certifyContacts_Modal: false,
      pageIsLoading: false,
      certifyContacts_trigger:true,
    })
    //Now do the web_registrationClientcall and after the start call  with 2 queryParameter (flexigeldID and certify_contacts:true)
  }

  render() {
    return (
      <div
        className={`loginMainContainer 
        ${(this.state.progress === "_succes_Login" ||
            this.state.progress === 108) &&
          "modal"
          }`}
      >
        {
          //MOBILE BANNER CONTROLL
          this.state.isMobile && (
            <MobileBanner
              closeBannerFunc={() => {
                this.setState({ isMobile: false });
              }}
            />
          )
        }

        <div className={`loginWrapLogo ${(this.state.size<400 && this.state.progress === "_WAIT_FOR_CUSTOMER_VERIFICATION")&& "iphone6" }`}>
          <div
            className={`login_Background_Logo_Container 
          ${(this.state.progress === "_succes_Login" ||
                this.state.progress === 108) &&
              "modal"
              }`}
          >
            <div
              className="LogoContainer"
              onClick={() => {
                if (this.state.progress !== 0) {
                  this.setState({ showModalBack: true });
                  window.sessionStorage.clear();
                }
              }}
            >
              <LogoHome />
            </div>
            <LangButton reload={this.state.reloadLanguage} languageReloaded={() =>
              this.setState({reloadLanguage:false})
            } />
          </div>

          {/* SPINNER */}
          {(this.state.pageIsLoading || this.state.TCisLoading) && (
            <div className="loaderContainer">
              <SpinnerLogin className="spinner" />
            </div>
          )}

          {/* OVERLAY */}
          {(this.state.progress === "_succes_Login" ||
            this.state.progress === 108) &&
            !this.state.pageIsLoading && <div className="overlay" />}

          <div
            className={`loginCardContainer ${(this.state.progress === "_succes_Login" ||
              this.state.progress === 108) &&
              "modal"
              }`}
          >
            <div
              className={`loginCard ${(this.state.progress === "_succes_Login" ||
                this.state.progress === 108) &&
                "modal"
                }`}
            >
              {
                //First Login screen (Welcome)
                this.state.progress === 0 && (
                  <Welcome
                    //  {...this.props}
                    successLog={(value, acr, id, phone, flexiGelId, pw) => {
                      this.setState({
                        progress: value,
                        acr: acr,
                        auth_req_id: id,
                        mobilePhone: phone,
                        popUpModal: true,
                        flexiGelId: flexiGelId,
                        pw: pw,
                      });
                    }}
                    display={this.state.pageIsLoading}
                    setProgressive={(value, url, isforget) => {
                      this.setState({
                        progress: value,
                        url: url,
                        isForget: isforget,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    setCertifyContacts={() => { this.setState({ certifyContacts_Modal: true}) }}
                    certifyContacts_trigger={this.state.certifyContacts_trigger?this.state.certifyContacts_trigger:false}
                    setCertifyContactsTrigger={(value)=>{this.setState({certifyContacts_trigger:value, isCertifyContacts:true})}}
                    setCertifyContactsToOTP={(auth_req_id, mobilePhone)=>{this.setState({auth_req_id: auth_req_id, mobilePhone: mobilePhone})}}
                  />
                )
              }

              {
                // Third Login screen (Create Password)
                this.state.progress === "_WAIT_FOR_CUSTOMER_VERIFICATION" && (
                  <CreatePassword
                    url={this.state.url}
                    isForget={this.state.isForget}
                    setProgressive={(value, phone, auReId) => {
                      this.setState({
                        progress: value,
                        mobilePhone: Utilities.maskerNumber(phone),
                        auth_req_id: auReId,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    display={this.state.pageIsLoading}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                  />
                )
              }
              {
                //Fourth Login screen (Activation--> OTP)
                this.state.progress === "_WAIT_FOR_OTP" && (
                  <Activation
                    userPhone={this.state.mobilePhone}
                    url={this.state.url}
                    auth_req_id={this.state.auth_req_id}
                    setProgressive={(value, mail) => {
                      this.setState({
                        progress: value,
                        email: Utilities.maskerMail(mail),
                        prevProgress: this.state.progress,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                  />
                )
              }
              {
                //OPTIONAL SCREEN Send a new code (Mobile) Screen
                this.state.progress === 4 && (
                  <SendNewCode
                    isFromEPin={this.state.isFromEPin}
                    url={this.state.url}
                    auth_req_id={this.state.auth_req_id}
                    popUpModal={this.state.popUpModal}
                    setProgressive={(value) => {
                      this.setState({ progress: value });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                    goBack={() =>
                      this.setState({ progress: this.state.prevProgress })
                    }
                  />
                )
              }
              {
                //Sixth Login screen (Correct OTP)
                this.state.progress === "_WAIT_FOR_EMAIL_LINK" && (
                  <CorrectOTP
                    userMail={this.state.email}
                    url={this.state.url}
                    setProgressive={(value) => {
                      this.setState({
                        progress: value,
                        prevProgress: this.state.progress,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                  />
                )
              }
              {
                // OPTIONAL SCREEN Send a New Email Screen
                this.state.progress === 6 && (
                  <SendNewEmail
                    url={this.state.url}
                    setProgressive={(value) => {
                      this.setState({ progress: value });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                    goBack={() =>
                      this.setState({ progress: this.state.prevProgress })
                    }
                  />
                )
              }

              {
                //eigth Login Screen (Congratulation)
                this.state.progress === "_END_OF_WORKFLOW" && (
                  <Congratulation
                    isForget={this.state.isForget}
                    isCertifyContacts={this.state.isCertifyContacts}
                    setProgressive={(value) => {
                      this.setState({ progress: value });
                    }}
                  />
                )
              }
              {/*    ------------    END OF LOGIN SCREEN ------------    */}

              {/*    ------------- START  SUCCES LOGIN START  -------------- */}
              {this.state.progress === "_succes_Login" &&
                this.state.acr === "mfa" && (
                  <MFALoginPopUp
                    flexiGelId={this.state.flexiGelId}
                    pw={this.state.pw}
                    auth_req_id={this.state.auth_req_id}
                    setEPinTest={() => this.setEPinTest()}
                    setProgressive={(value, acr, id, phone) => {
                      this.setState({
                        progress: value,
                        acr: acr,
                        auth_req_id: id,
                        mobilePhone: phone,
                        popUpModal: true,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                  />
                )}

              {this.state.progress === "_succes_Login" &&
                this.state.acr === "sms" && (
                  <SMSLoginPopUp
                    auth_req_id={this.state.auth_req_id}
                    phone={this.state.mobilePhone}
                    setEPinTest={() => this.setEPinTest()}
                    setProgressive={(value) => {
                      this.setState({
                        progress: value,
                        prevProgress: this.state.progress,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                  />
                )}

              {/*    ------------- END   SUCCES LOGIN START  -------------- */}

              {
                // Login Screen (e-Pin)
                this.state.progress === "_set_EPIN" && (
                  <EPIN
                    cardVbv={this.state.cardVbv}
                    cardID={this.state.cardID}
                    setProgressive={(
                      value,
                      acr,
                      auth_req_id,
                      phone,
                      ePinData
                    ) => {
                      this.setState({
                        progress: value,
                        acr,
                        auth_req_id,
                        mobilePhone: phone,
                        ePinData,
                      });
                    }}
                    isLoading={(value) => {
                      this.setState({
                        pageIsLoading: value,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading}
                  />
                )
              }

              {/* -------------------- FROM EPIN ------------------ */}
              {this.state.progress === 108 && this.state.acr === "mfa" && (
                <MFALoginPopUp
                  isFromEPin={true}
                  setEPinCall={() => this.setEPinCall()}
                  ePinGoToOTP={() => this.ePinGoToOTP()}
                  auth_req_id={this.state.auth_req_id}
                  setProgressive={(value, acr, id, phone) => {
                    this.setState({
                      progress: value,
                      acr: acr,
                      auth_req_id: id,
                      mobilePhone: phone,
                      popUpModal: true,
                    });
                  }}
                  isLoading={() => {
                    this.setState({
                      progress: "loading",
                    });
                  }}
                  setError={(text, isCorse, title) => {
                    this.setState({
                      textModalError: text,
                      titleModalError: title,
                      showModalError: true,
                      forceLogOut: isCorse,
                    });
                  }}
                  display={this.state.pageIsLoading}
                />
              )}
              {this.state.progress === 108 && this.state.acr === "sms" && (
                <SMSLoginPopUp
                  isFromEPin={this.state.isFromEPin}
                  setEPinCall={() => this.setEPinCall()}
                  auth_req_id={this.state.auth_req_id}
                  phone={this.state.mobilePhone}
                  setProgressive={(value) => {
                    this.setState({
                      progress: value,
                      prevProgress: this.state.progress,
                    });
                  }}
                  isLoading={(value) => {
                    this.setState({
                      pageIsLoading: value,
                    });
                  }}
                  setError={(text, isCorse, title) => {
                    this.setState({
                      textModalError: text,
                      titleModalError: title,
                      showModalError: true,
                      forceLogOut: isCorse,
                    });
                  }}
                  display={this.state.pageIsLoading}
                />
              )}
              {/* ------------------------- END  ----------------------- */}

              {
                //Login screen (succesfully e-PIN)
                this.state.progress === 109 && (
                  <SuccessEPIN
                    {...this.props}
                    cardInfoCall={this.state.cardInfoCall}
                    setProgressive={(value) => {
                      this.setState({
                        progress: value,
                        TCisLoading: true,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                  />
                )
              }
              {
                //Sixth Login Screen (Term & Condition)
                this.state.progress === 110 && (
                  <LogTermAndCondition
                    setProgressive={(value) => {
                      this.setState({ progress: value });
                    }}
                    isReady={(value) => {
                      this.setState({
                        pageIsLoading: value,
                        TCisLoading: false,
                      });
                    }}
                    setError={(text, isCorse, title) => {
                      this.setState({
                        textModalError: text,
                        titleModalError: title,
                        showModalError: true,
                        forceLogOut: isCorse,
                      });
                    }}
                    display={this.state.pageIsLoading || this.state.TCisLoading}
                  />
                )
              }
            </div>
            {/*Error Modal */}
            <UiModal
              visible={this.state.showModalError}
              title={
                this.state.titleModalError
                  ? this.state.titleModalError
                  : i18n.t("Error.TryAgain")
              }
              text={this.state.textModalError}
              noButtonText={i18n.t("Buttons.Close")}
              noButtonFunc={() => {
                if (this.state.forceLogOut) {
                  window.sessionStorage.clear();
                  this.setState({
                    progress: 0,
                    showModalError: false,
                    textModalError: "",
                    titleModalError: "",
                    forceLogOut: false,
                    pageIsLoading: false,
                  });
                } else {
                  this.setState({
                    showModalError: false,
                    textModalError: "",
                    titleModalError: "",
                  });
                }
              }}
            />
            {/*Back BrowserModal */}
            <UiModal
              visible={this.state.showModalBack}
              title={i18n.t("Error.Back")}
              text={i18n.t("Error.BackToLogin")}
              noButtonText={i18n.t("Buttons.Close")}
              noButtonFunc={() => {
                this.setState({ showModalBack: false });
              }}
              yesButtonText={i18n.t("Buttons.Continue")}
              yesButtonFunc={() => {
                this.setState({
                  showModalBack: false,
                  progress: 0,
                });
              }}
            />
            {/*showCardStatusModal*/}
            <UiModal
              visible={this.state.showCardStatusModal}
              title={i18n.t("Error.LimitedAccess")}
              text={"" +
                i18n.t("Error.CardStatusCase2_1") +
                Utilities.cardStatusConverter(this.state.cardStatus) +
                i18n.t("Error.CardStatusCase2_2")}
              noButtonText={i18n.t("Buttons.Close")}
              noButtonFunc={() => {
                window.sessionStorage.clear();
                this.setState({
                  showCardStatusModal: false,
                  progress: 0,
                  cardStatus: "",
                  pageIsLoading: false,
                })
              }}
            />

            {/*Certify Contacts Modal */}
            <UiModal
              visible={this.state.certifyContacts_Modal}
              title={i18n.t("Error.Login403Title")}
              text={i18n.t("Error.Login403Description")}
              noButtonText={i18n.t("Buttons.Close")}
              noButtonFunc={() => { this.setState({ certifyContacts_Modal: false }) }}
              yesButtonText={i18n.t("Buttons.Continue")}
              yesButtonFunc={() => { this.certifyContactsFunc() }}
            />
          </div>
          <ServiceInformationPopup callback={async (value) => {
            this.setState({reloadLanguage: true});
          }}/>
          <div>
            <Footer />
            <div className="Footer">
              <span className="footerText">S-Kreditpartner</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
//Redux connection
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
  scopeListDuck: state.scopeListDuck,
});
export default connect(mapStateToProps)(withTranslation()(Login));
