import * as React from "react"

function MenuTermsConditionsOff(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"MenuTermsConditionsOff"}</title>
      <g fill="#666" fillRule="evenodd">
        <path d="M20.751 23.83H4.249a.062.062 0 01-.062-.062V1.232c0-.034.028-.061.062-.061h12.023v3.248c0 .68.56 1.232 1.249 1.232h3.292v18.117a.062.062 0 01-.062.061zm-3.23-19.35a.062.062 0 01-.062-.06V1.998l2.515 2.482h-2.453zm4.478.58a.58.58 0 00-.173-.408L17.285.172V.17a.595.595 0 00-.42-.171H4.25C3.56 0 3 .553 3 1.232v22.536C3 24.448 3.56 25 4.249 25h16.502C21.44 25 22 24.447 22 23.768V5.066l-.001-.006z" />
        <path d="M17.75 8.008H7.25a.59.59 0 00-.594.586.59.59 0 00.594.585h10.5a.59.59 0 00.594-.585.59.59 0 00-.594-.586M17.75 11.54H7.25a.59.59 0 00-.594.586.59.59 0 00.594.585h10.5a.59.59 0 00.594-.585.59.59 0 00-.594-.585M17.75 15.073H7.25a.59.59 0 00-.594.585.59.59 0 00.594.586h10.5a.59.59 0 00.594-.586.59.59 0 00-.594-.585M12.5 18.605H7.25a.59.59 0 00-.594.585.59.59 0 00.594.586h5.25a.59.59 0 00.593-.586.59.59 0 00-.593-.585" />
      </g>
    </svg>
  )
}

export default MenuTermsConditionsOff
