import * as React from "react"

function MenuClose(props) {
    return (
        <svg
            width={25}
            height={25}
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                transform="translate(4 4)"
                fill="#FFF"
                stroke="#FFF"
                strokeWidth={0.5}
                fillRule="evenodd"
            >
                <rect
                    transform="rotate(45 8.485 8.485)"
                    x={-2.515}
                    y={7.485}
                    width={22}
                    height={2}
                    rx={1}
                />
                <rect
                    transform="scale(-1 1) rotate(45 0 -12)"
                    x={-2.515}
                    y={7.485}
                    width={22}
                    height={2}
                    rx={1}
                />
            </g>
        </svg>
    )
}

export default MenuClose
