import axios from "axios/index";
import Utilities from "../utils/utilities";
import i18n from "../I18next";

const axiosActivateInstance = axios.create();

axiosActivateInstance.interceptors.request.use(
  async (config) => {
    //Get token and refresh token from sessionStorage
    const { access_token } = Utilities.getToken();
    config.headers = {
      Accept: "*/*",
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Language: "" + i18n.language.toUpperCase(),
    };
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosActivateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    //Test Cors error
    if (typeof error.response === "undefined") {
      let obj = {
        status: 667,
        data: "Cors Error",
      };
      return obj;
    } else {
      return error.response;
    }
  }
);
export default axiosActivateInstance;
