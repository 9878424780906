import React, { useState } from "react";

import "./css/uiFAQCollapsable.css";

//ANTD
import "antd/dist/antd.css";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export default function UiFAQCollapsable(props) {
  const [expanded, setExpanded] = useState(null);

  const showCollapse = (cat, Cat_index) => {
    return cat.faqs.map((faq, info_index) => (
      <div
        className="FAQCollapsable_SingleBox_Container"
        key={info_index}
        onClick={() => {
          let obj = {
            catI: Cat_index,
            infoI: info_index,
          };
          expanded !== null &&
          expanded?.catI === Cat_index &&
          expanded?.infoI === info_index
            ? setExpanded(null)
            : setExpanded(obj);
        }}
      >
        <div className="FAQCollapsable_SingleBox_TitleRaw">
          <label style={{ color: "#444444", fontSize: "16px" }}>
            {faq.question}
          </label>
          {expanded !== null &&
          expanded?.catI === Cat_index &&
          expanded?.infoI === info_index ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )}
        </div>
        {expanded?.catI === Cat_index && expanded?.infoI === info_index && (
          <div className="FAQCollapsable_collapsDetails">
            <span>{faq.answer}</span>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="FAQCollapsable_Container">
      {props.data &&
        props.data.map((cat, Cat_index) =>
          //Show category selected === true condition
          props.empty === false
            ? cat.selected === true && showCollapse(cat, Cat_index)
            : showCollapse(cat, Cat_index)
        )}
    </div>
  );
}
