import React from 'react'
import './css/uiLabel.css'

export default function UiLabel(props) {
    return (
        <div className="labelContainer">
            <div className='labelTitle'>
                <label>{props.title}</label>
            </div>
            <div className='labelItem'>
            <label>{props.text}</label>
            </div>
            
        </div>
    )
}
