import React, { useState } from 'react'
import i18n from 'i18next';
import './css/uiLangButton.css'
import { useEffect } from 'react';

const LangButton = (props) => {

    const [lang, setLang] = useState(i18n.language)
    const [showLang, setShowLang] = useState(false)

    useEffect(() =>{
        if(props.reload === true && props.languageReloaded){
            if (i18n.language === 'en') {
                setLang('en')
            }
            if (i18n.language === 'de') {
                setLang('de')
            }
            props.languageReloaded();
        }
    });

    const changeLang = () => {

        let newLang

        if (i18n.language === 'en') {
            i18n.changeLanguage('de');
            setLang('de')
            newLang = 'de'
        } else if (i18n.language === 'de') {
            i18n.changeLanguage('en');
            setLang('en')
            newLang = 'en'
        }
        if (props.callback) {
            props.callback(newLang)
        }
        setShowLang(false)
    }

    return (
        <div className='langButtonContainer'>
            <div>
                <button
                    className='langButton'
                    onClick={() => setShowLang(!showLang)}
                >
                    {lang.toUpperCase()}
                </button>
            </div>

            {
                showLang &&
                <>
                    <div className='triangleToolTip' />
                    <div>
                        <button className='langTooltip'
                            onClick={()=>changeLang()}
                        >
                            {lang === 'en' ?
                                'Deutsch' : 'English'
                            }
                        </button>
                    </div>
                </>

            }
        </div>
    )
}

export default LangButton