import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";

//TRANSLATIONS
import i18n from "../../../I18next";
//Ant_D COMPONENT
import "antd/dist/antd.css";

//icons
import ImgOk from "../../../asset/svg/ImgOk";

export default class Congratulation extends Component {
  render() {
    return (
      <div className="loginInputCardContainer" style={{ display: this.props.display && 'none' }}>
        <UiCardTitle text={i18n.t("Login7.TitleText")} />
        <p style={{ textAlign: "left" }}>
          {this.props.isForget?i18n.t("Login7.ChangePw") : this.props.isCertifyContacts? i18n.t("Login7.CertifiyContacts"): i18n.t("Login7.Description")}
        </p>
        <ImgOk  style={{margin: "5rem 0", alignSelf:'center' }}/>
        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Login")}
              press={() => {
                this.props.setProgressive(0);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
