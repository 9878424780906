import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../../funcComponent/UiComponents/UiCardTitle";
import UiModal from "../../../funcComponent/UiComponents/UiModal";

//TRANSLATIONS
import i18n from "../../../../I18next";

//Ant_D COMPONENT
import "antd/dist/antd.css";
import { Checkbox } from "antd";
//GENERIC SERVICES
import GenericServices from "../../../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../../../utils/properties";


export default class LogTermAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TermCondCheck: false,
      TC: null,
      isReady: false,
      //Modal error
      modalMessage: "",
      isCors: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTermAndConditions();
  }

  getTermAndConditions = async () => {
    let contentID = "188f8a9e-b6b9-47c6-95c9-447fa778ef34";
    let termCondCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_NPC +
      "/sbcontent/v1/content/" +
      contentID +
      "/digest/1234"
    );
    if (termCondCall.status === 200) {
      let tc = termCondCall.data.content;

      this.setState({ TC: tc, isReady: true });
      this.props.isReady()
    }
    if (termCondCall.status === 666) {
      this.setState({
        modalMessage: i18n.t("Error.TimeIsUpRedirect"),
        showModal: true,
        isCors: true,
      });
    }
    //CORS control
    if (termCondCall.status === 667)
      this.setState({
        modalMessage: i18n.t("Error.CorsRedirect"),
        showModal: true,
        isCors: true,
      });
  };
  render() {
    return (

      this.state.isReady &&

      <div className="loginInputCardContainer" style={{ display: this.props.display && 'none' }}>
        <UiCardTitle text={i18n.t("Login6.TitleText")} />

        <div className="Log_TC_Body">
          <iframe title=" Login Term And Conditions" className="Log_TC_iFrame" srcDoc={this.state.TC} />

          <div style={{ marginBottom: "3rem", textAlign: "left" }}>
            <Checkbox
              onChange={(e) => {
                this.setState({
                  TermCondCheck: !this.stateTermCondCheck,
                });
              }}
            >
              {i18n.t("Checkboxes.TermAndConditions")}
            </Checkbox>
          </div>
        </div>

        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Continue")}
              disabled={!this.state.TermCondCheck ? true : false}
              press={() => {
                if (this.state.TermCondCheck) {
                  this.props.setProgressive(109);
                }
              }}
            />
          </div>
        </div>
        {
          <UiModal
            visible={this.state.showModal}
            title={"Error"}
            text={this.state.modalMessage}
            noButtonText={i18n.t("Buttons.Close")}
            noButtonFunc={() => {
              this.setState({ showModal: false, modalMessage: "" });
              if (this.state.isCors === true) {
                this.setState({
                  showModal: false,
                  modalMessage: "",
                  isCors: false,
                });
                this.props.setProgressive(0);
              }
            }}
          />
        }
      </div >
    );
  }
}
