import React from 'react'
//STYLE
import './css/error.css'
//IMAGES
import logo from "../asset/img/Logo.png"
export default function Error() {
    return (
        <div className='error_PageContainer'>

            <div className='error_Background_Logo_Container'>
                <div className='error_Background_Logo_item'style={{backgroundImage: `url(${logo})`}}/>
            </div>

            <p>404</p>

        </div>
    )
}
