import React, {useRef, useEffect, useState} from "react";
import Spinner1white from "../funcComponent/Spinner1white";

import "./css/iframeParent.css";

//Utils
import Utilities from "../../utils/utilities";
//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import {PROPERTIES} from "../../utils/properties";
// REDUX
import {connect} from "react-redux";
import {initBcAuth, setBcAuth} from "../../redux/ducks/bcAuthDuck";
import {setCardFlip} from "../../redux/ducks/navigationDuck";
//TRANSLATIONS
import i18n from "../../I18next";
import {
    initCloseBiometry,
    initSMS,
    initLoading,
    setLoading
} from "../../redux/ducks/iframeComunicationDuck";
import axios from "axios";
import querystring from "querystring";
import { message } from "antd";

const IframeParent = (props) => {
    const iFrameRef = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [cardImage, setCardImage] = useState(null);

    useEffect(async () => {
        setIsReady(false)
        let urlFrameCall = await GenericServices.get(
            "https://" +
            PROPERTIES.BASE_URL_OBP +
            "/sbcardimage/v1/card/" +
            props.cardId +
            "/image"
        );
        if (urlFrameCall.status === 200) {
            setCardImage(urlFrameCall.data);
        } else {
            let testError = Utilities.callErrorTest(urlFrameCall);
            if (testError !== false)
                props.setError(testError.textMessage, testError.isCorse, testError.title);
        }


        window.addEventListener("message", function (e) {
            props.dispatch(initBcAuth());
            if (e.data === "CloseBiometry") {
                props.dispatch(initSMS());
            } else if (e.data === "pollingExpired") {
                props.setError(i18n.t("Error.TimeIsUp"), false, i18n.t("Error.ExpiredRequest"));
            } else if (e.data === "expiredToken") {
                //call to refresh token
                console.log("Calling SbAUth to refresh expired access token!")
                //nella response passare request?
                props.dispatch(initLoading())
                refreshAccessToken().then((response) => {
                    let newToken;
                    let refresh_token;
                    if (response?.status === 666) {
                        return response;
                    } else {
                        newToken = response.data.access_token;
                        refresh_token = response.data.refresh_token;


                        let sessionStorageObj = {
                            access_token: newToken,
                            refresh_token: refresh_token,
                        };

                        window.sessionStorage.setItem(
                            "logged",
                            JSON.stringify(sessionStorageObj)
                        );
                        console.log("Access Token refreshed!")
                        // let originalRequest = error.config;
                        // originalRequest.headers.Authorization = "Bearer " + newToken;
                        // return axiosInstance(originalRequest);
                        // props.setError(i18n.t("Error.NewOtp_generic"))
                    props.dispatch(setLoading(false))
                    // Close card Details
                     props.dispatch(setCardFlip(true))
                     //reload component
                    // message = {
                    //     status: PROPERTIES.TOKEN_REFRESHED,
                    //     data: sessionStorageObj.newToken,
                    // }
                    //  notify web view
                    window.frames[PROPERTIES.CARD_FRAME_NAME].postMessage(JSON.stringify({
                                                                status: PROPERTIES.TOKEN_REFRESHED,
                                                                data: sessionStorageObj.newToken,
                                                            }))
                     // window.frames.FRAME_NAME.postMessage(JSON.stringify(message))
                     for (var i = 0; i < window.frames.length; i++) {
                         console.log("WINDOWS FRAME");
                         console.log(window.frames[i]);
                     }
                    }
                    
                })


                
            } else if (e.data === "genericError") {
                props.setError(i18n.t("Error.NewOtp_generic"));
                props.dispatch(initLoading())
            } else if (e.data === "startLoading") {
                //Start spinner
                props.dispatch(setLoading(true))
            } else {
                e.data.type = "ViewCard";
                e.data.title = i18n.t("CardDetail.TitleText");
                e.data.description = i18n.t("CardDetail.SubTitle1");
                props.dispatch(setBcAuth(e.data));
                //Spinner Stop
                props.dispatch(initLoading())
            }
        });
    }, [i18n.language.toUpperCase()]);

    useEffect(() => {
        if (props.iframeComunicationDuck.sms) {
            let data = {
                acr: "sms",
            };
            window.frames[0].frameElement.contentWindow.postMessage(data, "*");
            props.dispatch(initSMS());
        }
        if (props.iframeComunicationDuck.close) {
            let data = {
                close: true,
            };
            window.frames[0].frameElement.contentWindow.postMessage(data, "*");
            props.dispatch(initCloseBiometry());
        }
    });

    const refreshAccessToken = async () => {
        const {refresh_token} = Utilities.getToken();
        let timeUp = false;
        var querystring = require("querystring");
        let data;
        data = await axios({
            method: "post",
            url: "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
            data: querystring.stringify({
                grant_type: "refresh_token",
                refresh_token: refresh_token,
            }),
            headers: {
                Accept: "*/*",
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        })
            .catch((error) => {
                if (error?.response.status === 401) {
                    timeUp = true;
                    return error.response
                }
            })
            .then((response) => {
                if (response?.status === 200) return response;
            });
        if (timeUp === true) {
            let obj = {
                status: 666,
                data: {
                    errorMessage: i18n.t("Error.TimeIsUpRedirect")
                },
            };
            return obj;
        } else {
            return data;
        }
    };

    return (
        <div className="iframeContainer" >
            {!isReady && <Spinner1white/>}
            <iframe
                name={PROPERTIES.CARD_FRAME_NAME}
                className="iframeWindow"
                style={{display: !isReady && "none"}}
                ref={iFrameRef}
                onLoad={() => setIsReady(true)}
                srcDoc={cardImage}
                title="Child iframe Card Details"
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    bcAuthDuck: state.bcAuthDuck,
    iframeComunicationDuck: state.iframeComunicationDuck,
    navigationDuck: state.navigationDuck,
});

export default connect(mapStateToProps)(IframeParent);
