import React from "react";
//UICOMPONENT
import UiLabel from "./UiComponents/UiLabel";
//STYLE
import "./css/userCard.css";
//TRANSLATIONS
import i18n from "../../I18next";
//Utilities
import Utilities from "../../utils/utilities";
//ANTD
import { FileProtectOutlined, RightCircleOutlined } from "@ant-design/icons";

export default function MobileUserCard(props) {

  window.scrollTo(0,0)
  
  return (
    <div className="userCardView">
      <div className="userCard Mob">
        <div className="userCardContent">
          {/* PERSONAL DATA SECTION */}
          <div className="personalDataContainerMob">
            <div className="userCardTitle Mob">
              <label>{i18n.t("UserCard.PersonalData")}</label>
            </div>

            <div className="userCardItem Mob">
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.FlexiGeldID")}
                  text={props.user.username}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.Gender")}
                  text={props.camsUser.customerGender}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.Title")}
                  text={props.user.salutation}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.FirstName")}
                  text={props.camsUser.customerName}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.Surname")}
                  text={props.camsUser.customerSurname}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.Street")}
                  text={props.address.addresslist[0].address.addressLine1}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.HouseNumber")}
                  text={props.address.addresslist[0].address.addressLine2}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.PostCode")}
                  text={props.address.addresslist[0].address.addressZip}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.TownCity")}
                  text={props.address.addresslist[0].address.addressCity}
                />
              </div>
              <div className="uiLabelUserContainerMob last">
                <UiLabel
                  title={i18n.t("UserCard.Country")}
                  text={props.address.addresslist[0].address.addressCountry}
                />
              </div>
            </div>
          </div>

          {/* CONTACT INFO SECTION */}
          <div className="contactInfoContainerMob">
            <div className="userCardTitle Mob">
              <label>{i18n.t("UserCard.ContactInfo")}</label>
            </div>

            <div className="contactInfoItem">
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.MobileNumber")}
                  text={Utilities.maskerNumber(
                    props.user.mobilePhone
                  )}
                />
              </div>
              <div className="uiLabelUserContainerMob">
                <UiLabel
                  title={i18n.t("UserCard.EmailAddress")}
                  text={Utilities.maskerMail(
                    props.user.email
                  )}
                />
              </div>
              <div className="uiLabelUserContainerMob last">
                <UiLabel
                  title={i18n.t("UserCard.IbanBic")}
                  text={Utilities.maskerIban(props.iban)}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
