import React, { useEffect, useState } from "react";
import "./css/termAndConditionsScreen.css";
//TRANSLATIONS
import i18n from "../../I18next";
//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
//SPINNER
import Spinner1 from "./Spinner1";
//Utils
import Utilities from "../../utils/utilities";

export default function TermAndConditionsScreen(props) {
  const [TC, setTC] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getTermAndConditions();
  }, []);

  const getTermAndConditions = async () => {
    let contentID = "188f8a9e-b6b9-47c6-95c9-447fa778ef34";
    let termCondCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_NPC +
      "/sbcontent/v1/content/" +
      contentID +
      "/digest/1234"
    );
    if (termCondCall.status === 200) {
      let tc = termCondCall.data.content

      setTC(tc);
      setIsReady(true);
    } else {
      if (termCondCall.status === 404) {
        props.setError(i18n.t("Error.NewOtp_generic"), false, "");
      } else {
        let testError = Utilities.callErrorTest(termCondCall)
        if (testError !== false) props.setError(testError.textMessage, testError.isCorse, testError.title);
      }
    }

  };
  return (
    <div className="TAC_Princ_Container">
      <div className="TAC_Header">
        <label>{i18n.t("SideBar.TermsAndConditions")}</label>
      </div>
      <div className="TAC_Body">
        {!isReady && (
          <div className="TC_secondondarySpinner_Continer">
            <Spinner1 />
          </div>
        )}
        {
          isReady &&
          <iframe title="Term And Conditions" className="TC_iFrame" srcDoc={TC} />
        }
      </div>
    </div>
  );
}
