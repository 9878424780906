import React, { Component } from "react";
//ANTD
import "antd/dist/antd.css";
//icons
import IcoSearch from "../../asset/svg/IcoSearch";
import NoTransactions from "../../asset/svg/NoTransactions";
//TRANSLATIONS
import i18n from "../../I18next";
//STYLE
import "./css/transactions.css";
//COMPONENTS
import UiCollapsable from "../funcComponent/UiComponents/UiCollapsable";
//MOMENTJS
import "moment/locale/de";
//REDUX
import { connect } from "react-redux";
//Spinner
import Spinner2 from "../funcComponent/Spinner2"

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      tExpanded: null,
      isFromHome: false,
      searchButtonHover: false,
    };
  }

  filteredTransactionsMonth = () => {
    let list = [...this.props.transactions.transactionList];
    this.setState({
      filtered: list,
    });
  };

  searchTransactions = async (e) => {
    await this.filteredTransactionsMonth();
    let list = [...this.state.filtered];
    if (e !== "") {
      list = list.filter(
        (f) =>
          f.description.toLowerCase().includes(e.toLowerCase()) ||
          (f.amount/(-100)).toString().includes(e)||
          ((f.amount/(-100)).toString().replace(".",",")).includes(e)
      );
    }
    this.setState({
      filtered: list,
    });
  };

  expandCollapseMobile = (tExpanded) => {
    this.setState({
      tExpanded,
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.filteredTransactionsMonth();
    if (this.props.isFromHome) {
      this.props.callback();
      this.setState({
        tExpanded: this.props.tExpanded,
        isFromHome: true,
      });
    }
  };

  render() {
    return (
      <div className="transactions_Page_Container">
        {this.state.tExpanded && (
          <div className="transactionBreadcrumb">
            <span
              className="transactionBreadcrumb_transactions"
              onClick={() => {
                if (!this.state.isFromHome) {
                  this.setState({
                    tExpanded: false,
                  });
                } else {
                  this.props.backToHome();
                }
              }}
            >
              {`${this.state.isFromHome ? i18n.t("SideBar.Home") : i18n.t("SideBar.Transactions")}`}
            </span>
            <span className="transactionBreadcrumb_arrow">{">"}</span>
            <span className="transactionBreadcrumb_details">{i18n.t("Transactions.Details")}</span>
          </div>
        )}
        <div className="transaction_Header_Container">
          <label>{i18n.t("Transactions.Transactions")}</label>
        </div>

        {/*Spinner to Update transaction list after change lang */}
        {this.props.transactionsDuck.isLoading ? (
          <div className="transactionsLoaderContainer">
            <Spinner2 className="spinner" />
          </div>
        ) : (
          <>
            {/*Correct visualization*/}
            {!this.state.tExpanded ? (
              <div className="transactions_Body_Component_View">
                <div className="searchBoxContainer">
                  <div className="transactions_Search_inputBox_Container">
                    <div className="searchBoxFull">
                      <IcoSearch />

                      <input
                        className="transaction_Search_inputBox_item"
                        type="text"
                        placeholder={i18n.t("Placeholder.TransactionsSearch")}
                        onChange={(e) =>
                          this.searchTransactions(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                {this.state.filtered.length === 0 ? (
                  <div className="transactionClosePageContainer">
                    <div className="transaction_ClosePage_EmptyContainer">
                      <div
                        style={{
                          height: "4rem",
                          width: "4rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <NoTransactions />
                      </div>
                      <p>{i18n.t("Transactions.NoTransactionsInMonth")}</p>
                    </div>
                  </div>
                ) : (
                  <div className="transactions-Container">
                    <UiCollapsable
                      data={this.state.filtered}
                      size={this.props.size}
                      mobileOnClick={this.expandCollapseMobile}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="transactions_Body_Component_View">
                <UiCollapsable
                  data={[this.state.tExpanded]}
                  size={this.props.size}
                  tDetails={true}
                  isHome={false}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
  transactionsDuck: state.transactionsDuck,
});

export default connect(mapStateToProps)(Transactions);
