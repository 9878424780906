import React, { Component } from "react";
//STYLE
import "./css/home.css";
// REDUX
import { connect } from "react-redux";
import { setCardFlip, setNav } from "../redux/ducks/navigationDuck";
import { setScopeList } from "../redux/ducks/scopeListDuck";
import { initBcAuth } from "../redux/ducks/bcAuthDuck";
import {
  setCloseBiometry,
  setNewCode,
} from "../redux/ducks/iframeComunicationDuck";
import {setTransactions, setLoader} from "../redux/ducks/transactionsDuck"
//ANTD
import "antd/dist/antd.css";
//LODASH
import { get } from "lodash";
//TRANSLATIONS
import i18n from "../I18next";
//COMPONENT
import Navbar from "../component/funcComponent/Navbar";
import UserCard from "../component/funcComponent/UserCard";
import SideBar from "../component/classComponent/SideBar";
import MobileSideBar from "../component/classComponent/MobileSideBar";
import MobileUserCard from "../component/funcComponent/MobileUserCard";
import Footer from "../component/funcComponent/UiComponents/UiFooter";
import PrivacyNotice from "../component/funcComponent/PrivacyNotice";
//SCREEN COMPONENT
import HomeCard from "../component/classComponent/HomeCard";
import CardSetting from "../component/classComponent/CardSetting";
import TermAndConditionsScreen from "../component/funcComponent/TermAndConditionsScreen";
import Transactions from "../component/classComponent/Transactions";
import Statements from "../component/classComponent/Statements";
import FAQ from "../component/classComponent/FAQ";
//GENERIC SERVICES
import GenericServices from "../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../utils/properties";
//IMG
import Spinner2 from "../component/funcComponent/Spinner2";
import SpinnerLogin from "../component/funcComponent/SpinnerLogin";
//ICON
import Utilities from "../utils/utilities";
//UICOMPONENT
import UiModal from "../component/funcComponent/UiComponents/UiModal";
//PopUp
import BiometryPopUp from "../component/funcComponent/Biometry_pop_up";
//MOMENTJS
import moment from "moment";
import promotionsDuck, {setPromotions} from "../redux/ducks/promotionsDuck"

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserCard: false,
      userInfo: "",
      camsUserInfo: "",
      sideNav: 0,
      size: window.innerWidth,
      heigthMobile: window.innerHeight,
      mSideBar: false,
      tExpanded: false,
      isFromHome: false,
      showMobileLogoutModal: false,
      lang: i18n.language,
      flexiGeldID: "",
      //Card
      cardInfo: this.props.history.location.state?.data.card[0],
      cardStatus:
        this.props.history.location.state?.data.card[0].cardInfo[0].cardStatus,
      iban: this.props.history.location.state?.data.card[0].relatedAccount
        .relatedAccountNumber,
      //LogOutMOdal test
      showModal: false,
      isCorse: false,
      titleModalError: "",
      textModalError: "",
      //CardStatus Modal
      showCardStatusModal: false,
      isNoAccess: false,
      buttonText: "",
    };
    this.windowHeigth = window.innerHeight; //5rem di navbar+5rem di footer
  }

  logout = async () => {
    this.props.dispatch(setNav(0));
    window.sessionStorage.clear();
    this.props.history.push("/");
  };

  setError = (text, isCorse, title) => {
    this.setState({
      textModalError: text,
      titleModalError: title,
      showModal: true,
      isCorse: isCorse,
    });
  };

  cardStatusControl = () => {
    let test = Utilities.cardStatusController(this.state.cardStatus);
    if (test) {
      this.setState({
        showCardStatusModal: true,
        textModalError: test.text,
        titleModalError: test.title,
        buttonText: test.button,
        isNoAccess: test.isNoAccess,
      });
    }
  };

  updateSize = () => {
    this.setState({
      size: window.innerWidth,
      heigthMobile: window.innerHeight,
    });
  };

  profileChangeSize = () => {
    if (this.state.size >= 768 && this.state.sideNav === 6) {
      this.props.dispatch(setNav(0));
      this.setState({
        showUserCard: true,
        sideNav: 0,
      });
    }
    if (this.state.size < 768) {
      this.props.dispatch(setNav(6));
      this.setState({
        showUserCard: false,
        sideNav: 6,
      });
    }
  };

  mobileSideBar = () => {
    this.setState({
      mSideBar: !this.state.mSideBar,
    });
  };

  transactionDetails = (data) => {
    this.props.dispatch(setNav(1));
    this.setState({
      tExpanded: data,
      sideNav: 1,
      isFromHome: true,
    });
  };

  backToHome = () => {
    this.props.dispatch(setNav(0));
    this.setState({
      sideNav: 0,
    });
  };

  userCall = async () => {
    let userInfoCall = await GenericServices.get(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbuser/v1/user"
    );

    if (userInfoCall.status === 200) {
      this.setState({
        userInfo: userInfoCall.data,
        flexiGeldID: userInfoCall.data.username,
      });
    } else {
      let testError = Utilities.callErrorTest(userInfoCall);
      if (testError !== false)
        this.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };

  camsUserCall = async () => {
    let camsUserInfoCall = await GenericServices.get(
      "https://" + PROPERTIES.BASE_URL_OBP + "/sbcams/v1/customer"
    );
    if (camsUserInfoCall.status === 200) {
      this.setState({
        camsUserInfo: camsUserInfoCall.data,
      });
    } else {
      let testError = Utilities.callErrorTest(camsUserInfoCall);
      if (testError !== false)
        this.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  }

  addressCall = async () => {
    let addressInfoCall = await GenericServices.get(
        "https://" + PROPERTIES.BASE_URL_OBP + "/sbcams/v1/customer/address1"
    );

    if (addressInfoCall.status === 200) {
      this.setState({
        addressInfo: addressInfoCall.data,
      });
    } else {
      let testError = Utilities.callErrorTest(addressInfoCall);
      if (testError !== false)
        this.setError(
            testError.textMessage,
            testError.isCorse,
            testError.title
        );
    }
  };

  ttlControl=()=>{
    let text= i18n.t("Error.TimeIsUpRedirect")
    let title= i18n.t("Error.ExpiredRequest");
    //Take from session storage
    let temp = JSON.parse(window.sessionStorage?.getItem("TTL"));
    let ttl = get(temp, "TTL", null);
    let day = get(temp, "DAY", null);
    //Take current time
    let time=moment().format('LTS')
    let date=moment().format('DD')

    if(date>day){
      this.setError(text, true, title)
    }else{
      if(time>=ttl){
        this.setError(text, true, title)
      }else{
        setTimeout(() => {
          this.ttlControl();
        }, 1000);
      }
    }
  }

  trasactionCall = async (cardID) => {
    //Set start  transaction loader
    this.props.dispatch(setLoader(true))
    //Transaction CAll
    let cardTransactionCall = await GenericServices.get(
      "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        cardID +
        "/transaction"
    );

    if (cardTransactionCall.status === 200) {
      //stop transaction loader
    this.props.dispatch(setLoader(false))
    this.props.dispatch(setTransactions(cardTransactionCall.data))
    } else {
      let testError = Utilities.callErrorTest(cardTransactionCall);
      //stop transaction loader
      this.props.dispatch(setLoader(false))
      if (testError !== false)
        this.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };

  promotionsCall = async () => {
    let promotionStatusCall = await GenericServices.get(
			PROPERTIES.BASE_URL_PROMO_SEARCH_PROMOTION
		);

    if(promotionStatusCall.status == 200){
      this.props.dispatch(setPromotions(promotionStatusCall.data));
    }
    else{
      this.props.dispatch(setPromotions([]));
    }
  }

  componentDidUpdate = async () => {
    //GoBack Browser Controll
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = (e) => {
      e.preventDefault();
      
      if (this.props.iframeComunicationDuck.sendNewCode) {
        //Close send NewCode view
        this.props.dispatch(setNewCode(false));
      } else if (this.props.bcAuthDuck.bcauth) {
        //Close Biometry_popUp
        this.props.dispatch(initBcAuth());
        this.props.dispatch(setCloseBiometry(true));
      } else if (this.props.navigationDuck.cardFlip) {
        //Close card Details
        this.props.dispatch(setCardFlip(false));
      } else {
        //LogOut
        if (this.state.sideNav === 0) {
          this.setState({ showMobileLogoutModal: true });
        } else {
          //Back to Home
          this.props.dispatch(setNav(0));
          this.setState({ sideNav: 0 });
        }
      }
    };
  };

  componentDidMount = async () => {
    if (Utilities.sessionStorageControl()) {
      if (!this.props.scopeListDuck.scopeList) {
        // //Scope List to save in redux for OTP Controll CAll
        let contentId = "9938e9dc-3f1c-4f2f-bbfb-641658ec05b2";
        let scopeListCall = await GenericServices.get(
          "https://" +
            PROPERTIES.BASE_URL_NPC +
            "/sbcontent/v1/content/" +
            contentId +
            "/digest/1234"
        );
        if (scopeListCall.status === 200) {
          let scopeListOBJ = JSON.parse(scopeListCall.data.content);
          this.props.dispatch(setScopeList(scopeListOBJ.SKP));
        } else {
          let testError = Utilities.callErrorTest(scopeListCall);
          if (testError !== false)
            this.setError(
              testError.textMessage,
              testError.isCorse,
              testError.title
            );
        }
      }
      //TTL CONTROL
      this.ttlControl();

      //User CAll
      await this.camsUserCall();
      await this.userCall();

      await this.addressCall();

      //Card CAll
      if (!this.state.cardInfo) {
        let cardInfoCall = await GenericServices.get(
          "https://" +
            PROPERTIES.BASE_URL_OBP +
            "/sbcams/v1/card?accountNumber=" +
            this.state.flexiGeldID
        );
        if (cardInfoCall.status === 200) {
          let cardID = cardInfoCall.data.card[0].cardInfo[0].cardId;
          window.sessionStorage.setItem("CID", JSON.stringify({ cid: cardID }));
          this.setState({
            cardInfo: cardInfoCall.data.card[0],
            // cardStatus: cardInfoCall.data.card[0].cardInfo[0].cardStatus,
            iban: cardInfoCall.data.card[0].relatedAccount.relatedAccountNumber,
          });

          //Card Status CAll
          let cardStatusCall = await GenericServices.get(
            "https://" +
              PROPERTIES.BASE_URL_OBP +
              "/sbcams/v1/card/" +
              cardID +
              "/state"
          );
          if (cardStatusCall.status === 200) {
              this.setState({
                cardStatus: cardStatusCall.data.state
              })
          }else{
            let testError = Utilities.callErrorTest(cardStatusCall);
          if (testError !== false)
            this.setError(
              testError.textMessage,
              testError.isCorse,
              testError.title
            );
          }

          //Card status controll test
          this.cardStatusControl();
          //TransactionCall
          this.trasactionCall(cardID);
          this.promotionsCall();
        } else {
          let testError = Utilities.callErrorTest(cardInfoCall);
          if (testError !== false)
            this.setError(
              testError.textMessage,
              testError.isCorse,
              testError.title
            );
        }
      } else {
        //Card status controll test
        this.cardStatusControl();
        let cardID = this.state.cardInfo.cardInfo[0].cardId;
        this.trasactionCall(cardID);
        this.promotionsCall();
      }
      window.addEventListener("resize", this.updateSize);
      window.addEventListener("profile", this.profileChangeSize);
    } else {
      this.logout();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
    window.removeEventListener("profile", this.profileChangeSize);
  }

  render() {
    return !this.state.cardInfo || !this.props.transactionsDuck.transactionList? (
      <div className="loaderContainer">
        <Spinner2 className="spinner" />
        {/* <SpinnerIcon className="iconLauncher" /> */}
        <UiModal
          visible={this.state.showModal}
          title={
            this.state.titleModalError
              ? this.state.titleModalError
              : i18n.t("Error.TryAgain")
          }
          text={this.state.textModalError}
          noButtonText={i18n.t("Buttons.Close")}
          noButtonFunc={() => {
            if (this.state.isCorse === true) {
              window.sessionStorage.clear();
              window.location.href = "/";
            } else {
              this.setState({
                textModalError: "",
                titleModalError: "",
                showModal: false,
                isCorse: false,
              });
            }
          }}
        />
      </div>
    ) : (
      <div
        className={`homeScreenContainer ${this.state.mSideBar && "siderOpen"}`}
        style={{ height: this.state.mSideBar && this.state.heigthMobile }}
      >
        <Navbar
          logoutFunc={this.logout}
          userFunc={() => {
            this.setState({ showUserCard: !this.state.showUserCard });
          }}
          user={
            this.state.camsUserInfo.customerName + " " + this.state.camsUserInfo.customerSurname
          }
          size={this.state.size}
          mobileSideBar={this.mobileSideBar}
          sideBarOpen={this.state.mSideBar}
          callback={(value) => {
            this.setState({ lang: value })
            this.trasactionCall(this.state.cardInfo.cardInfo[0].cardId)
              }}
          setNavBack={()=>this.setState({ sideNav: 0 })}
        />

        {
          //User Card Modal
          this.state.userInfo &&
            this.state.showUserCard &&
            this.state.camsUserInfo &&
            (this.state.size >= 768 ? (
              <div
                className={`userCardContainer ${
                  this.state.size < 768 && "mob"
                }`}
              >
                <UserCard
                  camsUser={this.state.camsUserInfo}
                  user={this.state.userInfo}
                  address={this.state.addressInfo}
                  userTest={(this, this.state.userTest)}
                  iban={this.state.iban}
                  navigationFunc={(value) => {
                    this.setState({ sideNav: value, showUserCard: false });
                  }}
                />
              </div>
            ) : (
              this.profileChangeSize()
            ))
        }

        <div className="bodyContainer" onClick={()=>this.setState({showUserCard: false})}>
          {this.state.size >= 768 ? (
            <SideBar
              closeUserCard={() => {
                this.setState({ showUserCard: false });
              }}
              navigationFunc={(value) => {
                this.setState({ sideNav: value, showUserCard: false });
              }}
              cardStatus={this.state.cardStatus}
              funcWrongCardStatus={(test) => {
                this.setState({
                  showCardStatusModal: true,
                  textModalError: test.text,
                  titleModalError: test.title,
                  buttonText: test.button,
                });
              }}
            />
          ) : (
            this.state.mSideBar && (
              <MobileSideBar
                navigationFunc={(value) => {
                  this.setState({
                    sideNav: value,
                    showUserCard: false,
                    mSideBar: false,
                  });
                }}
                height={this.state.heigthMobile}
                cardStatus={this.state.cardStatus}
                showLogoutModal={() =>
                  this.setState({
                    mSideBar: false,
                    showMobileLogoutModal: true,
                  })
                }
                funcWrongCardStatus={(test) => {
                  this.setState({
                    showCardStatusModal: true,
                    textModalError: test.text,
                    titleModalError: test.title,
                    buttonText: test.button,
                  });
                }}
              />
            )
          )}
          {/* HOME MOBILE LOGOUT MODAL  */}
          <UiModal
            visible={this.state.showMobileLogoutModal}
            title={i18n.t("NavBar.Logout")}
            text={i18n.t("NavBar.LogoutMessage")}
            noButtonText={i18n.t("Buttons.NO")}
            noButtonFunc={() => this.setState({ showMobileLogoutModal: false })}
            yesButtonText={i18n.t("Buttons.YES")}
            yesButtonFunc={() => this.logout()}
          />

          {/* HOME ERROR MODAL  */}
          <UiModal
            visible={this.state.showModal}
            title={
              this.state.titleModalError
                ? this.state.titleModalError
                : i18n.t("Error.TryAgain")
            }
            text={this.state.textModalError}
            isCorse={this.state.isCorse}
            noButtonText={i18n.t("Buttons.Close")}
            noButtonFunc={() => {
              if (this.state.isCorse === true) {
                window.sessionStorage.clear();
                window.location.href = "/";
              } else {
                this.setState({
                  textModalError: "",
                  titleModalError: "",
                  showModal: false,
                  isCorse: false,
                });
              }
            }}
          />
          {/* CARD STATUS CONTROLL MODAL  */}
          <UiModal
            visible={this.state.showCardStatusModal}
            title={
              this.state.titleModalError
                ? this.state.titleModalError
                : i18n.t("Error.TryAgain")
            }
            text={this.state.textModalError}
            noButtonText={this.state.buttonText}
            noButtonFunc={() => {
              if (this.state.isNoAccess) {
                this.logout();
              }
              this.setState({
                showCardStatusModal: false,
                titleModalError: "",
                textModalError: "",
                buttonText: "",
                isNoAccess: false,
              });
            }}
          />
          {
            //Page Home
            this.state.sideNav === 0 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
                style={{
                  height: this.state.mSideBar && this.state.heigthMobile,
                }}
              >
                <HomeCard
                  cardInfo={this.state.cardInfo !== "" && this.state.cardInfo}
                  cardStatus={
                    this.state.cardStatus !== "" && this.state.cardStatus
                  }
                  transactions={this.props.transactionsDuck.transactionList !== "" &&
                    this.props.transactionsDuck.transactionList
                  }
                  navigationFunc={(value) => {
                    this.setState({ sideNav: value, showUserCard: false });
                  }}
                  size={this.state.size}
                  transactionDetails={this.transactionDetails}
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                  promotions={this.props.promotionsDuck.promotionsBannerList !== "" &&
                    this.props.promotionsDuck.promotionsBannerList
                  }
                />
              </div>
            )
          }
          {
            //Page Transactions
            this.state.sideNav === 1 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <Transactions
                  transactions={this.props.transactionsDuck.transactionList}
                  size={this.state.size}
                  tExpanded={this.state.tExpanded}
                  isFromHome={this.state.isFromHome}
                  callback={() => this.setState({ isFromHome: false })}
                  backToHome={() => this.backToHome()}
                />
              </div>
            )
          }
          {
            //Page Statements
            this.state.sideNav === 2 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <Statements
                  cardId={this.state.cardInfo.cardInfo[0].cardId}
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                />
              </div>
            )
          }
          {
            //Page Card setting
            this.state.sideNav === 3 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <CardSetting
                  cardInfo={this.state.cardInfo?.cardInfo[0]}
                  cardStatus={this.state.cardStatus}
                  cardStatusUpdateFunc={(value) => {
                    this.setState({ cardStatus: value });
                  }}
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                />
              </div>
            )
          }
          {
            //Page FAQ
            this.state.sideNav === 4 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <FAQ
                  lang={this.state.lang}
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                />
              </div>
            )
          }
          {
            //Page Term and Condition
            this.state.sideNav === 5 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <TermAndConditionsScreen
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                />
              </div>
            )
          }
          {
            //MOBILE Page Profile
            this.state.userInfo &&
              this.state.camsUserInfo &&
              this.state.sideNav === 6 &&
              (this.state.size < 768 ? (
                <div
                  className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
                >
                  <MobileUserCard
                    camsUser={this.state.camsUserInfo}
                    user={(this, this.state.userInfo)}
                    address={(this, this.state.addressInfo)}
                    userTest={(this, this.state.userTest)}
                    iban={this.state.iban}
                    mobile={this.state.size < 768}
                    navigationFunc={(value) => {
                      this.setState({ sideNav: value, showUserCard: false });
                    }}
                  />
                </div>
              ) : (
                this.profileChangeSize()
              ))
          }
          {
            //Page Privacy notice
            this.state.userInfo && this.state.sideNav === 7 && (
              <div
                className={`homeScreen ${this.state.mSideBar && "siderOpen"}`}
              >
                <PrivacyNotice
                  setError={(text, isCorse, title) =>
                    this.setError(text, isCorse, title)
                  }
                />
              </div>
            )
          }

          {
            //Biometry loading test
            this.props.iframeComunicationDuck.loading && (
              <div className="loaderBiometryContainer">
                <SpinnerLogin />
              </div>
            )
          }
          {
            //Biometry- popUp test

            this.props.bcAuthDuck.bcauth && (
              <BiometryPopUp
                setError={(text, isCorse, title) =>
                  this.setError(text, isCorse, title)
                }
              />
            )
          }
        </div>
        <Footer />
        <div className="Footer" onClick={()=>this.setState({showUserCard: false})}>
          <span className="footerText">S-Kreditpartner</span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
  scopeListDuck: state.scopeListDuck,
  bcAuthDuck: state.bcAuthDuck,
  iframeComunicationDuck: state.iframeComunicationDuck,
  transactionsDuck: state.transactionsDuck,
  promotionsDuck: state.promotionsDuck
});

export default connect(mapStateToProps)(Home);
