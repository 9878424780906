import React from "react";
//STYLE
import "./css/uiModal.css";
import "antd/dist/antd.css";
//ANTD
import { Modal } from "antd";

export default function UiModal(props) {
  return (
      <Modal
        visible={props.visible}
        centered={true}
        maskStyle={{
          backgroundColor:(props.isCorse===true) ?"#d9d9d9":"#a7a7a79c"
        }}
        
        title={props.title}
        footer={[
          props.noButtonText && (
            <button
              className="ModalButton"
              type={"primary"}
              onClick={() => props.noButtonFunc()}
            >
              {props.noButtonText}
            </button>
          ),
          props.yesButtonText && (
            <button
              className="ModalButton"
              type={"primary"}
              onClick={() => props.yesButtonFunc()}
            >
              {props.yesButtonText}
            </button>
          ),
        ]}
      >
        <p>{props.text}</p>
      </Modal>
  );
}
