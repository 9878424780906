import { combineReducers } from 'redux'

//DUCK
import navigationDuck from '../ducks/navigationDuck'
import scopeListDuck from '../ducks/scopeListDuck'
import bcAuthDuck from '../ducks/bcAuthDuck'
import bcTokenDuck from '../ducks/bcTokenDuck'
import iframeComunicationDuck from '../ducks/iframeComunicationDuck'
import transactionsDuck from '../ducks/transactionsDuck'
import promotionsDuck from '../ducks/promotionsDuck'

const rootReducer = combineReducers({
    navigationDuck,
    scopeListDuck,
    bcAuthDuck,
    bcTokenDuck,
    iframeComunicationDuck,
    transactionsDuck,
    promotionsDuck
});

export default rootReducer
