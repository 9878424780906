import React, { Component } from "react";
import axios from "axios";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
import UiInputBox from "../../funcComponent/UiComponents/UiInputBox";
//TRANSLATIONS
import i18n from "../../../I18next";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
//PROPERTIES
import { PROPERTIES } from "../../../utils/properties";

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flexiGelId: "",
      pw: "",
      isCertifyContacts:false
    };
  }
  componentDidUpdate=()=>{
    if(this.props?.certifyContacts_trigger){
      this.setState({isCertifyContacts:true})
      this.props.setCertifyContactsTrigger(false)
      //Initial root for cc
      this.createPWPrepare()
    }
  }

  createPWPrepare = async (isforget) => {
    let querystring = require("querystring");

    this.props.isLoading(true)

    let registerClient = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/web/registerclient",
      querystring.stringify({
        api_key:
          "2c70e12b7a0646f92279f427c7b38e7334d8e5389cff167a1dc30e73f826b683",
      })
    );

    if (registerClient.status === 667) {
      let isCorse = true
      this.props.setError(i18n.t("Error.CorsRedirect"), isCorse)
    }
    if (registerClient.status === 200) {
      let url = registerClient.data.registrationUrl;
      let enrolAT = registerClient.data.enrolAccessToken;
      //Set session storage
      let obj = {
        access_token: enrolAT,
      };
      window.sessionStorage.setItem("logged", JSON.stringify(obj));

      //Activate stateMachine
      let startCall
      if(this.state.isCertifyContacts){
        //certify Contact behavior
        let querystring = require("querystring");
        startCall = await ActivationServices.post(url + "/start",
        querystring.stringify({
          membershipNumber:PROPERTIES.FLEXI_ID_PREFIX+this.state.flexiGelId,
          certify_contacts:true
        }));

      }else{
        startCall = await ActivationServices.post(url + "/start");
      }
      

      if (startCall.status === 200) {
        if(this.state.isCertifyContacts){this.props.setCertifyContactsToOTP(startCall.data.params[1].value, startCall.data.params[0].value)}
        this.props.setProgressive(startCall.data.state, url, isforget);
        this.props.isLoading(false)
      }
      if (startCall.status === 400 || startCall.status === 500) {
        this.props.isLoading(false)
        let modalText = "";
        let title = "";

        //Omega control
        if (startCall.data?.errorMessage?.split("Ω").length === 2) {
          let message = startCall.data?.errorMessage.split("Ω")
          title = message[0]
          modalText = message[1]
        } else {

          //"500 null" CONTROLL message
          if (
            startCall.data.errorMessage === "500 null" ||
            startCall.data?.errorMessage === undefined ||
            startCall.data?.errorMessage.substring(0, 21) === "Internal server error"
          ) {
            modalText = i18n.t("Error.NewOtp_generic");
          } else {
            modalText = startCall.data.errorMessage;
          }
        }
        let isCorse = true
        this.props.setError(modalText, isCorse, title)
      }
      if (startCall.status === 667) {
        let isCorse = true
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse)
      }
    } else {
      if (registerClient.status === 400 || registerClient.status === 500) {
        this.props.isLoading(false)
        let title = ""
        let modalText = "";

        //Omega control
        if (registerClient.data?.errorMessage?.split("Ω").length === 2) {
          let message = registerClient.data?.errorMessage.split("Ω")
          title = message[0]
          modalText = message[1]
        } else {

          //"500 null" CONTROLL message
          if (
            registerClient.data.errorMessage === "500 null" ||
            registerClient.data?.errorMessage === undefined ||
            registerClient.data?.errorMessage.substring(0, 21) === "Internal server error"
          ) {
            modalText = i18n.t("Error.NewOtp_generic");
          } else {
            modalText = registerClient.data.errorMessage;
          }
        }
        let isCorse = true
        this.props.setError(modalText, isCorse, title)
      }
    }
  };

  loginFunc = async () => {
    this.props.isLoading(true)
    let querystring = require("querystring");
    await axios({
      method: "post",
      url: "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/user/login",
      data: querystring.stringify({
        camsMember: PROPERTIES.FLEXI_ID_PREFIX + this.state.flexiGelId,
        code: this.state.pw,
      }),
      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Language: "" + i18n.language.toUpperCase(),
        OriginApp: "SKP",
      },
    })
      .catch((error) => {
        this.props.isLoading(false)
        if (typeof error.response === "undefined") {
          //Cors error detect
          let isCorse = true
          this.props.setError(i18n.t("Error.CorsRedirect"), isCorse)
        } else {
          if (
            (error?.response.status === 500 ||
            (error?.response.status > 399 && error?.response.status < 405)) && error?.response.status !==404 && error?.response.status !==401 && error?.response.status !==403 
          ) {
            let title = ""
            let modalText = "";
            //Omega control
            if (error?.response.data?.errorMessage?.split("Ω").length === 2) {
              let message = error?.response.data?.errorMessage.split("Ω")
              title = message[0]
              modalText = message[1]
            } else {
              //"500 null" CONTROLL message
              if (
                error?.response.data.errorMessage === "500 null" ||
                error?.response.data?.errorMessage === undefined ||
                error?.response.data?.errorMessage.substring(0, 28) === "I/O error on GET request for" ||
                error?.response.data?.errorMessage.substring(0, 21) === "Internal server error"
              ) {
                modalText = i18n.t("Error.NewOtp_generic");
              } else {
                modalText = error?.response.data.errorMessage;
              }
            }
            this.props.isLoading(false)
            let isCorse = false
            this.props.setError(modalText, isCorse, title)
          }
          if (error?.response.status === 404 || error?.response.status === 401) {
            this.props.isLoading(false)
            let isCorse = false
            let title = ""
            let modalText = ""
            //Omega controll
            if (error?.response.data?.errorMessage?.split("Ω").length === 2) {
              let message = error?.response.data?.errorMessage.split("Ω")
              title = message[0]
              modalText = message[1]

            } else {
              title = i18n.t("Error.IncorrectEntries")
              modalText = error?.response.data.errorMessage

            }
            this.props.setError(modalText, isCorse, title)
          }
          if(error?.response.status===403){
            this.props.isLoading(false)
            this.props.setCertifyContacts()
            }
          if (error?.response.status === 667) {
            this.props.isLoading(false)
            let isCorse = true
            this.props.setError(i18n.t("Error.CorsRedirect"), isCorse)
          }
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          this.props.isLoading(false)
          this.props.successLog(
            "_succes_Login",
            response.data.acr,
            response.data.auth_req_id,
            response.data.phoneNumber,
            this.state.flexiGelId,
            this.state.pw
          );
          let obj = {
            access_token: response.data.enroll_token,
          };
          // Set session Storage
          window.sessionStorage.setItem("logged", JSON.stringify(obj));
        }
      });
  };

  render() {
    return (
      <div className="loginInputCardContainer" style={{ display: this.props.display && 'none' }}>
        <div className="CardTitle">
          <UiCardTitle size={"22px"} text={i18n.t("WelcomeLogin.TitleText")} />
          <UiCardTitle text={i18n.t("WelcomeLogin.SubTitle")} />
        </div>

        <div>
          <UiInputBox
            title={i18n.t("Placeholder.FlexiGelId")}
            type="text"
            placeholder={i18n.t("Placeholder.EnterFlexiGelId")}
            changeVal={(event) => {
              if (!isNaN(event) && event[event.length - 1] !== ".") {
                this.setState({ flexiGelId: event });
              }
            }}
            value={this.state.flexiGelId}
            flexiType={true}
            onPressEnter={() => {this.loginFunc();}}
          />
          <UiInputBox
            title={i18n.t("Placeholder.Password")}
            type="password"
            placeholder={i18n.t("Placeholder.Password")}
            value={this.state.pw}
            changeVal={(event) => {
              this.setState({ pw: event });
            }}
            onPressEnter={() => {this.loginFunc();}}
          />

          <div
            style={{
              paddingBottom: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontFamily: "Sparkasse_Rg"
            }}
          >
            <button
              className="link_Button"
              tabIndex="0"
              style={{ margin: "1rem 0rem" }}
              onClick={() => {
                this.createPWPrepare(true);
              }}
            >
              {i18n.t("WelcomeLogin.ForgetPass")}
            </button>
            <div className="link_NewPass">
              <label
                style={{
                  color: "#666666",
                  fontWeight: "600",
                  lineHeight: "1.2"
                }}
              >
                {i18n.t("WelcomeLogin.Create_pw1")}{" "}
              </label>
              <button
                className="link_Button"
                onClick={() => {
                  this.createPWPrepare(false);
                }}
              >
                {i18n.t("WelcomeLogin.Link")}
              </button>
              <label
                style={{
                  color: "#666666",
                  fontWeight: "600",
                }}
              >
                {i18n.t("WelcomeLogin.Create_pw2")}
              </label>
            </div>
          </div>

          <div className="loginButtonContainer">
            <div className="loginButtonItem">
              <UiButton
                title={i18n.t("Buttons.Continue")}
                disabled={!this.state.flexiGelId || !this.state.pw}
                press={() => {
                  this.loginFunc();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
