import React, { useState } from "react";
//STYLE
import "./css/mobileBanner.css";

//ICON
import IcoCloseWhite from "../../asset/svg/IcoCloseWhite";
import IconLauncher from "../../asset/svg/IconLauncher";
import ButtonWhiteOn from "../../asset/svg/ButtonWhiteOn";
import ButtonWhiteOff from "../../asset/svg/ButtonWhiteOff";

const buttonFunc = () => {
  const toMatch = [/webOS/i, /iPhone/i, /iPad/i, /iPod/i];

  let device = toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
  if (device === true) {
    window.location.href =
      "https://apps.apple.com/us/app/s-kreditpartner/id1629203850";
  } else {
    window.location.href =
      "https://play.google.com/store/apps/details?id=skreditpartner.app";
  }
};

export default function MobileBanner(props) {
  const [tap, setTap] = useState(false);
  return (
    <div className="mobileBanner_Container">
      <div className="mobileBanner_Item">
        <div className="mobileBanner_IconAndLabel_Container">
          <div
            className="mobileBanner_ColseIcon"
            onClick={props.closeBannerFunc}
          >
            <IcoCloseWhite />
          </div>
          <IconLauncher />
          <label className="mobileBanner_label">{"S-Kreditpartner "}</label>
        </div>

        {/* BUTTON  */}
        <div
          className="mobileBanner_Button_Container"
          onMouseOver={() => setTap(true)}
          onMouseOut={() => setTap(false)}
        >
          <button
            className="mobileBanner_Button_Item"
            onClick={() => {
              buttonFunc();
            }}
            type={"primary"}
          >
            {"Download"}
            <div className='mobileBAnner_ButtonIcon_Container'>
              {tap ? (
                <ButtonWhiteOn className="mobileBanner_buttonIcon" />
              ) : (
                <ButtonWhiteOff className="mobileBanner_buttonIcon" />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
