import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../funcComponent/UiComponents/UiCardTitle";
//TRANSLATIONS
import i18n from "../../I18next";
//icons
import SendNewCodeIco from "../../asset/svg/SendNewCodeIco";
import IcoPhone from "../../asset/svg/IcoPhone";
//UTILS
import Utilities from "../../utils/utilities";
//ActivationServices
import ActivationServices from "../../services/ActivationServices";
import { PROPERTIES } from "../../utils/properties";

export default class SendNewCode extends Component {

  sendNewCall = async () => {
    this.props.sendIsLoading(true)
    let querystring = require("querystring");
    let newModalOTP = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/generateotp",
      querystring.stringify({
        auth_req_id: this.props.auth_req_id,
      })
    );

    if (newModalOTP.status === 200) {
      this.props.sendIsLoading(false)
      this.props.success()
    }else{
      this.props.sendIsLoading(false)
      let testError=Utilities.callErrorTest(newModalOTP)
      if(testError!== false) this.props.setError(testError.textMessage, testError.isCorse, testError.title)
    }
  };

  render() {
    return (
      <>
        <UiCardTitle text={i18n.t("NewCode.TitleText")} />
        <p
          style={{
            marginBottom: "1.3rem",
            textAlign: "left",
          }}
        >
          {i18n.t("NewCode.Description")}
        </p>

        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.NewCode")}
              press={() => {
                this.sendNewCall();
              }}
            />
          </div>
        </div>
        <div className="loginImgContainer">
          <SendNewCodeIco />
        </div>

        <p
          style={{ marginTop: "1rem", marginBottom: "1rem", textAlign: "left" }}
        >
          {i18n.t("NewCode.FooterDescription")}
        </p>
        <div className="newCodePhoneRaw">
          <IcoPhone />
          <p style={{ margin: "1rem", textAlign: "left" }}>
            {i18n.t("NewCode.Phone") + " +49 30 620 080 8008"}
          </p>
        </div>
      </>
    );
  }
}
