import * as React from "react"

function IcoCloseCard(props) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle
          stroke="#FFF"
          strokeWidth={1.339}
          fill="#FFF"
          cx={12.5}
          cy={12.5}
          r={12.5}
        />
        <g fill="red" stroke="red" strokeWidth={0.5}>
          <path d="M7.964 6.956l10.08 10.08a.713.713 0 11-1.008 1.008L6.956 7.964a.713.713 0 111.008-1.008z" />
          <path d="M17.036 6.956l-10.08 10.08a.713.713 0 101.008 1.008l10.08-10.08a.713.713 0 10-1.008-1.008z" />
        </g>
      </g>
    </svg>
  )
}

export default IcoCloseCard