import React, {Component} from "react";
import "./css/homeCard.css";

//ANTD
import {EyeFilled} from "@ant-design/icons";
//TRANSLATIONS
import i18n from "../../I18next";
//COMPONENTS
import UiCollapsable from "../funcComponent/UiComponents/UiCollapsable";
import PopUp from "../funcComponent/Biometry_pop_up";
// REDUX
import {connect} from "react-redux";
import {setNav, setCardFlip} from "../../redux/ducks/navigationDuck";
import Utilities from "../../utils/utilities";
//SPINNER
import Spinner2 from "../funcComponent/Spinner2"
//icons
import CardVisa from "../../asset/svg/CardVisa";
import CardLogo from "../../asset/svg/CardLogo";
import ButtonGreyOff from "../../asset/svg/ButtonGreyOff";
import ButtonGreyOn from "../../asset/svg/ButtonGreyOn";
import IcoCloseCard from "../../asset/svg/IcoCloseCard";
import NoTransactions from "../../asset/svg/NoTransactions";
//IFRAME
import IframeParent from "../funcComponent/IframeParent";
import PromotionBanner from "./PromotionBanner";

class HomeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTransacPage: false,
            buttonHoover: false,
            visibility: false,
            showPop: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.dispatch(setCardFlip(false));
    }

    filteredTransactions = () => {
        let filtered = [...this.props.transactions.transactionList];
        filtered.splice(5);
        return filtered;
    };

    expandCollapseMobile = (data) => {
        this.props.transactionDetails(data);
    };

    callbackPopUp = () => {
        this.setState({showPop: false});
    };

    render() {
        return (
            <div className="HCPrincContainer">
                {
                    //BASE SCREEN
                }
                <div className="cardContainerView">
                    <div className="homeCardHeader">
                        <label className="cardTitle">{i18n.t("Card.Card")}</label>
                        <div
                            style={{
                                cursor: "pointer",
                                height: "25px",
                                alignSelf: "flex-end",
                                margin: "5px",
                            }}
                        >
                            {this.props.navigationDuck.cardFlip && (
                                <IcoCloseCard
                                    onClick={() => {
                                        this.props.dispatch(setCardFlip(false));
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    {this.state.showPop && (
                        <PopUp callback={() => this.callbackPopUp()}/>
                    )}

                    {this.props.navigationDuck.cardFlip ? (
                        <IframeParent
                            callback={() => this.setState({showPop: true})}
                            cardId={this.props.cardInfo.cardInfo[0].cardId}
                            setError={(modalText, isCorse, title) =>
                                this.props.setError(modalText, isCorse, title)
                            }
                        />
                    ) : (
                        <div
                            className={`bankCardContainer ${
                                this.props.navigationDuck.cardFlip && "flipped"
                            }`}
                            onClick={() => {
                                this.props.dispatch(setCardFlip(true));
                            }}
                        >
                            <div className="bankCardItem">
                                <div className="bankCardItemHeader">
                                    <div className="cardContentCircuitLogoContainer">
                                        <CardLogo/>
                                        <CardVisa/>
                                    </div>
                                </div>

                                {this.props.cardInfo !== false && ( //End Call controll
                                    <div>
                                        <div className="cardNumber">
                                            <label>
                                                {Utilities.cardNumberFommater(
                                                    this.props.cardInfo.cardInfo[0].cardNumber
                                                )}
                                            </label>
                                            {this.props.navigationDuck.cardFlip && (
                                                <EyeFilled
                                                    style={{color: "red"}}
                                                    onClick={() => this.setState({showPop: true})}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="cardContentContainer">
                                    <div className="cardContentLabelContainer">
                                        <div className="cardSingleLabelContainer">
                                            {!this.props.navigationDuck.cardFlip && (
                                                <div className="cardSingleLabelItem">
                                                    <label>{i18n.t("Card.FlexiGelId")}</label>
                                                </div>
                                            )}
                                            <div className="cardSingleLabelItem">
                                                <label>{i18n.t("Card.Expiry")}</label>
                                            </div>
                                            <div className="cardSingleLabelItem">
                                                <label>{i18n.t("Card.CVV")}</label>
                                            </div>
                                        </div>

                                        {this.props.cardInfo !== false && ( //End Call controll
                                            <div className="cardSingleLabelContainer">
                                                {!this.props.navigationDuck.cardFlip && (
                                                    <div className="cardSingleLabelItem">
                                                        <label>
                                                            {this.props.cardInfo.accountInfo.accountNumber}
                                                        </label>
                                                    </div>
                                                )}
                                                <div className="cardSingleLabelItem">
                                                    <label style={{marginRight: "2rem"}}>XX/XX</label>
                                                    {this.props.navigationDuck.cardFlip && (
                                                        <EyeFilled
                                                            style={{color: "red"}}
                                                            onClick={() => this.setState({showPop: true})}
                                                        />
                                                    )}
                                                </div>
                                                <div className="cardSingleLabelItem">
                                                    <label style={{marginRight: "3rem"}}>XXX</label>
                                                    {this.props.navigationDuck.cardFlip && (
                                                        <EyeFilled
                                                            style={{color: "red"}}
                                                            onClick={() => this.setState({showPop: true})}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!this.props.navigationDuck.cardFlip && (
                        <div className="cardStatusRow">
                            <label
                                style={{
                                    color: "white",
                                    marginRight: "1rem",
                                    fontSize: "16px",
                                    letterSpacing: 0,
                                    lineHeight: "23px",
                                }}
                            >
                                {i18n.t("Card.CardStatus")}
                            </label>
                            {this.props.cardInfo !== false && ( //End Call controll
                                <label
                                    style={{
                                        color: "white",
                                        marginRight: "1rem",
                                        fontSize: "22px",
                                        letterSpacing: 0,
                                        lineHeight: "23px",
                                        // fontWeight: "700",
                                        fontFamily: "Sparkasse_Bd"
                                    }}
                                >
                                    {Utilities.cardStatusConverter(this.props.cardStatus)}
                                </label>
                            )}
                        </div>
                    )}
                </div>

                {/* AVAILABLE AMOUNT ROW */}
                <div className="avaiableRow">
                    <div>
                        <label className="avaiableRow_lable">
                            {i18n.t("Card.AvailableAmount")}
                        </label>
                    </div>
                    {this.props.cardInfo !== false && ( //End Call controll
                        <div>
                            <label className="avaiableRow_amount">
                                {Utilities.availableAmountFormatter(
                                    this.props.cardInfo.dailyFinancials.availableAmount
                                )}
                            </label>
                        </div>
                    )}
                </div>

                {/* PROMOTIONS TOUR */}
                <div className="promotionTourSection">
                    <PromotionBanner promotions={this.props.promotions} 
                        setError={(modalText, isCorse, title) =>
                            this.props.setError(modalText, isCorse, title)
                        }
                    />
                </div>

                {/*Spinner to Update transaction list after change lang */}
                {this.props.transactionsDuck.isLoading ? (
                    <div className="homeCardLoaderContainer">
                        <Spinner2 className="spinner"/>
                    </div>
                ) : (
                    <>
                        {/*NORMAL VIEW FOR TRANSACTIONS SECTION*/}
                        {this.props.transactions !== false &&
                            /*  CONTROL FOR SHOW PRINCIPAL TRANSACTION VIEW  (FULL) */
                            this.props.transactions?.transactionList.length > 0 && (
                                <div className="transactionClosePageContainer">
                                    <div className="transaction_ClosePage_Header">
                                        <label>{i18n.t("Transactions.Transactions")}</label>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="transaction_ClosePage_lastTransactions_Container">
                                            <UiCollapsable
                                                data={this.filteredTransactions()}
                                                size={this.props.size}
                                                mobileOnClick={this.expandCollapseMobile}
                                                isHome={true}
                                            />
                                        </div>

                                        {/*Button */}
                                        <div className="transaction_ClosePage_ButtonContainer">
                                            <div
                                                className="transaction_ClosePage_ButtonItem"
                                                onClick={() => {
                                                    this.props.dispatch(setNav(1));
                                                    this.props.navigationFunc(1);
                                                }}
                                                onMouseOver={() =>
                                                    this.setState({buttonHoover: true})
                                                }
                                                onMouseOut={() =>
                                                    this.setState({buttonHoover: false})
                                                }
                                            >
                                                <label className="seeAllTransactionButton">
                                                    {i18n.t("Transactions.SeeAllTransactions")}
                                                </label>
                                                {this.state.buttonHoover ? (
                                                    <ButtonGreyOn/>
                                                ) : (
                                                    <ButtonGreyOff/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {this.props.transactions !== false &&
                            /*  CONTROL FOR SHOW PRINCIPAL TRANSACTION VIEW  (EMPTY) */
                            this.props.transactions?.transactionList.length === 0 && (
                                <div className="transactionClosePageContainer">
                                    <div className="transaction_ClosePage_EmptyContainer">
                                        <div
                                            style={{
                                                height: "3.7rem",
                                                width: "3.7rem",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <NoTransactions/>
                                        </div>
                                        <p>{i18n.t("Transactions.NoTransactions")}</p>
                                    </div>
                                </div>
                            )}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    navigationDuck: state.navigationDuck,
    transactionsDuck: state.transactionsDuck,
    promotionsDuck: state.promotionsDuck
});

export default connect(mapStateToProps)(HomeCard);
