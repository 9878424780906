import * as React from "react"

function MenuStatementsOff(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"MenuStatementsOff"}</title>
      <g fill="#666" stroke="#666" strokeWidth={0.5} fillRule="evenodd">
        <path d="M7.085 23.34H19.82c.088 0 .176.002.263-.004.545-.034.934-.304 1.149-.795.194-.443.102-.911.104-1.369H7.83c.055.81-.116 1.538-.746 2.169h0zM3.664 1.68V21.347c0 .063-.002.127 0 .19.03.953.743 1.687 1.686 1.735.911.047 1.7-.63 1.81-1.573.03-.265.011-.537.01-.806 0-.271.135-.41.409-.405.08.002.159.007.239.007h10.887c.084 0 .168-.008.251-.012V1.68H3.664zm15.97 18.816H21.568c.21 0 .41.054.415.294.01.537.048 1.084-.032 1.61-.144.956-.983 1.597-1.993 1.597-4.745.002-9.49 0-14.235.004-.467 0-.925-.025-1.348-.241-.823-.421-1.286-1.086-1.357-2.007-.01-.142-.01-.285-.01-.427 0-6.528.006-13.056-.007-19.583-.002-.867.149-.735.757-.735h15.335c.481 0 .543.063.543.55v18.938z" />
        <path d="M11.392 17.105l-5.427-.001c-.087 0-.178.006-.261-.014-.167-.04-.26-.153-.255-.327.005-.174.106-.277.273-.312.07-.014.143-.007.215-.007h10.926c.064 0 .128-.005.191.003.182.025.294.13.3.312.008.195-.105.309-.296.34-.078.012-.159.005-.238.005h-5.428M11.404 9.653h-5.45c-.08 0-.182.026-.235-.012-.104-.078-.225-.179-.26-.293-.047-.16.063-.29.23-.334.082-.023.173-.02.26-.02H16.85c.071 0 .145-.006.214.006.18.032.291.139.29.323-.001.193-.114.305-.309.327-.07.008-.143.003-.215.003h-5.427M11.397 5.278h5.432c.08 0 .16-.004.239.005.175.02.275.129.286.297.012.17-.072.292-.243.333-.084.02-.174.02-.26.02H5.962c-.08 0-.16.003-.238-.011-.181-.034-.283-.15-.275-.333.008-.182.114-.29.3-.307.072-.007.144-.003.216-.003h5.431M11.401 13.378l-5.406-.001c-.096 0-.193.003-.286-.014-.175-.03-.263-.149-.26-.319.004-.17.096-.283.272-.309.078-.011.16-.007.239-.007h10.885c.355 0 .51.096.514.317.004.23-.158.332-.527.332h-5.43" />
      </g>
    </svg>
  )
}

export default MenuStatementsOff
