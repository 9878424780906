import React, { useEffect } from "react";

//MOMENTJS
import moment from "moment";
//STYLE
import "./css/statementListCard.css";
//ICON
import Pdf from "../../asset/svg/Pdf";
import StatementIco from "../../asset/svg/StatementIco";
//UTILITIES
import Utilities from "../../utils/utilities";

export default function StatementListCard(props) {
  //Device
  // const [mobile, setMobile] = useState(false);

  var year = "";
  const printYear = (statement, i) => {
    let y = "";
    if (i === 0) {
      y = moment(statement.statementDate).format("YYYY");
      year = y;
      return y;
    } else {
      if (year !== moment(statement.statementDate).format("YYYY")) {
        y = moment(statement.statementDate).format("YYYY");
        year = y;
        return y;
      }
    }
    return "";
  };
  //did mount to find if device is apple web or android
  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    let MobileTest = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });

    if (MobileTest === true) {
      // setMobile(true);
    }
  }, []);

  const singleStatementCall = async (statementId) => {
    let data = await Utilities.getPDF(props.cardId, statementId);
    if (data?.status === 200) {
      var blob = new Blob([data.data], { type: "application/pdf" });
      var URL = window.URL || window.webkitURL;
      var dataUrl = URL.createObjectURL(blob);

      props.setIsLoading(false);

      //To download directly pdf
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = Utilities.getStatementFileName(""+statementId); //Set filName

      document.body.append(link);
      link.click();
      link.remove();

      //To view pdf
      // if (mobile) {
      //   window.location.href = dataUrl;
      // } else {
      //   let win = window.open("", "_blank");

      //   win.location = dataUrl;
      //   console.log(win)
      //   setTimeout(function() {
      //     win.document.title = ""+statementId;
      // }, 10);
      // }
    } else {
      let testError = Utilities.callErrorTest(data);
      props.setIsLoading(false);
      if (testError !== false)
        props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };

  return (
    <div className="statementListCard_Container">
      {props.statementList &&
        props.statementList.map((statement, index) => (
          <div className="statementListCard_allBox_Container" key={index}>
            {/* //Year */}
            <div className="statementListCard_year_Container">
              <label>{printYear(statement, index)}</label>
            </div>
            {/* //Single box */}
            <div
              className="statementListCard_SingleBox_Container"
              // style={{borderTop:index===0? '1px solid #D9D9D9': 'none' }}
              onClick={() => {
                props.setIsLoading(true);
                singleStatementCall(statement.statementId);
              }}
            >
              <div className="statementListCard_SingleBox_iconAndTitle-Raw">
                <StatementIco />
                <label style={{ cursor: "pointer" }}>
                  {moment(statement.statementDate).format("MMM YYYY")}
                </label>
              </div>

              <Pdf />
            </div>
          </div>
        ))}
    </div>
  );
}
