import React, { Component } from "react";

//STYLE
import "./css/mobileSideBar.css";
//ANTD
import "antd/dist/antd.css";
//UTILS
import Utilities from "../../utils/utilities";

//Icons
import ButtonWhiteOff from "../../asset/svg/ButtonWhiteOff";
import ButtonRedOn from "../../asset/svg/ButtonRedOn";
import MenuHomeOffMobile from "../../asset/svg/MenuHomeOffMobile";
import MenuHomeOn from "../../asset/svg/MenuHomeOn";
import MenuTransactionsOffMobile from "../../asset/svg/MenuTransactionsOffMobile";
import MenuTransactionsOn from "../../asset/svg/MenuTransactionsOn";
import MenuStatementsOn from "../../asset/svg/MenuStatementsOn";
import MenuStatementsOffMobile from "../../asset/svg/MenuStatementsOffMobile";
import MenuCardSettingOn from "../../asset/svg/MenuCardSettingOn";
import MenuCardSettingOffMobile from "../../asset/svg/MenuCardSettingOffMobile";
import MenuFaqOffMobile from "../../asset/svg/MenuFaqOffMobile";
import MenuFaqOn from "../../asset/svg/MenuFaqOn";
import MenuTermsConditionsOffMobile from "../../asset/svg/MenuTermsConditionsOffMobile";
import MenuTermsConditionsOn from "../../asset/svg/MenuTermsConditionsOn";
import MenuProfileOffMobile from "../../asset/svg/MenuProfileOffMobile";
import MenuProfileOn from "../../asset/svg/MenuProfileOn";
import MenuLogoutOffMobile from "../../asset/svg/MenuLogoutOffMobile";

//TRANSLATIONS
import i18n from "../../I18next";
// REDUX
import { connect } from "react-redux";
import { setNav } from "../../redux/ducks/navigationDuck";

class MobileSideBar extends Component {
  constructor(props){
    super(props)
    this.state={
      showLogoutModal:false
    }
  }

  render() {
    return (
      <div 
      className="sidebarOverlay"
      style={{height: this.props.height  - 80}}>
        <div className="mobileSidebarContainer"
        style={{height: this.props.height - 80}}>
          <div>
            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 0 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(0));
                this.props.navigationFunc(0);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">

                  {this.props.navigationDuck.navigationPointer === 0
                    ? <MenuHomeOn className='menuIcon' />
                    : <MenuHomeOffMobile className='menuIcon' />
                  }

                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 0
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("SideBar.Home")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 0
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 1 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(1));
                this.props.navigationFunc(1);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 1
                      ? <MenuTransactionsOn className='menuIcon' />
                      : <MenuTransactionsOffMobile className='menuIcon' />
                  }
                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 1
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("SideBar.Transactions")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 1
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 2 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(2));
                this.props.navigationFunc(2);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 2
                      ? <MenuStatementsOn className='menuIcon' />
                      : <MenuStatementsOffMobile className='menuIcon' />
                  }

                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 2
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("SideBar.Statements")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 2
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 3 && "selected"
                }`}
              onClick={() => {
                 //CardStatus controll
                 if(this.props.cardStatus==="AA"|| this.props.cardStatus==="AA_006"|| this.props.cardStatus==="TS" || this.props.cardStatus==="PD" || this.props.cardStatus==="SI"){
                this.props.dispatch(setNav(3));
                this.props.navigationFunc(3);
              }else{
                let obj={
                  title:i18n.t("Error.LimitedFunctionality"),
                  text:"" +
                  i18n.t("Error.CardStatusCase2_1") +
                  Utilities.cardStatusConverter(this.props.cardStatus) +
                  i18n.t("Error.CardStatusCase5_2"),
                  button: i18n.t("Buttons.OK")
                }
                this.props.funcWrongCardStatus(obj)
              }
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 3
                      ? <MenuCardSettingOn className='menuIcon' />
                      : <MenuCardSettingOffMobile className='menuIcon' />
                  }
                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 3
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("SideBar.CardSetting")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 3
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 4 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(4));
                this.props.navigationFunc(4);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 4
                      ? <MenuFaqOn className='menuIcon' />
                      : <MenuFaqOffMobile className='menuIcon' />
                  }
                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 4
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("SideBar.FAQ")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 4
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 5 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(5));
                this.props.navigationFunc(5);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 5
                      ? <MenuTermsConditionsOn className='menuIcon' />
                      : <MenuTermsConditionsOffMobile className='menuIcon' />
                  }
                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 5
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                    style={{ textAlign: 'left' }}
                  >
                    {i18n.t("SideBar.TermsAndConditions")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 5
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>
          </div>

          {/* PROFILE AND LOGOUT */}
          <div className="bottomContainer">
            <div
              className={`singleCardContainerMobSidebar ${this.props.navigationDuck.navigationPointer === 6 && "selected"
                }`}
              onClick={() => {
                this.props.dispatch(setNav(6));
                this.props.navigationFunc(6);
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  {
                    this.props.navigationDuck.navigationPointer === 6
                      ? <MenuProfileOn className='menuIcon' />
                      : <MenuProfileOffMobile className='menuIcon' />
                  }
                  <label
                    className={
                      this.props.navigationDuck.navigationPointer === 6
                        ? "mobSidebar_labelSelect"
                        : "mobSidebar_labelDeSelect"
                    }
                  >
                    {i18n.t("NavBar.Profile")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  {this.props.navigationDuck.navigationPointer === 6
                    ? <ButtonRedOn />
                    : <ButtonWhiteOff />
                  }
                </div>
              </div>
            </div>

            {/* LOGOUT */}
            <div
              className="singleCardContainerMobSidebar"
              onClick={() => {
                this.props.showLogoutModal();
              }}
            >
              <div className="singleCardItemSidebar">
                <div className="sidebarLabel">
                  <MenuLogoutOffMobile className='menuIcon' />
                  <label className="mobSidebar_labelDeSelect">
                    {i18n.t("NavBar.Logout")}
                  </label>
                </div>
                <div className="sidebarIcon">
                  <ButtonWhiteOff />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
});

export default connect(mapStateToProps)(MobileSideBar);
