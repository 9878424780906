import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
import UiInputBox from "../../funcComponent/UiComponents/UiInputBox";
//TRANSLATIONS
import i18n from "../../../I18next";
//UTILS
import Utilities from "../../../utils/utilities";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
//Antd
import { CheckOutlined } from "@ant-design/icons";
import { PROPERTIES } from "../../../utils/properties";

export default class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flexiGelId: "",
      pw1: "",
      pw2: "",
    };
  }

  createPWFunc = async () => {
    if (this.state.pw1 !== this.state.pw2) {
      let isCorse = false;
      this.props.setError(
        i18n.t("Error.DifferentPw"),
        isCorse,
        i18n.t("Error.IncorrectEntries")
      );
    } else {
      this.props.isLoading(true);
      let querystring = require("querystring");
      let createPWCall = await ActivationServices.post(
        this.props.url + "/registration_data",
        querystring.stringify({
          membershipNumber: PROPERTIES.FLEXI_ID_PREFIX + this.state.flexiGelId,
          password: "" + this.state.pw1,
          forgotPassword: this.props.isForget,
        })
      );
      if (createPWCall.status === 200) {
        this.props.isLoading(false);
        this.props.setProgressive(
          createPWCall.data.state,
          createPWCall.data.params[0].value,
          createPWCall.data.params[1].value
        );
      }
      if (createPWCall.status === 667) {
        let isCorse = true;
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
      }
      if (createPWCall.status === 500 || createPWCall.status === 400) {
        this.props.isLoading(false);
        let title = "";
        let modalText = "";

        //Omega control
        if (createPWCall.data?.errorMessage?.split("Ω").length === 2) {
          let message = createPWCall.data?.errorMessage.split("Ω");
          title = message[0];
          modalText = message[1];
        } else {
          //"500 null" CONTROLL message
          if (
            createPWCall.data.errorMessage === "500 null" ||
            createPWCall.data?.errorMessage === undefined ||
            createPWCall.data?.errorMessage.substring(0, 21) ===
              "Internal server error"
          ) {
            modalText = i18n.t("Error.NewOtp_generic");
          } else {
            modalText = createPWCall.data.errorMessage;
            //Test title from be
            if (createPWCall.data?.errorTitle) {
              title = createPWCall.data.errorTitle;
            } else {
              title = i18n.t("Error.IncorrectEntries");
            }
          }
        }
        let isCorse = false;
        this.props.setError(modalText, isCorse, title);
      }
    }
  };
  render() {
    return (
      <div
        className="loginInputCardContainer"
        style={{ display: this.props.display && "none" }}
      >
        <div className="CardTitle">
          <UiCardTitle
            text={
              this.props.isForget
                ? i18n.t("ForgetPasswordLogin.TitleText")
                : i18n.t("CreatePasswordLogin.TitleText")
            }
          />
          <UiCardTitle
            text={
              this.props.isForget
                ? i18n.t("ForgetPasswordLogin.SubTitle")
                : i18n.t("CreatePasswordLogin.SubTitle")
            }
          />
        </div>

        <div>
          <UiInputBox
            title={i18n.t("Placeholder.FlexiGelId")}
            type="text"
            placeholder={i18n.t("Placeholder.EnterFlexiGelId")}
            changeVal={(event) => {
              if (!isNaN(event) && event[event.length - 1] !== ".") {
                this.setState({ flexiGelId: event });
              }
            }}
            flexiType={true}
            value={this.state.flexiGelId}
            onPressEnter={() => {
              if (
                this.state.flexiGelId &&
                this.state.pw1 &&
                this.state.pw2 &&
                this.state.pw1.length >= 8 &&
                Utilities.uppercasePwControll(this.state.pw1) &&
                Utilities.specialCharPwControll(this.state.pw1) &&
                Utilities.numberPwControll(this.state.pw1)
              )
                this.createPWFunc();
            }}
          />
          <UiInputBox
            title={i18n.t("Placeholder.Password")}
            type="password"
            placeholder={i18n.t("Placeholder.Password")}
            value={this.state.pw1}
            changeVal={(event) => {
              this.setState({ pw1: event });
            }}
            onPressEnter={() => {
              if (
                this.state.flexiGelId &&
                this.state.pw1 &&
                this.state.pw2 &&
                this.state.pw1.length >= 8 &&
                Utilities.uppercasePwControll(this.state.pw1) &&
                Utilities.specialCharPwControll(this.state.pw1) &&
                Utilities.numberPwControll(this.state.pw1)
              )
                this.createPWFunc();
            }}
          />
        </div>
        <div className="loginInputRaw">
          <UiInputBox
            title={i18n.t("Placeholder.ReapeatPassword")}
            type="password"
            placeholder={i18n.t("Placeholder.ReapeatPassword")}
            value={this.state.pw2}
            changeVal={(event) => {
              this.setState({ pw2: event });
            }}
            onPressEnter={() => {
              if (
                this.state.flexiGelId &&
                this.state.pw1 &&
                this.state.pw2 &&
                this.state.pw1.length >= 8 &&
                Utilities.uppercasePwControll(this.state.pw1) &&
                Utilities.specialCharPwControll(this.state.pw1) &&
                Utilities.numberPwControll(this.state.pw1)
              )
                this.createPWFunc();
            }}
          />
        </div>

        {/* Password controll */}
        <div className="login_passwordControll_container">
          <label>{i18n.t("CreatePasswordLogin.PWControllTitle")}</label>

          <div>
            <CheckOutlined
              style={{
                marginRight: "1.5rem",
                fontSize: "1rem",
                color: Utilities.uppercasePwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
              }}
            />

            <label
              style={{
                color: Utilities.uppercasePwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
                fontWeight: Utilities.uppercasePwControll(this.state.pw1)
                  ? "700"
                  : "500",
              }}
            >
              {i18n.t("CreatePasswordLogin.PWControllUpperCase")}
            </label>
          </div>

          <div>
            <CheckOutlined
              style={{
                marginRight: "1.5rem",
                fontSize: "1rem",
                color: Utilities.specialCharPwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
              }}
            />
            <label
              style={{
                color: Utilities.specialCharPwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
                fontWeight: Utilities.specialCharPwControll(this.state.pw1)
                  ? "700"
                  : "500",
              }}
            >
              {i18n.t("CreatePasswordLogin.PWControllSpecialChar")}
            </label>
          </div>

          <div>
            <CheckOutlined
              style={{
                marginRight: "1.5rem",
                fontSize: "1rem",
                color: Utilities.numberPwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
              }}
            />
            <label
              style={{
                color: Utilities.numberPwControll(this.state.pw1)
                  ? "black"
                  : "#666666",
                fontWeight: Utilities.numberPwControll(this.state.pw1)
                  ? "700"
                  : "500",
              }}
            >
              {i18n.t("CreatePasswordLogin.PWControllNumber")}
            </label>
          </div>
        </div>

        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Continue")}
              disabled={
                !this.state.flexiGelId ||
                !this.state.pw1 ||
                !this.state.pw2 ||
                this.state.pw1.length < 8 ||
                !Utilities.uppercasePwControll(this.state.pw1) ||
                !Utilities.specialCharPwControll(this.state.pw1) ||
                !Utilities.numberPwControll(this.state.pw1)
              }
              press={() => {
                this.createPWFunc();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
