import * as React from "react"

function SvgComponent(props) {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: '100%',
          height: '100%',
          margin: "auto",
          animationPlayState: "running",
          animationDelay: "0s",
          alignSelf: 'center',
        }}
        width={110}
        height={110}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        display="block"
        {...props}
      >
        <path
          d="M10 50a40 40 0 0080 0 40 41.3 0 01-80 0"
          fill="red"
          style={{
            animationPlayState: "running",
            animationDelay: "0s",
          }}
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 50.65;360 50 50.65"
          />
        </path>
      </svg>
  )
}

export default SvgComponent
