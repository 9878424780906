//Import i18next for translations
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
//Import json langs
import translationDe from './asset/translations/de.json'
import translationEn from './asset/translations/en.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: {
        translation: translationDe,
      },
      en: {
        translation: translationEn,
      },
    },
    lng: 'de',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
