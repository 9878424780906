import * as React from "react";

function ButtonGreyOnReverse(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"ButtonGreyOn"}</title>
      <g
        fill="none"
        fillRule="evenodd"
        transform=" translate(1 1) rotate(180,10,10)"
      >
        <circle
          stroke="#666"
          strokeWidth={1.4}
          fill="#666"
          cx={10}
          cy={10}
          r={10}
        />
        <path
          d="M9.008 12.96a.286.286 0 01-.184.063.286.286 0 01-.184-.062.185.185 0 010-.3l3.233-2.63L8.64 7.398c-.101-.082-.101-.217 0-.299a.304.304 0 01.368 0l3.416 2.78c.101.083.101.217 0 .3l-3.416 2.78z"
          stroke="#FFF"
          strokeWidth={0.9}
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default ButtonGreyOnReverse;
