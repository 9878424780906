import * as React from "react"

function IcoSearch(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.509 1.99C4.914 1.99 2 4.888 1.99 8.473c-.01 3.585 2.915 6.5 6.52 6.5 3.6-.001 6.507-2.893 6.517-6.481.01-3.584-2.915-6.502-6.518-6.502zM8.979 0c.355.05.712.087 1.065.15 1.61.29 3.015.99 4.22 2.094a8.31 8.31 0 012.512 4.218c.487 1.974.228 3.873-.615 5.709-.171.373-.2.243.102.546.991.993 1.989 1.98 2.981 2.972.91.91 1.01 2.35.239 3.376-.829 1.104-2.452 1.257-3.509.316-.385-.342-.74-.718-1.105-1.081-.741-.736-1.482-1.471-2.218-2.212-.099-.099-.173-.115-.302-.053-1.305.623-2.675.972-4.128.916-2.003-.076-3.775-.758-5.29-2.073C1.494 13.63.55 12.08.185 10.218-.354 7.471.281 5.011 2.126 2.89 3.365 1.465 4.93.57 6.781.186 7.125.115 7.48.088 7.828.04c.07-.01.14-.026.21-.039h.941z"
        fill="red"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default IcoSearch
