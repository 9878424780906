//Action
const SET_BCTOKEN='web/bctoken/SET_BCTOKEN'
const INIT_BCTOKEN='web/bctoken/INIT_BCTOKEN'

//Action Set Creators
export function setBcToken(valueObj) {
    return {
        type: SET_BCTOKEN,
        paylod: {
           bctoken: valueObj
        }
    }
}

//Action init Creators
export function initBcToken() {
    return {
        type: INIT_BCTOKEN,
        paylod: {} 
    }
}
const INIT_STATE = {
    bctoken: null
}

//Reducer
export default function bcTokenDuck(state = INIT_STATE, action) {
    var newState
    switch (action.type) {
        case SET_BCTOKEN:
            newState = Object.assign({}, state)
            newState.bctoken = action.paylod.bctoken
            return newState;
        case INIT_BCTOKEN:
            newState = Object.assign({}, state)
            newState.bctoken = null
            return newState
        default:
            return state;
    }
}