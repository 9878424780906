import React, { Component } from 'react'

//STYLE
import './css/changeEPin.css'
//TRANSLATIONS
import i18n from '../../I18next';
//UICOMPONENT
import UiInputBox from '../funcComponent/UiComponents/UiInputBox'
import UiButton from '../funcComponent/UiComponents/UiButton'

//Ant_D COMPONENT
import 'antd/dist/antd.css';
import { CheckCircleOutlined } from '@ant-design/icons';
// PROPERTIES
import { PROPERTIES } from "../../utils/properties";
// UTILITIES
import Utilities from "../../utils/utilities";
import ScopeListTester from '../../utils/scopeListTester';
// BCAUTHORIZE
import bcAuthorize from "../../utils/bcAuthorize";
// REDUX
import { connect } from "react-redux";
import { setBcAuth } from "../../redux/ducks/bcAuthDuck";
import { initBcToken } from "../../redux/ducks/bcTokenDuck";
// AXIOS
import axios from "axios";
//SPINNER
import SpinnerLogin from "../funcComponent/SpinnerLogin"

class ChangeEPin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EPIN1: '',
            EPIN2: '',
            showSuccessView: false,
            cardVbv: "",
            callInfo: null,
            test: null,
            isLoading: false
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        // //CardStatus for e-Pin  Verification
        // let ePinStatus = await GenericServices.get(
        //     "https://" + PROPERTIES.BASE_URL_OBP + "/sbacs/v1/" + this.props.cardID + "/cardstatus"
        // );
        // if (ePinStatus.status === 200) {
        //     if (!ePinStatus.data.setPin) {
        //         this.setState({
        //             cardVbv: ePinStatus.data.cardVbvStatus,
        //         });
        //     }
        // } else {
        //     let testError = Utilities.callErrorTest(ePinStatus)
        //     if (testError !== false) this.props.setError(testError.textMessage, testError.isCorse, testError.title);
        // }
    }


    handlePress = async () => {
        let url =
            "https://" +
            PROPERTIES.BASE_URL_OBP +
            "/sbacs/v1/" +
            this.props.cardID +
            "/setpin";

        let obj = { userPin: this.state.EPIN1 };

        let callInfo = {
            method: "PUT",
            url: url,
            data: obj,
        };

        //Scope list test method
        let test = await ScopeListTester.callTester(
            this.props.scopeListDuck.scopeList,
            callInfo.method,
            url
        );

        this.setState({ callInfo, test })

        if (!test) {

            this.ChangeEPinCall()

        } else {
            let BCACall = await bcAuthorize.call(test.scope);
            this.setState({ isLoading: false })

            if (BCACall.status === 200) {
                BCACall.data.title = i18n.t("CardSetting.ChangeEPin")
                BCACall.data.description = i18n.t("PopUp.mfaChangeEPINDescription")
                BCACall.data.scope = test.scope

                this.props.dispatch(setBcAuth(BCACall.data));
            } else {
                let testError = Utilities.callErrorTest(BCACall)
                if (testError !== false) this.props.setError(testError.textMessage, testError.isCorse, testError.title);
            }
        }
    }

    ChangeEPinCall = async (bctoken) => {

        let token
        if (!bctoken) {
            const { access_token } = Utilities.getToken();
            token = access_token
        } else {
            token = bctoken
        }

        let ePinCall = await axios({
            method: this.state.callInfo.method,
            url: this.state.callInfo.url,
            data: this.state.callInfo.data,
            headers: {
                Accept: "*/*",
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
            },
        });
        if (ePinCall.status === 200) {
            this.setState({ isLoading: false, showSuccessView: true })
        } else {
            this.setState({ isLoading: false })
            let testError = Utilities.callErrorTest(ePinCall)
            if (testError !== false) this.props.setError(testError.textMessage, testError.isCorse, testError.title);
        }
    }

    componentDidUpdate() {
        if (this.props.bcTokenDuck.bctoken?.token && this.props.bcTokenDuck.bctoken?.scope === this.state.test?.scope) {
            this.setState({ isLoading: true, test: null })
            this.ChangeEPinCall(this.props.bcTokenDuck.bctoken.token)
            this.props.dispatch(initBcToken());
        }
    }

    render() {
        return (
            <div className='changeEPin_Princ_Container'>
                {//After call spinner
                    this.state.isLoading &&
                    <div className="changeEPin_AfterCall_Spinner">
                        <SpinnerLogin />
                    </div>
                }
                <div className='changeEPin_headerRow'>

                    <div className='changeEPin_headerRow_Title'>
                        <label>{i18n.t("CardSetting.ChangeEPin")}</label>
                    </div>

                    <div className='changeEPin_headerRow_History'>
                        <label className='changeEPin_Navigability' onClick={this.props.goBackFunc}>{i18n.t("CardSetting.CardSetting")}</label>
                        <label style={{ color: 'red', fontWeight: '700' }}>{" > "}</label>
                        <label className='changeEPin_Navigability_right'>{i18n.t("CardSetting.ChangeEPin")}</label>
                    </div>

                </div>

                <div className='changeEPin_Card'>

                    {// START VIEW

                        !this.state.showSuccessView &&

                        <div className='changeEPin_Card_ItemContainer'>

                            <div className='changeEPin_Card_TitleRow'>
                                <label>{i18n.t("CardSetting.ChangeItToContinueMakingECommercePayments")}</label>
                            </div>

                            <div>
                                <UiInputBox
                                    title={i18n.t("Placeholder.EPIN")}
                                    type="password"
                                    placeholder={i18n.t("Placeholder.EPIN")}
                                    changeVal={(event) => {
                                        if (!isNaN(event) && event[event.length - 1] !== ".") {
                                            this.setState({ EPIN1: event });
                                        }
                                    }}
                                    value={this.state.EPIN1}
                                    isPIN={true}
                                    numericKeyboard={true}
                                    onPressEnter={() => {
                                        if((this.state.EPIN1 !== '') && (this.state.EPIN2 !== '')){
                                            if ((this.state.EPIN1 === this.state.EPIN2) && this.state.EPIN1.length === 6) {
                                                this.setState({ isLoading: true })
                                                this.handlePress()
                                            } else {
                                                if (this.state.EPIN1 !== this.state.EPIN2) {
                                                    this.props.setError(i18n.t("Error.DifferentEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                                }
                                                if (this.state.EPIN1.length !== 6 || this.state.EPIN2.length !== 6) {
                                                    this.props.setError(i18n.t("Error.ShortEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div>
                                <UiInputBox
                                    title={i18n.t("Placeholder.RepeatEPIN")}
                                    type="password"
                                    placeholder={i18n.t("Placeholder.RepeatEPIN")}
                                    changeVal={(event) => {
                                        if (!isNaN(event) && event[event.length - 1] !== ".") {
                                            this.setState({ EPIN2: event });
                                        }
                                    }}
                                    isPIN={true}
                                    value={this.state.EPIN2}
                                    numericKeyboard={true}
                                    onPressEnter={() => {
                                        if((this.state.EPIN1 !== '') && (this.state.EPIN2 !== '')){
                                            if ((this.state.EPIN1 === this.state.EPIN2) && this.state.EPIN1.length === 6) {
                                                this.setState({ isLoading: true })
                                                this.handlePress()
                                            } else {
                                                if (this.state.EPIN1 !== this.state.EPIN2) {
                                                    this.props.setError(i18n.t("Error.DifferentEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                                }
                                                if (this.state.EPIN1.length !== 6 || this.state.EPIN2.length !== 6) {
                                                    this.props.setError(i18n.t("Error.ShortEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div className='changeEPin_Card_ButtonContainer'>
                                <UiButton
                                    title={i18n.t('Buttons.Confirm')}
                                    disabled={((this.state.EPIN1 === '') || (this.state.EPIN2 === '')) ? true : false}
                                    press={() => {
                                        if ((this.state.EPIN1 === this.state.EPIN2) && this.state.EPIN1.length === 6) {
                                            this.setState({ isLoading: true })
                                            this.handlePress()
                                        } else {
                                            if (this.state.EPIN1 !== this.state.EPIN2) {
                                                this.props.setError(i18n.t("Error.DifferentEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                            }
                                            if (this.state.EPIN1.length !== 6 || this.state.EPIN2.length !== 6) {
                                                this.props.setError(i18n.t("Error.ShortEPIN"), false, i18n.t("Error.IncorrectEntries"),)
                                            }

                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {// SUCCESS VIEW
                        this.state.showSuccessView &&
                        <div className='changeEPin_Card_ItemContainer'>

                            <div className='changeEPin_Card_Success_TitleRow'>
                                <label>{i18n.t("CardSetting.EPINChangeSuccessfully")}</label>
                            </div>

                            <div className='changeEPin_Card_Success_SubTitleRow'>
                                <label>{i18n.t("CardSetting.NowYouCanMakeECommercePayments")}</label>
                            </div>

                            <CheckCircleOutlined style={{ color: 'red', fontSize: '10rem', marginBottom: '-1rem' }} />

                            <div className='changeEPin_Card_ButtonContainer'>
                                <UiButton
                                    title={i18n.t('Buttons.Close')}
                                    disabled={false}
                                    press={this.props.goBackFunc}
                                />
                            </div>

                        </div>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    scopeListDuck: state.scopeListDuck,
    bcTokenDuck: state.bcTokenDuck,
});

export default connect(mapStateToProps)(ChangeEPin);