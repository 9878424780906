import * as React from "react"

function ButtonOffBottom(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(1 1)"
        stroke="#666"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeWidth={1.4} cx={10} cy={10} r={10} />
        <path
          d="M6.54 8.976a.261.261 0 01-.072-.184.26.26 0 01.072-.184.24.24 0 01.35 0l3.078 3.233 3.077-3.233a.24.24 0 01.35 0 .27.27 0 010 .368l-3.252 3.416a.24.24 0 01-.35 0L6.54 8.976z"
          strokeWidth={0.9}
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default ButtonOffBottom
