import * as React from "react"

function Menu(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"menu"}</title>
      <g fill="#FFF" fillRule="evenodd">
        <path d="M23.237 11.811c.832.001 1.47.3 1.703.897.012.032.04.06.06.091v.459c-.135.176-.242.37-.412.526-.298.275-.712.424-1.2.425-3.481.004-6.962.003-10.444.003-3.757 0-7.515-.005-11.273.004-.687.002-1.346-.382-1.564-.8-.342-.655.23-1.375 1.083-1.552.184-.038.382-.053.574-.053 7.157-.002 14.315-.004 21.473 0zM23.217 17.596c.78 0 1.467.27 1.69.857.015.042.061.077.093.116v.49c-.033.03-.083.057-.095.091-.188.508-.839.843-1.704.848-.228.002-.456 0-.683 0-6.872 0-13.744 0-20.616.002-.728 0-1.305-.171-1.688-.64-.463-.567-.136-1.284.614-1.579.285-.112.643-.18.968-.18 7.14-.01 14.28-.01 21.421-.005zM23.166 6.003c.665 0 1.245.155 1.596.6l.086.121.152.25v.492c-.033.03-.082.057-.095.09-.212.555-.903.848-1.702.85-1.268.002-2.537 0-3.806 0l-17.54.001c-.723 0-1.283-.188-1.653-.653-.498-.624-.026-1.415.858-1.651.244-.066.52-.099.781-.099C8.951 6 16.058 6 23.166 6.003z" />
      </g>
    </svg>
  )
}

export default Menu
