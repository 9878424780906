import React, { useEffect, useState } from "react";
import "./css/privacyNotice.css";
//TRANSLATIONS
import i18n from "../../I18next";
//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
//SPINNER
import Spinner1 from "./Spinner1";
//Utils
import Utilities from "../../utils/utilities";

export default function PrivacyNotice(props) {
  const [PN, setPN] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPrivacyNotice();
  },[]);

  const getPrivacyNotice = async () => {
    let contentID = "dc71ae51-ab35-45c0-9a84-0d4e4e40638d";
    let privacyNoticeCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_NPC +
      "/sbcontent/v1/content/" +
      contentID +
      "/digest/1234"
    );
    if (privacyNoticeCall.status === 200) {
      let pn = privacyNoticeCall.data.content;

      setPN(pn);
      setIsReady(true);
    } else {
      if (privacyNoticeCall.status === 404) {
        props.setError(i18n.t("Error.NewOtp_generic"), false, "");
      } else {
        let testError = Utilities.callErrorTest(privacyNoticeCall);
        if (testError !== false)
          props.setError(testError.textMessage, testError.isCorse, testError.title);
      }
    }
  };

  return (
    <div className="PN_Princ_Container">
      <div className="PN_Header">
        <label>{i18n.t("UserCard.PrivacyNotice")}</label>
      </div>
      <div className="PN_Body">
        {!isReady && (
          <div className="PN_secondondarySpinner_Continer">
            <Spinner1 />
          </div>
        )}
        {
          isReady &&
          <iframe title="Privacy Notice" className="PN_iFrame" srcDoc={PN} />
        }
      </div>
    </div>
  );
}
