import * as React from "react"

function IcoHiddenEyes(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.673 12.712c-.05 2.783 2.045 5.077 4.682 5.128 2.679.051 4.927-2.123 4.975-4.81.049-2.781-2.05-5.077-4.685-5.125-2.69-.048-4.924 2.111-4.972 4.807zM24 13.057c-.24.36-.454.74-.722 1.074-1.446 1.807-3.136 3.316-5.179 4.379-1.394.725-2.864 1.187-4.424 1.34-3.03.295-5.772-.532-8.282-2.253-1.63-1.118-2.989-2.526-4.173-4.12-.292-.394-.293-.82-.002-1.211 1.366-1.84 2.963-3.42 4.915-4.598 1.613-.974 3.342-1.592 5.213-1.774 2.723-.265 5.238.386 7.578 1.809 1.935 1.176 3.515 2.753 4.868 4.583.088.119.14.267.208.402v.37z"
          fill="red"
        />
        <path
          d="M15.106 12.879c-.01 1.473-1.195 2.673-2.632 2.667-1.423-.005-2.583-1.22-2.574-2.696.009-1.464 1.202-2.661 2.644-2.654 1.413.007 2.571 1.22 2.562 2.683"
          fill="red"
        />
        <path
          d="M16.86 4.437l.697.307a1.095 1.095 0 01.557 1.452L11.441 21.01c-.247.548-.89.795-1.44.553l-.696-.307a1.095 1.095 0 01-.558-1.452L15.421 4.99c.247-.548.89-.795 1.44-.553z"
          fill="#FFF"
        />
        <path
          d="M17.765 4.594a.921.921 0 01.469 1.222l-6.81 15.117a.937.937 0 01-1.232.473.921.921 0 01-.469-1.222l6.81-15.117a.937.937 0 011.232-.473z"
          fill="red"
        />
      </g>
    </svg>
  )
}

export default IcoHiddenEyes
