const ScopeListTester = {

    callTester: async (scopeList, method, url) => {
        let match = false
        let bcAuth
        
        scopeList.map((item) => {

            if (item.method === method.toUpperCase()) {

                let reduxUrl = item.url.split("/")
                let myCallUrl = url.split("/")

                if (reduxUrl.length === myCallUrl.length) {
                    if ((reduxUrl[reduxUrl.length - 1] === myCallUrl[myCallUrl.length - 1]) || reduxUrl[reduxUrl.length - 1] === "*") { //LAST WORLD
                        if ((reduxUrl[reduxUrl.length - 2] === myCallUrl[myCallUrl.length - 2]) || reduxUrl[reduxUrl.length - 2] === "*") {// SECOND-LAST WORLD
                            if ((reduxUrl[reduxUrl.length - 3] === myCallUrl[myCallUrl.length - 3]) || reduxUrl[reduxUrl.length - 3] === "*") {// THIRD LAST WORLD

                                match = true
                                bcAuth=item
                                
                            }
                        }
                    }
                }
            }
        })

        if (!match){
            return match
        }else{
            return bcAuth
        }
    }
}

export default ScopeListTester;