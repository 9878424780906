import React, { Component } from "react";

//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
import UiOTP from "../../funcComponent/UiComponents/UiOTP";
//TRANSLATIONS
import i18n from "../../../I18next";
//UTILS
import Utilities from "../../../utils/utilities";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
import { PROPERTIES } from "../../../utils/properties";

export default class Activation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
    };
  }

  onPressEnter = () => {
    if (this.state.OTP.length === 6) {
      this.verifyOTP()
    }
  }

  verifyOTP = async () => {
    this.props.isLoading(true);
    let querystring = require("querystring");
    let verifyOTPCall = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/verifyotp",
      querystring.stringify({
        auth_req_id: this.props.auth_req_id,
        encryptedInfo: "" + this.state.OTP,
      })
    );
    if (verifyOTPCall.status === 200) {
      this.stateCall();
    }
    if (verifyOTPCall.status === 500) {
      this.props.isLoading(false);
      let title = ""
      let modalText = "";

      //Omega control
      if (verifyOTPCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = verifyOTPCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          verifyOTPCall.data.errorMessage === "500 null" ||
          verifyOTPCall.data?.errorMessage === undefined ||
          verifyOTPCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = verifyOTPCall.data.errorMessage;
        }
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse, title);
    }
    if (verifyOTPCall.status === 400) {
      this.props.isLoading(false);
      let isCorse = false;
      let title = ""
      let modalText = "";

      //Omega control
      if (verifyOTPCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = verifyOTPCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        if (verifyOTPCall.data.errorMessage === "Too many attempts. Please request a new code.\n" || verifyOTPCall.data.errorMessage === "Zu viele Versuche. Bitte fordern Sie einen neuen Code an. \n") {
          title = i18n.t("Error.TooManyAttempts");
        } else {
          if (verifyOTPCall.data.errorMessage === "The provided code is expired. Please request a new code.\n" || verifyOTPCall.data.errorMessage === "Der bereitgestellte Code ist abgelaufen. Bitte fordern Sie einen neuen Code an.\n") {
            title = i18n.t("Error.ExpiredCode");
          } else {
            title = i18n.t("Error.IncorrectEntries");
          }
        }
        modalText = verifyOTPCall.data.errorMessage
      }
      this.props.setError(modalText, isCorse, title);
    }
    if (verifyOTPCall.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.DifferentPw"), isCorse);
    }
  };
  stateCall = async () => {
    this.props.isLoading(true);
    let state = await ActivationServices.get(this.props.url + "/state");
    if (state.status === 200 && state.data.state === "_WAIT_FOR_EMAIL_LINK") {
      this.props.isLoading(false);
      this.props.setProgressive(state.data.state, state.data.params[2].value);
    }
    if (state.status === 500 || state.status === 400) {
      this.props.isLoading(false);
      let title = ""
      let modalText = "";

      //Omega control
      if (state.data?.errorMessage?.split("Ω").length === 2) {
        let message = state.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          state.data.errorMessage === "500 null" ||
          state.data?.errorMessage === undefined ||
          state.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = state.data.errorMessage;
        }
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse, title);
    }
    if (state.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.DifferentPw"), isCorse);
    }
  };

  render() {
    return (
      <div
        className="loginInputCardContainer"
        style={{ display: this.props.display && "none" }}
      >
        <UiCardTitle text={i18n.t("ActivationLogin.TitleText")} />
        <span
          style={{
            marginBottom: "3rem",
            textAlign: "left",
            fontFamily: "Sparkasse_Rg"
          }}
        >
          {i18n.language === "en"
            ? i18n.t("ActivationLogin.Description") +
            " " +
            Utilities.maskerNumber(this.props.userPhone)
            : //DE
            i18n.t("ActivationLogin.Description1") +
            " " +
            Utilities.maskerNumber(this.props.userPhone) +
            " " +
            i18n.t("ActivationLogin.Description2")}
        </span>

        {/* OTP COMPONENT TEST */}
        <UiOTP
          length={6}
          saveFunc={(val) => {
            this.setState({ OTP: val });
          }}
          onPressEnter={() => this.onPressEnter()}
        />

        <div style={{ margin: "2rem" }}>
          <button
            className="link_Button"
            onClick={() => {
              this.props.setProgressive(4, "invalidmail@invalidmail.com");
            }}
          >
            {i18n.t("ActivationLogin.Link")}
          </button>
        </div>
        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Confirm")}
              disabled={this.state.OTP.length < 6}
              press={() => {
                this.verifyOTP();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
