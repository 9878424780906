import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Import screen
import Home from './screens/Home'
import Login from './screens/Login'
import Error from './screens/Error'
import PrivacyNotice from "./screens/PrivacyNotice";
//REDUX
import ApplicationStore from './ApplicationStore';
import { Provider } from 'react-redux';
//Import css
import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider store={ApplicationStore}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/privacy-notice" component={PrivacyNotice} />
            <Route exact path="*" component={Error} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
