import React from 'react'

export default function UiCardTitle(props) {
    return (
        <div style={{fontSize:props.size?props.size:22,fontWeight:800, textAlign:'left',fontFamily:"Sparkasse_Bd"}}>
            <label>{props.text}</label>
            
        </div>
    )
}
