//Action
const SET_BCAUTH = 'web/bcauth/SET_BCAUTH'
const INIT_BCAUTH = 'web/bcauth/INIT_BCAUTH'

//Action Set_ScopeList Creators
export function setBcAuth(valueObj) {
    return {
        type: SET_BCAUTH,
        paylod: {
            bcauth: valueObj
        }
    }
}

//Action init_ScopeList Creators
export function initBcAuth() {
    return {
        type: INIT_BCAUTH,
        paylod: {}
    }
}


const INIT_STATE = {
    bcauth: null,
}

//Reducer
export default function bcAuthDuck(state = INIT_STATE, action) {
    var newState
    switch (action.type) {
        case SET_BCAUTH:
            newState = Object.assign({}, state)
            newState.bcauth = action.paylod.bcauth
            return newState;
        case INIT_BCAUTH:
            newState = Object.assign({}, state)
            newState.bcauth = null
            return newState

        default:
            return state;
    }
}