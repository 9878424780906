import * as React from "react"

function ImgOtp(props) {
    return (
        <svg
            width={111}
            height={87}
            viewBox="0 0 111 87"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g fill="red">
                    <path d="M0 39.71L28.292 58.4c-.247.177-.426.321-.62.444C18.668 64.563 9.66 70.28.65 75.994c-.2.126-.433.198-.65.295V39.71M42.108 29c12.089 0 24.177-.001 36.266.002 2.574 0 4.481 1.3 5.278 3.527.233.653.16 1.033-.488 1.458-9.123 5.995-18.222 12.028-27.328 18.051-4.404 2.914-8.815 5.818-13.206 8.75-.464.31-.764.298-1.221-.005A22184.493 22184.493 0 00.738 33.889c-.486-.32-.564-.61-.415-1.132.578-2.02 2.414-3.555 4.536-3.73.38-.03.765-.026 1.148-.026h36.101M83.852 82.574c-.661 2.878-2.548 4.422-5.386 4.423-24.294.004-48.588.004-72.882 0-2.502 0-4.417-1.332-5.188-3.537-.215-.615-.156-.986.475-1.383 6.88-4.327 13.734-8.695 20.595-13.052 3.65-2.317 7.307-4.624 10.943-6.964.524-.338.88-.394 1.432-.013 2.093 1.448 4.245 2.81 6.352 4.239 1.235.837 2.411.843 3.65.006 2.152-1.457 4.335-2.87 6.495-4.318.375-.252.652-.323 1.083-.048 10.664 6.791 21.34 13.564 32.012 20.341.137.087.262.191.419.306M84 39.725v36.659L55.714 58.433 84 39.725" />
                </g>
                <g transform="translate(59 4)" stroke="red">
                    <circle strokeWidth={4} fill="#FFF" cx={24} cy={24} r={26} />
                    <path
                        d="M21.56 33.56l15.91-15.908a1.5 1.5 0 000-2.122s0 0 0 0a1.5 1.5 0 00-2.122 0s0 0 0 0L20.5 30.379h0l-6.364-6.364a1.5 1.5 0 10-2.121 2.12l7.424 7.425h0a1.5 1.5 0 002.122 0z"
                        strokeWidth={1.5}
                        fill="red"
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ImgOtp
