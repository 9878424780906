import React, { useState } from "react";
//UICOMPONENT
import UiLabel from "./UiComponents/UiLabel";
//STYLE
import "./css/userCard.css";
//TRANSLATIONS
import i18n from "../../I18next";
import { withTranslation } from "react-i18next";
//Utilities
import Utilities from "../../utils/utilities";
//ANTD
import { FileProtectOutlined, RightCircleOutlined, RightCircleFilled } from "@ant-design/icons";
// REDUX
import { connect } from "react-redux";
import { setNav } from "../../redux/ducks/navigationDuck";

function UserCard(props) {
  const [hover, setHover] = useState(false);

  return (
    <div className="userCardView">
      <div className="triangle" />
      {/* triangle */}
      <div className="userCard">
        <div className="userCardContent">
          {/* PERSONAL DATA SECTION */}
          <div className="personalDataContainer">
            <div
              className="userCardTitle"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <label>{i18n.t("UserCard.PersonalData")}</label>
            </div>

            <div className="userCardItem">
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.FlexiGeldID")}
                  text={props.user.username}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.Gender")}
                  text={props.camsUser.customerGender}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.Title")}
                  text={props.user?.title?props.user?.title:""}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.FirstName")}
                  text={props.camsUser.customerName}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.Surname")}
                  text={props.camsUser.customerSurname}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.Street")}
                  text={props.address.addresslist[0].address.addressLine1}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.HouseNumber")}
                  text={props.address.addresslist[0].address.addressLine2}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.PostCode")}
                  text={props.address.addresslist[0].address.addressZip}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.TownCity")}
                  text={props.address.addresslist[0].address.addressCity}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.Country")}
                  text={props.address.addresslist[0].address.addressCountry}
                />
              </div>
            </div>
          </div>

          {/* CONTACT INFO SECTION */}
          <div className="contactInfoContainer">
            <div className="userCardTitle">
              <label>{i18n.t("UserCard.ContactInfo")}</label>
            </div>

            <div className="contactInfoItem">
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.MobileNumber")}
                  text={Utilities.maskerNumber(props.user.mobilePhone)}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.EmailAddress")}
                  text={Utilities.maskerMail(props.user.email)}
                />
              </div>
              <div className="uiLabelUserContainer">
                <UiLabel
                  title={i18n.t("UserCard.IbanBic")}
                  text={Utilities.maskerIban(props.iban)}
                />
              </div>
            </div>
          </div>

          {/* PRIVACY NOTICE SECTION */}
        </div>
      </div>
    </div>
  );
}
//Redux connection
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
});
export default connect(mapStateToProps)(withTranslation()(UserCard));
