import React, { useState } from "react";
import "./css/uiInputBox.css";
import "antd/dist/antd.css";
import { Input, Tooltip } from "antd";

import i18n from "../../../I18next";

//icons
import IcoInfo from "../../../asset/svg/IcoInfo";
import IcoHiddenEyes from "../../../asset/svg/IcoHiddenEyes"
import IcoVisibleEye from "../../../asset/svg/IcoVisibleEye"
import { PROPERTIES } from "../../../utils/properties";

const UiInputBox = (props) => {
  const [visibility, setVisibility] = useState(props.type);
  const [flexiGelId, setFlexiGelId]=useState("")
  const [flexiGelIdShort, setFlexiGelIdShort]=useState(false)

  const handleChange = (event) => {
    if (event.target.value[event.target.value.length - 1] !== " ") {
      setFlexiGelId(event.target.value)
      props.changeVal(event.target.value);
    }
    if(event.target.value.length===7||event.target.value.length===0){
      setFlexiGelIdShort(false)
    }
  };

  const changeVisibility = () => {
    setVisibility(visibility === "password" ? "text" : "password");
  };
  const onBlurFunc=()=>{
    if(flexiGelId.length<7 && flexiGelId.length!==0){
      setFlexiGelIdShort(true)
    }else{
      setFlexiGelIdShort(false)
    }
  }

  return (
    <div className="inputBoxContainer">
      <label htmlFor={props.title} className="titleStyle">{props.title}</label>

      {
        //Generic and password Input box type controll
        !props.flexiType && (
          <div className="inputExternalContainer">
            <Input
              id={props.title}
              className="inputBox password"
              type={visibility}
              onChange={handleChange}
              placeholder={props.placeholder}
              maxLength={props.isPIN && 6}
              pattern={props.numericKeyboard &&"[0-9]*"}
              inputmode={props.numericKeyboard &&"numeric"}
              value={props.value && props.value}
              onPressEnter={props?.onPressEnter? props.onPressEnter:null}
            />
            {props.type === "password" && (
              <div className="visibilityContainer" onClick={changeVisibility}>
                {visibility === "password" ? (
                  <IcoVisibleEye className='inputIcons' />
                ) : (
                  <IcoHiddenEyes className='inputIcons' />
                )}
              </div>
            )}
          </div>
        )
      }
      {
        //FlexiGelld ID input type controll
        props.flexiType && (
          <div className="flexigeldIdExternalContainer">
          <div className="flexigeldIdinputExternalContainer">
            <Input
              className="inputBox"
              addonBefore= {PROPERTIES.FLEXI_ID_PREFIX}
              placeholder={props.placeholder}
              onChange={handleChange}
              maxLength={9}
              pattern="[0-9]*"
              inputmode="numeric"
              value={props.value && props.value}
              onBlur={()=>{onBlurFunc()}}
              onPressEnter={props?.onPressEnter? props.onPressEnter:null}
            />
            <div className="visibilityContainer">
              <Tooltip
                overlayInnerStyle={{ borderRadius: '5px', color: 'black', fontSize: '0.7rem' }}
                title={i18n.t("WelcomeLogin.Tooltip")}
                color="rgb(235, 234, 234)"
                arrowPointAtCenter={true}
                trigger='click'
                placement='bottomRight'
              >

                <IcoInfo className='inputIcons' />

              </Tooltip>
            </div>
            </div>
            {// short flexiGeldId controll message
              flexiGelIdShort &&
              <div> <label className="flexiGeldControllLabel">{i18n.t("Error.ShortFlexiGeldId")}</label> </div>
            }
            
          </div>
        )
      }

    </div>
  );
};
export default UiInputBox;
