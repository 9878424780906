import React, { Component } from "react";

//Ui_COMPONENT
import UiButton from "../../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../../funcComponent/UiComponents/UiCardTitle";
import UiInputBox from "../../../funcComponent/UiComponents/UiInputBox";
//TRANSLATIONS
import i18n from "../../../../I18next";
//Antd
import Modal from "antd/lib/modal/Modal";
// SCOPLISTTESTER
import ScopeListTester from "../../../../utils/scopeListTester";
// BCAUTHORIZE
import bcAuthorize from "../../../../utils/bcAuthorize";
// REDUX
import { connect } from "react-redux";
// PROPERTIES
import { PROPERTIES } from "../../../../utils/properties";
// UTILITIES
import Utilities from "../../../../utils/utilities";
// AXIOS
import axios from "axios";

class EPIN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PIN1: "",
      PIN2: "",
      showModal: false,
      modalMessage: "",
      modalTitle:"",
      isCorse: false,
    };
  }

  handlePress = async () => {

    this.props.isLoading(true)

    let url =
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbacs/v1/" +
      this.props.cardID +
      "/setpin";
    let obj = { userPin: this.state.PIN1 };
    let method;
    if (this.props.cardVbv === "014") {
      method = "PUT";
    } else {
      method = "POST";
    }

    let test = await ScopeListTester.callTester(
      this.props.scopeListDuck.scopeList,
      method,
      url
    );

    if (!test) {
      //  CALL WITH NORMAL ACCESS TOKEN
      const { access_token } = Utilities.getToken();
      let ePinCall = await axios({
        method: method,
        url: url,
        data: obj,
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (ePinCall.status === 200) {
        this.props.isLoading(false)
        this.props.setProgressive(109);
      }


    } else {
      let BCACall = await bcAuthorize.call(test.scope);

      if (BCACall.status === 666 || BCACall.status === 667) {
        this.props.isLoading(false)
        let isCorse = true
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse)
      }
      if (BCACall.status === 400 || BCACall.status === 500) {
        this.props.isLoading(false)
        //"500 null" CONTROLL message
        let modalText = "";
        if (
          BCACall.data.errorMessage === "500 null" ||
          BCACall.data?.errorMessage === undefined ||
          BCACall.data?.errorMessage.substring(0,21)==="Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = BCACall.data.errorMessage;
        }
        let isCorse = false
        this.props.setError(modalText, isCorse)
      }

      if (BCACall.status === 200) {
        this.props.isLoading(false)
        let ePinData = {
          method: method,
          url: url,
          data: obj,
          scope: test.scope,
        };
        this.props.setProgressive(
          108,
          BCACall.data.acr,
          BCACall.data.auth_req_id,
          BCACall.data.phoneNumber,
          ePinData
        );
      }
    }
  };

  render() {
    return (
      <div className="loginInputCardContainer" style={{ display: this.props.display && 'none' }}>
        <UiCardTitle text={i18n.t("Login4.TitleText")} />
        <p
          style={{
            marginBottom: "5rem",
            textAlign: "left",
          }}
        >
          {i18n.t("Login4.Description")}
        </p>
        <div className="loginInputRaw">
          <UiInputBox
            title={i18n.t("Placeholder.EPIN")}
            type="password"
            placeholder={i18n.t("Placeholder.EPIN")}
            changeVal={(event) => {
              if (!isNaN(event) && event[event.length - 1] !== ".") {
                this.setState({ PIN1: event });
              }
            }}
            value={this.state.PIN1}
            isPIN={true}
            numericKeyboard={true}
            onPressEnter={() => {
              if (this.state.PIN1 !== "" && this.state.PIN2 !== ""){
                if ((this.state.PIN1 === this.state.PIN2) && this.state.PIN1.length === 6) {
                  this.handlePress();
                } else {
                  if(this.state.PIN1 !== this.state.PIN2){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.DifferentEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                  if(this.state.PIN1.length !== 6 || this.state.PIN2.length !== 6){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.ShortEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                }
              }    
            }}
          />
        </div>
        <div className="loginInputRaw">
          <UiInputBox
            title={i18n.t("Placeholder.RepeatEPIN")}
            type="password"
            placeholder={i18n.t("Placeholder.RepeatEPIN")}
            changeVal={(event) => {
              if (!isNaN(event) && event[event.length - 1] !== ".") {
                this.setState({ PIN2: event });
              }
            }}
            isPIN={true}
            value={this.state.PIN2}
            numericKeyboard={true}
            onPressEnter={() => {
              if (this.state.PIN1 !== "" && this.state.PIN2 !== ""){
                if ((this.state.PIN1 === this.state.PIN2) && this.state.PIN1.length === 6) {
                  this.handlePress();
                } else {
                  if(this.state.PIN1 !== this.state.PIN2){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.DifferentEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                  if(this.state.PIN1.length !== 6 || this.state.PIN2.length !== 6){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.ShortEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                }
              }    
            }}
          />
        </div>
        <div style={{ margin: "3rem" }} />
        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Confirm")}
              disabled={
                this.state.PIN1 === "" ||
                  this.state.PIN2 === "" 
                  // this.state.PIN1.length !== 6 ||
                  // this.state.PIN2.length !== 6
                  ? true
                  : false
              }
              press={() => {
                if ((this.state.PIN1 === this.state.PIN2) && this.state.PIN1.length === 6) {
                  this.handlePress();
                } else {
                  if(this.state.PIN1 !== this.state.PIN2){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.DifferentEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                  if(this.state.PIN1.length !== 6 || this.state.PIN2.length !== 6){
                    this.setState({
                      showModal: true,
                      modalMessage: i18n.t("Error.ShortEPIN"),
                      modalTitle:i18n.t("Error.IncorrectEntries"),
                    });
                  }
                  
                }
              }}
            />
          </div>
        </div>
        <Modal
          visible={this.state.showModal}
          centered={true}
          title={this.state.modalTitle? this.state.modalTitle : "Error"}
          footer={[
            <button
              className="ModalButton"
              style={{ marginRight: "1rem" }}
              type={"primary"}
              onClick={() => {
                if (this.state.isCorse === true) {
                  this.setState({
                    showModal: false,
                    modalMessage: "",
                    modalTitle:"",
                    isCorse: false,
                  });
                  this.props.setProgressive(0);
                }
                this.setState({ showModal: false, modalMessage: "" });
              }}
            >
              {i18n.t("Buttons.Close")}
            </button>,
          ]}
        >
          <p>{this.state.modalMessage}</p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scopeListDuck: state.scopeListDuck,
});

export default connect(mapStateToProps)(EPIN);
