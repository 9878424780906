import React from "react";
import i18n from "../../../I18next";
import { useHistory } from "react-router-dom";

//STYLE
import "./css/uiFooter.css";

export default function UiFooter(props) {

    const history = useHistory();

    return ( 
      <div className="footerContainer" >
          <p className="footerText">
            {i18n.t("Footer.Line1")}
          </p>
          <p className="footerText">
            {i18n.t("Footer.Line2.1")}
            <a className="footerMail" href="mailto:kartenservice@s-kreditpartner.de">{i18n.t("Footer.Email")}</a> 
            { i18n.t("Footer.Line2.2")} | 
            <a className="footerPrivacy" onClick={() => history.push("/privacy-notice")}>{i18n.t("Footer.PrivacyNotice")}</a>
          </p>
      </div>
    );
}
