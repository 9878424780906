import * as React from "react"

function Ico_visible_eye(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"ico_visible_eye"}</title>
      <g fill="red" fillRule="evenodd">
        <path d="M7.673 12.84c-.05 2.769 2.045 5.052 4.682 5.102 2.679.051 4.927-2.111 4.975-4.784.049-2.768-2.05-5.052-4.685-5.1-2.69-.048-4.924 2.1-4.972 4.783zM24 13.185c-.24.357-.454.735-.722 1.068-1.446 1.798-3.136 3.299-5.179 4.356-1.394.722-2.864 1.182-4.424 1.333-3.03.294-5.772-.528-8.282-2.24-1.63-1.112-2.989-2.514-4.173-4.1-.292-.391-.293-.815-.002-1.204 1.366-1.83 2.963-3.403 4.915-4.575 1.613-.968 3.342-1.584 5.213-1.764 2.723-.264 5.238.384 7.578 1.8 1.935 1.17 3.515 2.738 4.868 4.558.088.12.14.267.208.4v.368z" />
        <path d="M15.106 13.007c-.01 1.465-1.195 2.66-2.632 2.653-1.423-.005-2.583-1.214-2.574-2.682.009-1.456 1.202-2.648 2.644-2.64 1.413.007 2.571 1.214 2.562 2.669" />
      </g>
    </svg>
  )
}

export default Ico_visible_eye
