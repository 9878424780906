import * as React from "react"

function TransactionOut(props) {
    return (
        <svg
            width={37}
            height={37}
            viewBox="0 0 37 37"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                <circle stroke="red" fill="#FFF" cx={17.5} cy={17.5} r={17.5} />
                <g transform="translate(5 9)">
                    <g fill="red">
                        <path d="M5.457 11.141c.269 0 .537.006.805 0a1.037 1.037 0 001.008-1.036c.005-.562-.432-1.039-.991-1.052a34.724 34.724 0 00-1.633-.001c-.563.013-.992.477-.99 1.048.001.57.434 1.025.997 1.04.268.007.536.001.804.001M25 6.807v7.272c-.086.041-.057.131-.073.197-.387 1.637-1.68 2.716-3.32 2.718-6.072.008-12.142.008-18.213 0-1.64-.002-2.935-1.082-3.322-2.718-.015-.065.013-.155-.072-.197V6.757c.185-.32.46-.407.817-.407 7.804.008 15.608.008 23.413.001.364 0 .624.109.77.456M0 2.921c.088-.05.062-.148.079-.221C.45 1.103 1.757.01 3.369.007c6.087-.01 12.174-.01 18.261 0 1.675.002 3.028 1.192 3.321 2.863.007.04-.003.085.049.1v.897c-.202.335-.503.402-.871.401-7.76-.008-15.522-.008-23.282 0-.364 0-.65-.078-.847-.4V2.92z" />
                    </g>
                    <circle stroke="red" fill="#FFF" cx={20} cy={13} r={6} />
                    <path
                        d="M19.81 10.072a.29.29 0 01.382 0l1.728 1.544a.224.224 0 010 .343.294.294 0 01-.385 0l-1.262-1.13v4.928c0 .135-.121.243-.272.243-.151 0-.272-.108-.272-.243V10.83l-1.264 1.13a.294.294 0 01-.385 0 .224.224 0 010-.343l1.73-1.544z"
                        stroke="red"
                        strokeWidth={0.5}
                        fill="red"
                    />
                </g>
            </g>
        </svg>
    )
}

export default TransactionOut
