import React from "react";

//STYLE
import './css/successChangePin.css'
//TRANSLATIONS
import i18n from '../../I18next';
//UICOMPONENT
import UiButton from './UiComponents/UiButton'
//Ant_D COMPONENT
import 'antd/dist/antd.css';
import { CheckCircleOutlined } from '@ant-design/icons';


export default function SuccessChangePin(props) {
  return (
    <div className="changePin_Princ_Container">
      <div className="changePin_headerRow">
        <div className="changePin_headerRow_Title">
          <label>{i18n.t("ChangeCardPIN.Title")}</label>
        </div>

        <div className="changePin_headerRow_History">
          <label
            className="changePin_Navigability"
            onClick={props.goBackFunc}
          >
            {i18n.t("CardSetting.CardSetting")}
          </label>
          <label style={{ color: "red", fontWeight: "700" }}>{" > "}</label>
          <label className="changePin_Navigability_right">
            {i18n.t("CardSetting.ChangeCardPin")}
          </label>
        </div>
      </div>
      <div className="changePin_Card">
        <div className="changePin_Card_ItemContainer">
          <div className="changePin_Card_Success_TitleRow">
            <label>{i18n.t("CardSetting.PINChangeSuccessfully")}</label>
          </div>

          <div className="changePin_Card_Success_SubTitleRow">
            <label>{i18n.t("CardSetting.SuccessChangePinDescription")}</label>
          </div>

          <CheckCircleOutlined
            style={{ color: "red", fontSize: "10rem", marginBottom: "-1rem" }}
          />

          <div className="changePin_Card_ButtonContainer">
            <UiButton
              title={i18n.t("Buttons.Close")}
              disabled={false}
              press={props.goBackFunc}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
