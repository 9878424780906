import * as React from "react"

function MenuProfileOffMobile(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M12.492 23.535v-.003c2.082 0 4.165.017 6.247-.006 1.743-.019 2.807-1.117 2.79-2.844a18.844 18.844 0 00-.106-1.948c-.133-1.159-.376-2.293-.945-3.334-.586-1.071-1.432-1.764-2.703-1.837a.639.639 0 00-.343.077c-.373.22-.731.465-1.1.692a7.305 7.305 0 01-3.3 1.095c-1.625.125-3.11-.314-4.479-1.174-.332-.208-.66-.423-.996-.627a.479.479 0 00-.25-.071 3.125 3.125 0 00-1.43.393c-.833.465-1.296 1.23-1.637 2.069-.651 1.597-.795 3.281-.752 4.981.024.906.421 1.647 1.23 2.126.545.322 1.15.411 1.772.411h6.002zM5.883 25c-.294-.062-.593-.106-.88-.188-1.788-.513-2.908-1.877-2.987-3.738-.081-1.932.132-3.837.872-5.646.478-1.168 1.172-2.177 2.334-2.795a4.628 4.628 0 012.291-.546c.254.005.521.133.753.258.373.201.72.451 1.081.674 2.107 1.29 4.215 1.29 6.32-.005.293-.18.591-.353.866-.558.504-.374 1.058-.421 1.657-.334 1.634.238 2.788 1.127 3.566 2.543.695 1.265 1.007 2.64 1.146 4.055.072.727.105 1.46.097 2.191-.027 2.181-1.535 3.804-3.72 4.043-.055.006-.107.03-.16.046H5.882z" />
        <path d="M12.356 10.58c2.557-.018 4.596-2.05 4.583-4.569a4.555 4.555 0 00-4.628-4.545c-2.557.023-4.582 2.063-4.556 4.59a4.562 4.562 0 004.601 4.523zM12.919 0c.327.065.657.121.982.197 2.231.519 4.138 2.632 4.438 4.892.197 1.488-.02 2.895-.867 4.153-1.126 1.671-2.682 2.644-4.74 2.782-2.703.182-4.926-1.396-5.935-3.538-.948-2.011-.565-4.69.921-6.358C8.63 1.104 9.73.399 11.09.124c.231-.046.465-.083.697-.124h1.132z" />
      </g>
    </svg>
  )
}

export default MenuProfileOffMobile
