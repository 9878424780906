import React, { useState, useEffect } from "react";
//CSS
import "./css/uiOTP.css";
//TRANSLATIONS
import i18n from "../../../I18next";

export default function UiOTP(props) {
  const [otp, setOtp] = useState(new Array(props.length).fill(""));
  const [showOTP, setShowOTP] = useState(false);

  const handleChange = (element, index) => {
    let arr = otp;
    if (isNaN(element.value)) return false;

    if (
      index === 0 &&
      element.value.length !== 0
    ) {
      let pasteOTP = element.value
      if (element.value.length > 6) {
        pasteOTP = element.value.substring(0, 6)
      }
      for (let i = 0; i < pasteOTP.length; i++) {
        arr[i] = pasteOTP[i];
      }
      setOtp([...arr]);
    } else if (element.value.length > 7) {
      return
    } else {
      arr[index] = element.value;
      setOtp([...arr]);
    }

    props.saveFunc(otp.join(""));
  };

  const handleKeyPress=(e)=>{
    if(e.key==='Enter'){
      props?.onPressEnter()
    }
  }

  useEffect(() => {
    if (showOTP) {
      for (let i = 0; i < otp.length; i++) {
        if (otp[i] === "") {
          return document.getElementById("input_" + i).focus();
        }
      }
      //Decommented for auto remove focus on last otp-inputbox when user insert 6 digits!!!
      //return (
        //document.getElementById("input_" + 5).focus(),
        // document.getElementById("input_" + 5).blur() 
      //);
    }
  }, [otp, showOTP]);

  return (
    <>
      {!showOTP && (
        <button
          className="otpContainer"
          onClick={() => {
            setShowOTP(true);
          }}
        >
          {i18n.t("Placeholder.OTP")}
        </button>
      )}

      {showOTP && (
        <div className="otpContainer">
          {otp &&
            otp.map((data, index) => {
              if (index === 0) {
                return (
                  <input
                    className="otp-field"
                    type="text"
                    name="otp"
                    key={index}
                    id={"input_" + index}
                    value={data}
                    pattern="[0-9]*"
                    inputmode="numeric"
                    autoFocus
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.focus();
                      } else {
                        handleChange(e.target, index);
                      }
                    }}
                    onChange={(e) => {
                      handleChange(e.target, index);
                    }}
                    onKeyPress={(e)=>{handleKeyPress(e)}}
                  />
                );
              } else {
                return (
                  <input
                    className="otp-field"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    id={"input_" + index}
                    value={data}
                    pattern="[0-9]*"
                    inputmode="numeric"
                    onSelect={(e) => {
                      if (
                        e.target.previousSibling.value === "" &&
                        e.target.value === ""
                      ) {
                        e.target.previousSibling.focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && e.target.value === "") {
                        e.target.previousSibling.focus();
                      }
                    }}
                    onChange={(e) => {
                      handleChange(e.target, index);
                    }}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    onKeyPress={(e)=>{handleKeyPress(e)}}
                  />
                );
              }
            })}
        </div>
      )}
    </>
  );
}
