import React, { Component } from "react";

//Ui_COMPONENT
import UiButton from "../../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../../funcComponent/UiComponents/UiCardTitle";
//TRANSLATIONS
import i18n from "../../../../I18next";
//Ant_D COMPONENT
import "antd/dist/antd.css";
import { CheckCircleOutlined } from "@ant-design/icons";

export default class SuccessEPIN extends Component {

  render() {
    return (
      <div className="loginInputCardContainer" style={{ display: this.props.display && 'none' }}>
        <UiCardTitle text={i18n.t("Login5.TitleText")} />
        <p
          style={{
            marginBottom: "1.3rem",
            textAlign: "left",
          }}
        >
          {i18n.t("Login5.Description")}
        </p>
        <CheckCircleOutlined
          style={{ color: "red", fontSize: 92, margin: "3rem 0" }}
        />
        <p
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
            textAlign: "left",
          }}
        >
          {i18n.t("Login5.FooterDescription")}          
          {/* <button

              className="link_Button"
              onClick={() => {
                this.props.setProgressive(110);
              }}
            >
              {i18n.t("Login5.Link")}
            </button> */}
        </p>

        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Home")}
              press={() => {
                this.props.history.push("/home", { data: this.props.cardInfoCall});
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
