import * as React from "react"

function CardVisa(props) {
  return (
    <svg
      width={58}
      height={37}
      viewBox="0 0 58 37"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="silver" fillRule="evenodd">
        <path
          d="M29.962 8.891c-.032 2.567 2.245 4 3.96 4.85 1.762.875 2.354 1.435 2.347 2.216-.013 1.196-1.405 1.724-2.709 1.745-2.273.036-3.595-.626-4.646-1.126l-.819 3.905c1.055.495 3.007.927 5.032.946 4.752 0 7.861-2.39 7.878-6.097.018-4.703-6.385-4.964-6.341-7.066.015-.638.612-1.318 1.92-1.49.647-.088 2.435-.155 4.461.796l.796-3.778C40.75 3.387 39.35 3 37.606 3c-4.473 0-7.619 2.423-7.644 5.891m19.521-5.565c-.867 0-1.599.515-1.925 1.307l-6.789 16.516h4.75l.944-2.66h5.803l.549 2.66H57L53.348 3.326h-3.865m.665 4.814l1.37 6.693h-3.753l2.383-6.693M24.204 3.326l-3.743 17.823h4.525l3.742-17.823h-4.524m-6.694 0L12.8 15.457 10.894 5.142c-.223-1.152-1.106-1.816-2.086-1.816h-7.7L1 3.843c1.58.35 3.377.913 4.465 1.516.666.369.856.691 1.074 1.567l3.609 14.223h4.782l7.332-17.823H17.51"
          fillRule="nonzero"
        />
        <text
          fontFamily="Helvetica"
          fontSize={9.1}
          letterSpacing={-0.455}
          transform="translate(1 3)"
        >
          <tspan x={32.558} y={28.91}>
            {"Credit"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default CardVisa
