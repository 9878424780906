import axios from "axios/index";
import Utilities from "../utils/utilities";
import { PROPERTIES } from "../utils/properties";
import i18n from "../I18next";
const axiosInstance = axios.create();

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    //Get token and refresh token from sessionStorage
    const { access_token } = Utilities.getToken();
    config.headers = {
      Accept: "*/*",
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      Language: "" + i18n.language.toUpperCase(),
      "X-Origin":"web-portal",
      "OriginApp": "SKP"
    };
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    Utilities.updateTTL()
    return response;
  },
  async (error) => {
    Utilities.updateTTL()
    //Test Cors error
    if (typeof error.response === "undefined") {
      let obj = {
        status: 667,
        data: {
          errorMessage: i18n.t("Error.CorsRedirect")
        },
      };
      return obj;
    } else {
      let originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        let newToken;
        let refresh_token;
        let refCall = await refreshAccessToken();
        if (refCall?.status === 666) {

          return refCall;
        } else {
          newToken = refCall.data.access_token;
          refresh_token = refCall.data.refresh_token;
        }
        // const { refresh_token } = Utilities.getToken();
        let sessionStorageObj = {
          access_token: newToken,
          refresh_token: refresh_token,
        };
        window.sessionStorage.setItem(
          "logged",
          JSON.stringify(sessionStorageObj)
        );
        originalRequest.headers.Authorization = "Bearer " + newToken;
        return axiosInstance(originalRequest);
      } else {
        return error.response;
      }
    }
  }
);

const refreshAccessToken = async () => {
  const { refresh_token } = Utilities.getToken();
  let timeUp = false;
  var querystring = require("querystring");
  let data;
  data = await axios({
    method: "post",
    url: "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
    // data: bodyFormData,
    data: querystring.stringify({
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    }),
    headers: {
      Accept: "*/*",
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  })
    .catch((error) => {
      if (error?.response.status === 401) {
        timeUp = true;
        return error.response
      }
    })
    .then((response) => {
      if (response?.status === 200) return response;
    });
  if (timeUp === true) {
    let obj = {
      status: 666,
      data: {
        errorMessage: i18n.t("Error.TimeIsUpRedirect")
      },
    };
    return obj;
  } else {
    return data;
  }
};

// utilizzo connect e map
export default axiosInstance;
