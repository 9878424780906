import * as React from "react"

function StatementIco(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"statements"}</title>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle stroke="red" fill="#FFF" cx={17.5} cy={17.5} r={17.5} />
        <g fill="red">
          <path d="M14.549 21.056h-1.347c-.371.002-.63.245-.632.588-.002.344.257.593.626.594h2.694c.38 0 .65-.254.645-.598-.004-.338-.27-.582-.64-.583-.448-.002-.897 0-1.346 0m2.838-1.945c1.391 0 2.782 0 4.172.002.262 0 .464-.096.58-.337.2-.412-.095-.846-.575-.846h-5.5c-.954 0-1.909.001-2.864-.001-.254-.001-.45.096-.567.327-.206.409.087.854.563.855 1.397.002 2.794 0 4.191 0m-.007-3.125l4.21-.001a.59.59 0 00.17-1.16c-.084-.022-.177-.02-.266-.02l-7.902-.001c-.154 0-.308-.006-.461.002a.558.558 0 00-.519.37c-.162.399.128.808.576.809 1.397.002 2.795 0 4.192 0M23.119 27H11.881c-.023-.01-.044-.027-.068-.032-1.15-.231-1.813-1.05-1.813-2.24V9.241C10 7.928 10.913 7 12.204 7c2.193-.002 4.386 0 6.579 0h.214v.24c0 1.373.022 2.747-.008 4.12-.019.866.634 1.527 1.495 1.509 1.43-.032 2.86-.009 4.29-.009H25v11.906c0 .065-.001.13-.004.195a2.134 2.134 0 01-1.583 1.96l-.294.078" />
          <path d="M20.16 7.222c.196.154.355.261.493.393a862.423 862.423 0 013.647 3.503c.159.154.29.34.468.552-.11.005-.158.009-.206.009h-4.098c-.254 0-.303-.05-.303-.307v-4.15z" />
        </g>
      </g>
    </svg>
  )
}

export default StatementIco