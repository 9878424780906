import * as React from "react"

function IcoCloseWhite(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path d="M3.875 1.958l19.167 19.167a1.355 1.355 0 11-1.917 1.917L1.958 3.875a1.355 1.355 0 111.917-1.917z" />
        <path d="M21.125 1.958L1.958 21.125a1.355 1.355 0 101.917 1.917L23.042 3.875a1.355 1.355 0 10-1.917-1.917z" />
      </g>
    </svg>
  )
}

export default IcoCloseWhite
