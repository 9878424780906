import * as React from "react"

function MenuTransactionsOff(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#666" fillRule="evenodd">
        <path d="M14.35 16.867l4.293 4.556 4.28-4.545c-.056-.002-.142-.01-.228-.01l-1.543-.001c-.594-.001-.77-.19-.77-.826V3.42h-3.464v12.657c0 .592-.186.789-.748.79h-1.82zM18.488 23c-.145-.126-.301-.24-.433-.38-1.801-1.906-3.599-3.816-5.398-5.725-.05-.055-.101-.109-.153-.163-.192-.201-.257-.441-.154-.707.103-.269.311-.38.582-.38.839.003 1.677.001 2.516.001h.32v-.367c0-3.886.015-7.773-.01-11.66-.007-.98.587-1.493 1.378-1.468 1.028.034 2.057.007 3.085.009.862.001 1.312.482 1.312 1.403v12.083h.321c.839 0 1.678.002 2.517 0 .27-.001.478.111.581.38.102.265.037.504-.154.707-1.902 2.017-3.802 4.035-5.705 6.05-.08.085-.186.145-.28.217h-.325zM8.08 21.599v-.376V9.048c0-.087-.001-.173.001-.259.013-.404.228-.64.61-.646.56-.009 1.119-.003 1.678-.003h.289L6.356 3.586 2.051 8.14h1.797c.594 0 .768.188.768.826v12.633h3.465zM9.23 9.36v.388c0 3.877-.015 7.754.012 11.631.006.993-.588 1.504-1.399 1.476-1.018-.035-2.038-.007-3.057-.009-.865 0-1.32-.48-1.32-1.396V9.36H3.15c-.83 0-1.66-.003-2.49.002-.278.002-.5-.095-.611-.376-.11-.277-.032-.519.167-.73 1.89-2.001 3.776-4.006 5.666-6.008.309-.327.617-.331.922-.008 1.897 2.008 3.79 4.02 5.685 6.029.192.203.263.439.163.705-.107.282-.324.39-.605.388-.83-.004-1.66-.002-2.49-.002h-.327z" />
      </g>
    </svg>
  )
}

export default MenuTransactionsOff
