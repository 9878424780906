import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
//TRANSLATIONS
import i18n from "../../../I18next";
//Ant_D COMPONENT
import "antd/dist/antd.css";
//icons
import ImgOtp from "../../../asset/svg/ImgOtp";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
import Utilities from "../../../utils/utilities";

export default class CorrectOTP extends Component {
  verifyEmail = async () => {
    this.props.isLoading(true);
    let state = await ActivationServices.get(this.props.url + "/state");
    if (state.status === 200) {
      this.props.isLoading(false);
      this.props.setProgressive(state.data.state);
    }
    if (state.status === 400 || state.status === 500) {
      this.props.isLoading(false);
      let title = ""
      let modalText = "";

      //Omega control
      if (state.data?.errorMessage?.split("Ω").length === 2) {
        let message = state.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          state.data.errorMessage === "500 null" ||
          state.data?.errorMessage === undefined ||
          state.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = state.data.errorMessage;
          if (
            state.data.errorMessage ===
            "The link is expired or invalid. Please request a new link." ||
            state.data.errorMessage ===
            "Der Link ist abgelaufen oder ungültig. Bitte fordern Sie einen neuen Link an."
          ) {
            title = i18n.t("Error.ExpiredLink");
          } else {
            title = i18n.t("Error.VerificationOfYourData");
          }
        }
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse, title);
    }
    if (state.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
    }
  };
  render() {
    return (
      <div
        className="loginInputCardContainer"
        style={{ display: this.props.display && "none" }}
      >
        <UiCardTitle text={i18n.t("Login3.TitleText")} />

        <p>
          {i18n.language === "en"
            ? i18n.t("Login3.Description1") +
            " " +
            Utilities.maskerMail(this.props.userMail) +
            ". "
            : i18n.t("Login3.Description1") +
            " " +
            Utilities.maskerMail(this.props.userMail) +
            i18n.t("Login3.Description11")}
        </p>

        <p>{i18n.t("Login3.Description2")}</p>

        <ImgOtp style={{ margin: "3rem 0", alignSelf: "center" }} />

        <div style={{ marginBottom: "2rem" }}>
          <button
            className="link_Button"
            onClick={() => {
              this.props.setProgressive(6);
            }}
          >
            {i18n.t("Login3.Link")}
          </button>
        </div>

        <div className="loginButtonContainer">
          <div className="loginButtonItem">
            <UiButton
              title={i18n.t("Buttons.Continue")}
              press={() => {
                this.verifyEmail();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
