import React, { useState } from "react";
//STYLE
import "./css/navbar.css";
//ANTD
import "antd/dist/antd.css";
import { Modal } from "antd";
//TRANSLATIONS
import i18n from "../../I18next";
//IMAGE
import LogoHome from "../../asset/svg/LogoHome";
//icons
import MenuProfileOff from "../../asset/svg/MenuProfileOffMobile";
import MenuLogoutOff from "../../asset/svg/MenuLogoutOffMobile";
import Menu from "../../asset/svg/Menu";
import MenuClose from "../../asset/svg/MenuClose";
import LangButton from "./UiComponents/UiLangButton";
// REDUX
import { connect } from "react-redux";
import { setNav } from "../../redux/ducks/navigationDuck";

const Navbar=(props)=> {

  const [showModal, setShowModal] = useState(false);
  const [lang, setLang] = useState(i18n.language)

  // const { callback } = props

  // useEffect(() => {
  //   console.log(callback)
  //   callback(lang)
  // }, [lang])

  const pressLogo=()=>{
    props.dispatch(setNav(0));
    props.setNavBack()
  }

  return (
    <div className="navbarContainer">
      {props.size < 768 && !props.sideBarOpen ? (
        <Menu onClick={() => props.mobileSideBar()} />
      ) : (
        props.size < 768 &&
        props.sideBarOpen && <MenuClose onClick={() => props.mobileSideBar()} />
      )}

      {/* TEMPORARY IMAGE */}
      <div className="navbarLogoContainer" onClick={()=>pressLogo()}>
        <LogoHome/>
      </div>

      {props.size >= 768 && (
        <div className="navbarButtonContainer">
          <button
            className="navbarUserContainer"
            onClick={() => {
              props.userFunc();
            }}
            tabIndex="1"
          >
            <MenuProfileOff />
            <span className="navbarButtonText">{props.user}</span>
          </button>

          <button
            className="navbarLogoutContainer"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <MenuLogoutOff />
            <span className="navbarButtonText">{i18n.t("NavBar.Logout")}</span>
          </button>

          <LangButton
            callback={(value) => {
              setLang(value)
              props.callback(lang)
            }}
          />
        </div>
      )}

      {props.size < 768 &&
        <LangButton
          callback={(value) => setLang(value)}
        />
      }

      <Modal
        visible={showModal}
        centered={true}
        title={i18n.t("NavBar.Logout")}
        maskStyle={{backgroundColor:"#a7a7a79c"}}
        footer={[
          <button
            key={"Logout_No"}
            className="ModalButton"
            type={"primary"}
            onClick={() => {
              setShowModal(false);
            }}
          >
            {i18n.t("Buttons.NO")}
          </button>,
          <button
            key={"Logout_Yes"}
            className="ModalButton"
            type={"primary"}
            onClick={() => {
              props.logoutFunc();
            }}
          >
            {i18n.t("Buttons.YES")}
          </button>,
        ]}
      >
        <p>{i18n.t("NavBar.LogoutMessage")}</p>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
});

export default connect(mapStateToProps)(Navbar);