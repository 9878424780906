//Action
const SET_NAV = 'web/token/SET_NAV'
const INIT_NAV = 'web/token/INIT_NAV'
const SET_CARD_FLIP = 'web/token/SET_CARD_FLIP'
const SET_CHANGEPIN_VIEW = 'web/token/SET_CHANGEPIN_VIEW'

//Action Set_Navigation Creators
export function setNav(valueObj) {
    return {
        type: SET_NAV,
        paylod: {
            navigationPointer: valueObj
        }
    }
}

//Action Set_CardFlip for back browser controll
export function setCardFlip(value){
    return {
        type: SET_CARD_FLIP,
        paylod:{
            cardFlip:value
        }
    }
}
//Action Set_ChangePinView for back to cardSetting controll
export function setChangePinView(value){
    return {
        type: SET_CHANGEPIN_VIEW,
        paylod:{
            changePinView:value
        }
    }
}

//Action init_Navigator Creators
export function initNav() {
    return {
        type: INIT_NAV,
        paylod: {}
    }
}

const INIT_STATE = {
    navigationPointer: 0,
    cardFlip:false,
    changePinView:false
}

//Reducer
export default function navigationDuck(state = INIT_STATE, action) {
    var newState
    switch (action.type) {
        case SET_NAV:
            newState = Object.assign({}, state)
            newState.navigationPointer = action.paylod.navigationPointer
            return newState;
        case INIT_NAV:
            newState = Object.assign({}, state)
            newState.navigationPointer = {}
            return newState;
        case SET_CARD_FLIP:
            newState = Object.assign({}, state)
            newState.cardFlip = action.paylod.cardFlip
            return newState;
        case SET_CHANGEPIN_VIEW:
            newState = Object.assign({}, state)
            newState.changePinView = action.paylod.changePinView
            return newState;
        default:
            return state;
    }
}