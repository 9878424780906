import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
//TRANSLATIONS
import i18n from "../../../I18next";
//icons
import IcoPhone from "../../../asset/svg/IcoPhone";
import SendNewEmailIco from "../../../asset/svg/SendNewEmailIco";
//Temporary icon
import { LeftOutlined } from '@ant-design/icons';
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";

export default class SendNewEmail extends Component {

  sendNewCall = async () => {
    this.props.isLoading(true);
    let newmailCall = await ActivationServices.get(
      this.props.url + "/sendnewemaillink"
    );

    if (newmailCall.status === 200) {
      this.props.isLoading(false);
      this.props.setProgressive(newmailCall.data.state);
    }
    if (newmailCall.status === 400 || newmailCall.status === 500) {
      this.props.isLoading(false);
      let title = ""
      let modalText = "";

      //Omega control
      if (newmailCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = newmailCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        //"500 null" CONTROLL message
        if (
          newmailCall.data.errorMessage === "500 null" ||
          newmailCall.data?.errorMessage === undefined ||
          newmailCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = newmailCall.data.errorMessage;
          title = i18n.t("Error.TooManyAttempts")
        }
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse, title);
    }

    if (newmailCall.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
    }
  };

  render() {
    return (
      <>
        <div
          className="goBackIcon"
          style={{ display: this.props.display && "none" }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.goBack()}
          />
        </div>
        <div
          className="loginInputCardContainer"
          style={{ display: this.props.display && "none" }}
        >
          <UiCardTitle text={i18n.t("NewEmail.TitleText")} />
          <p style={{ marginBottom: "1.3rem" }}>
            {i18n.t("NewEmail.Description")}
          </p>

          <div className="loginButtonContainer">
            <div className="loginButtonItem">
              <UiButton
                title={i18n.t("Buttons.NewEmail")}
                press={() => {
                  this.sendNewCall();
                }}
              />
            </div>
          </div>
          <div className="loginImgContainer">
            <SendNewEmailIco />
          </div>
          <p
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              textAlign: "left",
            }}
          >
            {i18n.t("NewEmail.FooterDescription")}
          </p>
          <div className="newCodePhoneRaw">
            <IcoPhone />
            <p style={{ margin: "1rem", textAlign: "left" }}>
              {i18n.t("NewEmail.Phone") + " +49 30 620 080 8008"}
            </p>
          </div>
        </div>
      </>
    );
  }
}
