import * as React from "react"

function IcoPhone(props) {
  return (
    <svg
      width={31}
      height={31}
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="red" fillRule="nonzero" stroke="red" strokeWidth={0.5}>
        <path d="M29.188 23.33l-5.859-3.907a1.71 1.71 0 00-2.29.372l-1.707 2.195a.73.73 0 01-.935.196l-.325-.179c-1.076-.586-2.415-1.317-5.113-4.015-2.698-2.7-3.43-4.04-4.016-5.114l-.178-.324a.73.73 0 01.193-.94L11.15 9.91c.702-.55.864-1.547.373-2.29l-3.907-5.86a1.701 1.701 0 00-2.294-.516l-2.45 1.472A3.456 3.456 0 001.296 4.76c-.882 3.215-.218 8.763 7.954 16.937 6.502 6.5 11.341 8.249 14.668 8.249a8.55 8.55 0 002.267-.297 3.453 3.453 0 002.046-1.575l1.473-2.45c.47-.779.242-1.79-.516-2.293z" />
        <path d="M28.873 25.13l-1.47 2.45a2.493 2.493 0 01-1.471 1.14c-2.968.815-8.153.14-15.999-7.706S1.413 7.984 2.227 5.016a2.497 2.497 0 011.142-1.474l2.45-1.47a.738.738 0 01.995.224l2.122 3.187 1.782 2.672a.742.742 0 01-.161.994l-2.194 1.706a1.688 1.688 0 00-.447 2.167l.174.316c.617 1.131 1.383 2.538 4.182 5.336 2.798 2.797 4.204 3.564 5.335 4.18l.316.175c.733.411 1.657.22 2.167-.447l1.707-2.193a.743.743 0 01.993-.162l5.859 3.908a.738.738 0 01.224.995zM17.403 5.826a8.21 8.21 0 018.2 8.2.482.482 0 10.965 0 9.175 9.175 0 00-9.165-9.165.482.482 0 100 .965z" />
        <path d="M17.403 8.72a5.313 5.313 0 015.306 5.307.482.482 0 00.965 0 6.278 6.278 0 00-6.271-6.271.482.482 0 100 .964z" />
        <path d="M17.403 11.615a2.415 2.415 0 012.412 2.412.482.482 0 00.965 0 3.38 3.38 0 00-3.377-3.377.482.482 0 100 .965z" />
      </g>
    </svg>
  )
}

export default IcoPhone
