import React, { useState, useRef, useEffect } from "react";
//STYLE
import "./css/uiPromoCarousel.css"

//ICON
import ButtonRedOn from "../../../asset/svg/ButtonRedOn";
import ButtonGreyOff from "../../../asset/svg/ButtonGreyOff";
import ButtonGreyOffReverse from "../../../asset/svg/ButtonGreyOffReverse"
import ButtonRedOnReverse from "../../../asset/svg/ButtonRedOnReverse";
import i18n from "../../../I18next";
import UiPromoImage from "./UiPromoImage";



export default function UiPromoCarousel(props) {

    const [nextHover, setNextHover] = useState(false);
    const [backHover, setBackHover]=useState(false)
    const [cont, setCont] = useState(0)
    const [initial, setInitial] = useState(0)
    const mask = useRef()
    
    useEffect(() => {
      if (window.addEventListener){
        window.addEventListener('resize', onUpdateDimension);
      }
    });


    const nextButton = () => {
        var min;
        var amount = 12;
        var stop=mask.current.clientWidth >= (props.data.length)*amount*16;
        if(window.innerWidth<= 408){
          //mobile vercion
          min = -1 * props.data.length ;
        } else if (window.innerWidth >= 1464 ) {
          //Desktop
          min = -1 * props.data.length + 5;
        } else if (window.innerWidth >= 1290 && window.innerWidth < 1464) {
          //Desktop windows big
          min = -1 * props.data.length + 4;
        }else if (window.innerWidth >= 1115 && window.innerWidth < 1290) {
          //Desktop windows medium
          min = -1 * props.data.length + 3;
        }else if (window.innerWidth >= 933 && window.innerWidth < 1115) {
          //Desktop windows small
          min = -1 * props.data.length + 2;
        }else{
          //ipad & web vercions
          min= -1 * props.data.length + 1;
        } 
        
        if (cont > min && !stop) {
          let init= initial - amount
          setCont(cont - 1)
          document.getElementById("carouselArray").style.transform = "translateX(" + init + "rem)";
          document.getElementById("carouselArray").style.transition = "0.5s";
          setInitial(initial - amount)
        }
      }
    
      const backButton = () =>{
        var max = props.data.length - 2;
        var amount = 12;
    
        if (cont < max && cont !== 0) {
          let init= initial + amount
          setCont(cont + 1)
          document.getElementById("carouselArray").style.transform = "translateX(" + init + "rem)";
          document.getElementById("carouselArray").style.transition = "0.5s";
          setInitial(initial + amount)
        }
      }


      


      const openPromotion = (url) => {
        let validUrl = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        if(url.match(validUrl)){
          window.open(url, '_blank', 'noreferrer');
        }
        else{
          props.setError(
            i18n.t("Error.NewOtp_generic"),
            false,
            i18n.t("Error.Generic_error")
          );
        }
      };


      const existProps = (props) => {
        if(props.data === undefined || props.data === null){
          return false;
        }
        if(props.data.length == 0){
          return false
        }
        return true;
      }
      

      const onUpdateDimension = () => {
        setCont(0)
        let element = document.getElementById("carouselArray");
        if(element !== null && element !== undefined){
          element.style.transform = "translateX(0rem)";
          element.style.transition = "0.8s";
        }
        let carouselContainer = document.getElementById("carouselContainerPromo");
        if(hasScroll(carouselContainer, 'horizontal')){
          carouselContainer.scrollLeft = 0;
        }
        setInitial(0)
      }

      const  hasScroll = (el, direction) => {
        direction = (direction === 'vertical') ? 'scrollTop' : 'scrollLeft';
        var result = !! el[direction];

        if (!result) {
            el[direction] = 1;
            result = !!el[direction];
            el[direction] = 0;
        }
        return result;
      }


    return (
      <div>
      {(() => { 
        if(existProps(props)) {
            return (
              <div className="promotionTourCarousel">
                <div
                    id={"backButtonCarouselPromo"}
                    className={"carouselDirectionalButtonArrowPromo"}
                    onMouseOver={() => {
                    setBackHover(true);
                    }}
                    onMouseOut={() => {
                    setBackHover(false);
                    }}
                    onClick = {()=>backButton()}
                >
                    {backHover ? <ButtonRedOnReverse /> : <ButtonGreyOffReverse />}
                </div>

                <div ref={mask} className="buttonArrayMaskPromo" id="carouselContainerPromo" style={{maxWidth:"calc("+ props.data.length*13+"rem)"}}>
                    <div className={"promoCarouselContainer"} id="carouselArray">
                    {props.data &&
                        props.data.map((item, index) => (
                          <UiPromoImage 
                            src={item.detailContent}
                            key={item.idPromotion}
                            onPromoClick={() => openPromotion(item.link)}
                            onDeletePromo={() => props.deleteCallback(item)}
                          />
                        ))}
                    </div>
                </div>
                <div
                    id={"nextButtonCarouselPromo"}
                    className={"carouselDirectionalButtonArrowPromo"}
                    onMouseOver={() => {
                    setNextHover(true);
                    }}
                    onMouseOut={() => {
                    setNextHover(false);
                    }}
                    onClick = {()=>nextButton()}
                >
                    {nextHover ? <ButtonRedOn /> : <ButtonGreyOff />}
                </div>
              </div>
            )
          }
        })()}
        </div>  
    )
}
