import React, { Component } from "react";

//TRANSLATIONS
import i18n from "../../I18next";
//STYLE
import "./css/statements.css";
//ANTD
import "antd/dist/antd.css";
import { DownloadOutlined } from "@ant-design/icons";
// import { DatePicker } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
//MOMENTJS
import moment from "moment";
import "moment/locale/de";
//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
//COMPONENT
import StatementListCard from "../funcComponent/StatementListCard";
//Spinner
import Spinner1 from "../funcComponent/Spinner1";
import SpinnerLogin from "../funcComponent/SpinnerLogin";
//Utils
import Utilities from "../../utils/utilities";

export default class Statements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectData: null,
      statementList: "",
      filterList: "",
      isLoading: false,
    };
  }
  componentDidMount = async () => {
    let cardId = this.props.cardId;
    let statementsCall = await GenericServices.get(
      "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        cardId +
        "/statement"
    );

    if (statementsCall.status === 200) {
      this.setState({ statementList: statementsCall.data.statementList });
    } else {
      let testError = Utilities.callErrorTest(statementsCall);
      if (testError !== false)
        this.props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };
  statementListFilter = () => {
    if (this.state.selectData !== null) {
      let filtered = this.state.statementList.filter(
        (statement) =>
          (statement =
            moment(statement.statementDate)
              .locale(i18n.language)
              .format("MMM YYYY") === this.state.selectData)
      );
      return filtered;
    }
    return [...this.state.statementList].reverse();
  };

  render() {
    return (
      <div className="statements_Page_Container">
        <div className="statements_Header_Container">
          <label>{i18n.t("SideBar.Statements")}</label>
        </div>

        <div className="statements_Select_Box">
          {/* <div className="statements_Select_container">
            <SearchOutlined
              style={{
                color: "red",
                fontSize: "1.5rem",
                marginRight: "0.5rem",
              }}
            />
            <DatePicker
              className="statements_Select_item"
              bordered={false}
              inputReadOnly={true}
              locale={i18n.language}
              onChange={(value) => {
                if (value === null) {
                  this.setState({
                    selectData: null,
                  });
                } else {
                  this.setState({
                    selectData: moment(value).format("MMM YYYY"),
                  });
                }
              }}
              picker="month"
              placeholder="Select month"
            />
          </div> */}
        </div>

        <div className="statements_Body_Container">
          {this.state.statementList === "" ? (
            <div className="statements_secondondarySpinner_Continer">
              <Spinner1 />
            </div>
          ) : this.state.statementList.length === 0 ? (
            <div className="statementsClosePageContainer">
              <div className="statements_ClosePage_EmptyContainer">
                <DownloadOutlined style={{ fontSize: "8rem" }} />
                <p>{i18n.t("Statements.NoStatements")}</p>
              </div>
            </div>
          ) : (
            <StatementListCard
              statementList={this.statementListFilter()}
              cardId={this.props.cardId}
              setError={(text, isCorse, title) =>
                this.props.setError(text, isCorse, title)
              }
              setIsLoading={(value)=>{this.setState({isLoading:value})}}
            />
          )}

          {
            //AFTER PRESS ONE STATEMENTS SPINNER
            this.state.isLoading && (
              <div className="statements_AfterCall_Spinner">
                <SpinnerLogin />
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
