import { get } from "lodash";
import axios from "axios";
import i18n from "../I18next";
import moment from "moment";
import { PROPERTIES } from "../utils/properties";

const Utilities = {
  getToken: () => {
    let temp = JSON.parse(window.sessionStorage?.getItem("logged"));
    let access_token = get(temp, "access_token", null);
    let refresh_token = get(temp, "refresh_token", null);
    let obj = {
      access_token: access_token,
      refresh_token: refresh_token,
    };
    return obj;
  },
  updateTTL: () => {
    let date= moment().format('DD')
    let time = moment().add(5, 'minutes').format('LTS')
    let obj = {
      DAY: date,
      TTL: time
    }
    window.sessionStorage.setItem("TTL", JSON.stringify(obj));
  },
  getCardId: () => {
    let temp = JSON.parse(window.sessionStorage?.getItem("CID"));
    let cid = get(temp, "cid", null);
    return cid;
  },

  maskerNumber: (num) => {
    let n = "" + num;
    if (n === "Undefined" || n === "null") {
      return "*** *******";
    } else {
      let hide = "";
      for (let i = 0; i < n.length - 3; i++) {
        if (i !== 2) {
          hide = hide + "*";
        } else {
          hide = hide + "* ";
        }
      }
      return hide + n.substring(n.length - 3, n.length);
    }
  },
  maskerIban: (iban) => {
    let n = "" + iban;
    let result = "";
    for (let i = 1; i < n.length + 1; i++) {
      if (i < 3 || i > n.length - 3) {
        result = result + n.substring(i - 1, i);
      } else {
        result = result + "*";
      }
    }
    return result;
  },
  maskerMail: (email) => {
    let pos = email.indexOf("@");
    let visible = Math.ceil(pos / 3);
    let result = "" + email.substring(0, visible);
    for (let i = visible + 1; i < pos + 1; i++) {
      result = result + "*";
    }
    result = result + email.substring(pos, email.length);

    return result;
  },
  uppercasePwControll: (pw) => {
    let char = "";
    var num = /^[1234567890!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
    if (pw === "") {
      return false;
    } else {
      for (let i = 0; i < pw.length; i++) {
        char = pw.charAt(i);
        if (char === char.toLocaleUpperCase() && !char.match(num)) {
          return true;
        }
      }
    }
    return false;
  },
  numberPwControll: (pw) => {
    let char = "";
    if (pw === "") {
      return false;
    } else {
      for (let i = 0; i < pw.length; i++) {
        char = pw.charAt(i);
        if (!isNaN(char * 1)) {
          return true;
        }
      }
    }
    return false;
  },
  specialCharPwControll: (pw) => {
    var format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
    let char = "";
    if (pw === "") {
      return false;
    } else {
      for (let i = 0; i < pw.length; i++) {
        char = pw.charAt(i);
        if (char.match(format)) {
          return true;
        }
      }
    }
    return false;
  },
  textControl: (t) => {
    if (t.length < 16) {
      return t;
    } else {
      let newT = t.substring(0, 15) + "...";
      return newT;
    }
  },
  availableAmountFormatter: (n) => {
    // //v1
    // let temp= (n / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    // //v2
    // let number=(n / 100).toFixed(2).replace(/[,.]/g, function (x) { return x == "," ? "." : ","; })
    // //v3
    let t3 = (n / 100).toFixed(3);
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(t3);
  },
  availableAmountsignControl: (t) => {
    if (t?.messageType === "T" || t?.messageType === "A") {
      //I invert the sign
      return Utilities.availableAmountFormatter(-1 * t.amount)
    } else {
      return Utilities.availableAmountFormatter(t.amount)
    }
  },
  cardNumberFommater: (n) => {
    n = "" + n;
    return (
      "XXXX  XXXX  XXXX  " +
      // n.substring(0, 4) +
      // "  " +
      // n.substring(4, 8) +
      // "  " +
      // n.substring(8, 12) +
      "  " +
      n.substring(12, 16)
    );
  },
  cardStatusConverter: (c) => {
    switch (c) {
      case "AA":
        return i18n.t("CardStatus.AA");
      
      case "AA_006":
        return i18n.t("CardStatus.AA_006");

      case "UK":
        return i18n.t("CardStatus.UK");

      case "CC":
        return i18n.t("CardStatus.CC");

      case "SI":
        return i18n.t("CardStatus.SI");

      case "PD":
        return i18n.t("CardStatus.PD");

      case "CP":
        return i18n.t("CardStatus.CP");

      case "FP":
        return i18n.t("CardStatus.FP");

      case "MP":
        return i18n.t("CardStatus.MP");

      case "LP":
        return i18n.t("CardStatus.LP");

      case "SP":
        return i18n.t("CardStatus.SP");

      case "XP":
        return i18n.t("CardStatus.XP");

      case "JT":
        return i18n.t("CardStatus.JT");

      case "SU":
        return i18n.t("CardStatus.SU");

      case "TS":
        return i18n.t("CardStatus.TS");

      case "UP":
        return i18n.t("CardStatus.UP");

      case "IN":
        return i18n.t("CardStatus.IN");

      case "NL":
        return i18n.t("CardStatus.NL");

      default:
        return "ERROR";
    }
  },
  cardStatusController: (cs) => {
    let obj = {
      text: "",
      title: "",
      button: "",
      isNoAccess: false,
    };
    switch (cs) {
      //ALL
      case "AA":
      case "AA_006":
      case "PD":
      case "SI":
        return false;

      //ALL+PopUp
      case "TS":
        obj.text=""+
        i18n.t("Error.CardStatusCase2_1") +
        Utilities.cardStatusConverter(cs) +
        i18n.t("Error.CardStatusCase3_2");
        obj.title = i18n.t("Error.LimitedFunctionality");
        obj.button = i18n.t("Buttons.OK");
        return obj;

      //NO ACCESS
      case "UK":
      case "CC":
      case "UP":
      case "IN":
      case "NL":
        obj.text = "" +
          i18n.t("Error.CardStatusCase2_1") +
          Utilities.cardStatusConverter(cs) +
          i18n.t("Error.CardStatusCase2_2");
        obj.title = i18n.t("Error.LimitedAccess");
        obj.button = i18n.t("Buttons.Close");
        obj.isNoAccess = true;
        return obj;

      //READ ONLY 1
      case "FP":
      case "LP":
      case "SP":
        obj.text = "" +
          i18n.t("Error.CardStatusCase2_1") +
          Utilities.cardStatusConverter(cs) +
          i18n.t("Error.CardStatusCase3_2");
        obj.title = i18n.t("Error.LimitedAccess");
        obj.button = i18n.t("Buttons.OK");
        return obj;

      //READ ONLY 2
      case "CP":
      case "MP":
      case "XP":
      case "JT":
      case "SU":
        obj.text = "" + i18n.t("Error.CardStatusCase2_1") +
          Utilities.cardStatusConverter(cs) +
          i18n.t("Error.CardStatusCase4_2");
        obj.title = i18n.t("Error.LimitedFunctionality");
        obj.button = i18n.t("Buttons.OK");
        return obj;

      default:
        return false;
    }
  },
  getPDF: async (cardId, statementId, isFromNewToken) => {
    const { access_token } = Utilities.getToken();
    let timeUp = false;
    let corse = false;
    let tokenLoop = isFromNewToken ? isFromNewToken : false;
    let okReturn = false;
    // console.log("INIZIO\n", "\nTimeUp:",timeUp,"\nCorse:",corse,"\nTokenLoop:", tokenLoop,"\n OkReturn:",okReturn, "\nIsfromNewToken:",isFromNewToken)
    let result;
    let secondResult;

    await axios({
      method: "get",
      url:
        "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        cardId +
        "/statement/" +
        statementId +
        "/transaction/pdf",
      responseType: "arraybuffer",

      headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Language: "IT",
        Authorization: `Bearer ${access_token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .catch(async (error) => {
        if (error?.response.status === 401) {
          if (!tokenLoop) {
            //Get new token test
            let newTokenCall = await Utilities.getNewToken();
            if (newTokenCall.status === 200) {
              let secondTry = await Utilities.getPDF(cardId, statementId, true);
              secondResult = secondTry;
              okReturn = true;
            }
            if (newTokenCall.status === 666) {
              timeUp = true;
              tokenLoop = true;
            }
          } else {
            //Inside second 401
            timeUp = true;
            tokenLoop = true;
          }
        } else if (typeof error.response === "undefined") {
          corse = true;
          okReturn = true;
        } else {
          //Inside Generic error case
          okReturn = true;
        }
        result = error.response;
        return error.response;
      })
      .then((response) => {
        if (response?.status === 200) {
          okReturn = true;
          result = response;
          return response;
        }
      });

    if (timeUp === true) {
      result = {
        status: 666,
        data: {
          errorMessage: i18n.t("Error.TimeIsUpRedirect"),
        },
      };
    } else if (corse === true) {
      result = {
        status: 667,
        data: {
          errorMessage: i18n.t("Error.CorsRedirect"),
        },
      };
    }
    // console.log("FINE\nResult:",result, "\nTimeUp:",timeUp,"\nCorse:",corse,"\nTokenLoop:", tokenLoop,"\n OkReturn:",okReturn, "\nIsfromNewToken:",isFromNewToken)
    if (tokenLoop || okReturn) {
      if (secondResult) {
        return secondResult;
      } else return result;
    }
  },
  getStatementFileName:(statement)=>{
    let filName= statement.split("T")
    return filName[0]+".pdf"
  },
  getNewToken: async () => {
    const { refresh_token } = Utilities.getToken();
    var querystring = require("querystring");
    let data;
    await axios({
      method: "post",
      url: "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
      data: querystring.stringify({
        grant_type: "refresh_token",
        refresh_token: refresh_token,
      }),
      headers: {
        Accept: "*/*",
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .catch((error) => {
        if (error?.response.status === 401) {
          let obj = {
            status: 666,
            data: {
              errorMessage: i18n.t("Error.TimeIsUpRedirect"),
            },
          };
          data = obj;
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          let newToken = response.data.access_token;
          const { refresh_token } = Utilities.getToken();
          let sessionStorageObj = {
            access_token: newToken,
            refresh_token: refresh_token,
          };
          window.sessionStorage.setItem(
            "logged",
            JSON.stringify(sessionStorageObj)
          );
          data = {
            status: 200,
          };
        }
      });
    return data;
  },
  sessionStorageControl: () => {
    const control = Utilities.getToken();
    if (!control.access_token || !control.refresh_token) {
      return false;
    }
    return true;
  },
  callErrorTest: (call) => {
    let isCorse = false;
    let textMessage = "";
    let title = "";
    let objError;

    //TimeUp
    if (call.status === 666 || call.status === 401) {
      textMessage = i18n.t("Error.TimeIsUpRedirect");
      isCorse = true;
      title = i18n.t("Error.ExpiredRequest");
      return (objError = {
        textMessage,
        isCorse,
        title,
      });
    }
    //Cors
    if (call.status === 667) {
      textMessage = i18n.t("Error.CorsRedirect");
      isCorse = true;
      return (objError = {
        textMessage,
        isCorse,
        title,
      });
    }
    //Generic error
    if (call > 399 || call.status < 501 || call.status === 204) {
      //"500 null" CONTROLL message
      let modalText = "";
      let Title = "";
      //Omega controll
      if (call.data?.errorMessage?.split("Ω").length === 2) {
        let message=call.data?.errorMessage.split("Ω")
        Title=message[0]
        modalText=message[1]
      } else {
        if (
          call.status === 204 ||
          call.data.errorMessage === "500 null" ||
          call.data?.errorMessage === undefined ||
          call.data?.errorMessage.substring(0, 28) === "I/O error on GET request for" ||
          call.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = call.data.errorMessage;
          Title = Utilities.titleErrorSelector(call.data.errorMessage);
        }
      }

      title = Title;
      textMessage = modalText;
      isCorse = false;
      return (objError = {
        textMessage,
        isCorse,
        title,
      });
    }
    return false;
  },
  titleErrorSelector: (errorMessage) => {
    switch (errorMessage) {
      case "Too many attempts. Please request a new code.\n":
      case "Zu viele Versuche. Bitte fordern Sie einen neuen Code an. \n":
      case "You have reached the maximum number of code requests for today. Please try again tomorrow.":
      case "Sie haben die maximale Anzahl von Codeanfragen für heute erreicht. Bitte versuchen Sie es morgen erneut.":
        return i18n.t("Error.TooManyAttempts");

      case "The provided code is expired. Please request a new code.\n":
      case "Der bereitgestellte Code ist abgelaufen. Bitte fordern Sie einen neuen Code an.\n":
        return i18n.t("Error.ExpiredCode");

      case "The provided code is not correct.\n":
      case "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. \n":
        return i18n.t("Error.IncorrectEntries");

      default:
        return "";
    }
  },
};

export default Utilities;
