import * as React from "react"

function Ico_info(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0a9 9 0 10.002 18.002A9 9 0 009 0zm1.375 13.912a1.37 1.37 0 01-1.371 1.366 1.37 1.37 0 01-1.372-1.366V7.935a1.371 1.371 0 012.743 0v5.977zM9 5.442a1.508 1.508 0 110-3.016 1.508 1.508 0 010 3.016z"
        fill="red"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Ico_info
