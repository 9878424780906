import React, {Component}  from "react";

//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
import { connect } from "react-redux";
import "./css/serviceInformationPopup.css"
import LangButton from "./UiComponents/UiLangButton";

class ServiceInformationPopup extends Component{

  constructor(props) {
		super(props);
		this.state = {
			serviceInformation: null,
      showMessage: true
		};
	}

  getServiceInformation = async () => {
    let serviceInformationCall = await GenericServices.get(
      "https://" +
      PROPERTIES.BASE_URL_NPC +
      "/sbcrm/v1/serviceInformation"
    );
    if (serviceInformationCall.status === 200) {
      this.setState({
        serviceInformation: serviceInformationCall.data
      })
    } 
  };

  componentDidMount = async () => {
    await this.getServiceInformation();
  }

  showInformationMessage = () => {
    return this.state.serviceInformation !== null && this.state.serviceInformation !== undefined && this.state.showMessage;
  }

  hideInformationMessage = () => {
    this.setState({
      showMessage:false
    });
  }
  

  render() {
    return this.showInformationMessage() ? (
      <div className="serviceInformationPopup">
        <div className="popupContent">
          <div className="header">{this.state.serviceInformation.header}</div>
          <div className="body">{this.state.serviceInformation.body}</div>
          <div className="popupButtons">
            <LangButton
              callback={async (value) => {
                await this.getServiceInformation();
                this.props.callback(value);
              }}
            ></LangButton> 
            <button className="remindMeButton" onClick={() => this.hideInformationMessage()}>{this.state.serviceInformation.remindMeLaterButtonText}</button>
          </div>
          
        </div>
      </div>
    ) :
    (
      <></>
    )
  }
  
}

const mapStateToProps = (state) => ({
	navigationDuck: state.navigationDuck,
});


export default connect(mapStateToProps)(ServiceInformationPopup);