import React, { Component } from "react";
//CSS
import "./css/smsLoginPopUp.css";
//UiComponent
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
import UiOTP from "../../funcComponent/UiComponents/UiOTP";
import UiButton from "../../funcComponent/UiComponents/UiButton";
//TRANSLATIONS
import i18n from "../../../I18next";
//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
import GenericServices from "../../../services/GenericServices";
import { PROPERTIES } from "../../../utils/properties";
//Utilities
import Utilities from "../../../utils/utilities";
//MOMENTJS
import moment from "moment";

export default class SMSLoginPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: "",
    };
  }

  onPressEnter = () => {
    if (this.state.OTP.length === 6) {
      this.verifyOTP()
    }
  }

  verifyOTP = async () => {
    console.log("Verify login")
    this.props.isLoading(true);
    let querystring = require("querystring");
    let verifyOTPCall;
    if (this.props.isFromEPin) {
      verifyOTPCall = await GenericServices.post(
        "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/verifyotp",
        querystring.stringify({
          auth_req_id: this.props.auth_req_id,
          encryptedInfo: "" + this.state.OTP,
        })
      );
    } else {
      verifyOTPCall = await ActivationServices.post(
        "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/verifyotp",
        querystring.stringify({
          auth_req_id: this.props.auth_req_id,
          encryptedInfo: "" + this.state.OTP,
        })
      );
    }

    if (verifyOTPCall.status === 200) {
      if (this.props.isFromEPin) {
        this.props.setEPinCall();
      } else {
        this.tokenCall();
      }
    }
    if (verifyOTPCall.status === 401) {
      this.props.isLoading(false);
      let isCorse = true;
      let title = ""
      let modalText = "";

      //Omega control
      if (verifyOTPCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = verifyOTPCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]
      } else {
        modalText = verifyOTPCall.data.errorMessage
      }
      this.props.setError(modalText, isCorse, title);

    }
    if (verifyOTPCall.status === 400 || verifyOTPCall.status === 500) {
      this.props.isLoading(false);

      let modalText = "";
      let title = "";
      //Omega controll
      if (verifyOTPCall.data?.errorMessage?.split("Ω").length === 2) {
        let message = verifyOTPCall.data?.errorMessage.split("Ω")
        title = message[0]
        modalText = message[1]

      } else {
        //"500 null" CONTROLL message
        if (
          verifyOTPCall.data.errorMessage === "500 null" ||
          verifyOTPCall.data?.errorMessage === undefined ||
          verifyOTPCall.data?.errorMessage.substring(0, 21) === "Internal server error"
        ) {
          modalText = i18n.t("Error.NewOtp_generic");
        } else {
          modalText = verifyOTPCall.data.errorMessage;
          if (
            verifyOTPCall.data.errorMessage ===
            "Too many attempts. Please request a new code.\n" ||
            verifyOTPCall.data.errorMessage ===
            "Zu viele Versuche. Bitte fordern Sie einen neuen Code an. \n"
          ) {
            title = i18n.t("Error.TooManyAttempts");
          } else {
            if (
              verifyOTPCall.data.errorMessage ===
              "The provided code is expired. Please request a new code.\n" ||
              verifyOTPCall.data.errorMessage ===
              "Der bereitgestellte Code ist abgelaufen. Bitte fordern Sie einen neuen Code an.\n"
            ) {
              title = i18n.t("Error.ExpiredCode");
            } else {
              title = i18n.t("Error.IncorrectEntries");
            }
          }
        }
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse, title);
    }
    if (verifyOTPCall.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
    }
  };

  tokenCall = async () => {
    this.props.isLoading(true);
    var querystring = require("querystring");
    let getToken = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
      querystring.stringify({
        auth_req_id: this.props.auth_req_id,
        grant_type: "auth_req_id",
      })
    );

    if (getToken.status === 200) {
      let obj = {
        access_token: getToken.data.access_token,
        refresh_token: getToken.data.refresh_token,
      };
      //Set token
      window.sessionStorage.setItem("logged", JSON.stringify(obj));
      //Time to Live
      let date= moment().format('DD')
      let time = moment().add(5, 'minutes').format('LTS')
      obj = {
        DAY: date,
        TTL: time
      }
      window.sessionStorage.setItem("TTL", JSON.stringify(obj));

      await this.props.setEPinTest();

      // this.props.setProgressive("_set_EPIN")
    }

    if (
      (getToken.status > 399 && getToken.status < 405) ||
      getToken.status === 500
    ) {
      this.props.isLoading(false);
      //"500 null" CONTROLL message
      let modalText = "";
      if (
        getToken.data.errorMessage === "500 null" ||
        getToken.data?.errorMessage === undefined ||
        getToken.data?.errorMessage.substring(0, 21) === "Internal server error"
      ) {
        modalText = i18n.t("Error.NewOtp_generic");
      } else {
        modalText = getToken.data.errorMessage;
      }
      let isCorse = false;
      this.props.setError(modalText, isCorse);
    }
    if (getToken.status === 667) {
      this.props.isLoading(false);
      let isCorse = true;
      this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
    }
  };

  render() {
    return (
      <div
        className="otpInputCardContainer"
        style={{ display: this.props.display && "none" }}
      >
        <div style={{ textAlign: "left" }}>
          <UiCardTitle
            text={
              this.props.isFromEPin
                ? i18n.t("PopUp.e-Pin")
                : i18n.t("PopUp.SignIn")
            }
          />
          <label className="SMSpopDescription" style={{ marginBottom: "1rem", fontFamily: "Sparkasse_Rg" }}>
            {i18n.language === "en"
              ? i18n.t("ActivationLogin.Description") +
              " " +
              Utilities.maskerNumber(this.props.phone)
              : //DE
              i18n.t("ActivationLogin.Description1") +
              " " +
              Utilities.maskerNumber(this.props.phone) +
              " " +
              i18n.t("ActivationLogin.Description2")}
          </label>
        </div>

        <UiOTP length={6} saveFunc={(value) => this.setState({ OTP: value })} onPressEnter={() => this.onPressEnter()} />

        <button
          className="link_Button"
          onClick={() => {
            this.props.setProgressive(4);
          }}
          style={{ margin: "1rem 0" }}
        >
          {i18n.t("PopUp.otpLink")}
        </button>

        <div className="SMSpopButtonContainer">
          <UiButton
            title={i18n.t("Buttons.Confirm")}
            disabled={this.state.OTP?.length < 6}
            press={() => {
              this.verifyOTP();
            }}
          />
        </div>
      </div>
    );
  }
}
