import * as React from "react"

function MenuFaqOffMobile(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.782 1h1.437c.392.051.786.089 1.175.155 2.16.37 4.07 1.272 5.707 2.729 2 1.781 3.252 3.992 3.723 6.636.074.418.118.841.176 1.262v1.438c-.034.274-.062.548-.101.82-.357 2.493-1.378 4.676-3.157 6.456-2.952 2.955-6.517 4.055-10.614 3.247-2.796-.552-5.064-2.033-6.78-4.303-1.986-2.627-2.707-5.596-2.182-8.847.383-2.373 1.447-4.42 3.13-6.134 1.722-1.755 3.798-2.855 6.223-3.282.419-.074.842-.119 1.263-.177"
          stroke="#FFF"
          strokeWidth={1.314}
        />
        <path
          d="M12.492 19.204a.943.943 0 00.961-.944.941.941 0 00-.944-.961.94.94 0 00-.961.944.942.942 0 00.944.961M11.55 14.085c0 .134-.007.27.001.404.03.5.45.889.954.887a.95.95 0 00.945-.896c.01-.156 0-.314.007-.471.015-.409.197-.716.57-.896.817-.393 1.464-.982 1.97-1.725.617-.907.97-1.882.747-3.004-.475-2.39-2.632-3.884-5.032-3.476-1.996.338-3.551 2.205-3.523 4.227.006.466.31.849.746.943.641.14 1.137-.303 1.18-1.032.06-1.009.565-1.731 1.49-2.11.93-.382 1.796-.2 2.539.476.68.619.927 1.597.598 2.325-.323.717-.82 1.28-1.53 1.64-1.109.561-1.651 1.473-1.662 2.708"
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default MenuFaqOffMobile
