import React, { Component } from "react";

//STYLE
import "./css/sideBar.css";
//ANTD
import "antd/dist/antd.css";
//UTILS
import Utilities from "../../utils/utilities";

//Icons
import ButtonGreyOff from "../../asset/svg/ButtonGreyOff";
import ButtonRedOn from "../../asset/svg/ButtonRedOn";
import MenuHomeOff from "../../asset/svg/MenuHomeOff";
import MenuHomeOn from "../../asset/svg/MenuHomeOn"
import MenuTransactionsOff from "../../asset/svg/MenuTransactionsOff"
import MenuTransactionsOn from "../../asset/svg/MenuTransactionsOn";
import MenuStatementsOn from "../../asset/svg/MenuStatementsOn";
import MenuStatementsOff from "../../asset/svg/MenuStatementsOff";
import MenuCardSettingOn from "../../asset/svg/MenuCardSettingOn";
import MenuCardSettingOff from "../../asset/svg/MenuCardSettingOff";
import MenuFaqOff from "../../asset/svg/MenuFaqOff";
import MenuFaqOn from "../../asset/svg/MenuFaqOn";
import MenuTermsConditionsOff from "../../asset/svg/MenuTermsConditionsOff";
import MenuTermsConditionsOn from "../../asset/svg/MenuTermsConditionsOn";

//TRANSLATIONS
import i18n from "../../I18next";
// REDUX
import { connect } from "react-redux";
import { setNav } from "../../redux/ducks/navigationDuck";

class sideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: null,
    };
  }

  render() {
    return (
      <div className="sidebarContainer" onClick={() => this.props.closeUserCard()}>
        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 0 ||
            this.state.hover === 0) &&
            "selected"
            }`}
          onClick={() => {
            this.props.dispatch(setNav(0));
            this.props.navigationFunc(0);
          }}
          onMouseOver={() => this.setState({ hover: 0 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">

            <div className="sidebarLabel">

              {this.props.navigationDuck.navigationPointer === 0 ||
                this.state.hover === 0
                ? <MenuHomeOn className='menuIcon' />
                : <MenuHomeOff className='menuIcon' />
              }

              <label
                className={
                  this.props.navigationDuck.navigationPointer === 0 ||
                    this.state.hover === 0
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
              >
                {i18n.t("SideBar.Home")}
              </label>
            </div>
            <div className="sidebarIcon">

              {this.props.navigationDuck.navigationPointer === 0 ||
                this.state.hover === 0
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }

            </div>
          </div>
        </button>

        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 1 ||
            this.state.hover === 1) &&
            "selected"
            }`}
          onClick={() => {
            this.props.dispatch(setNav(1));
            this.props.navigationFunc(1);
          }}
          onMouseOver={() => this.setState({ hover: 1 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">

            <div className="sidebarLabel">
              {
                this.props.navigationDuck.navigationPointer === 1 ||
                  this.state.hover === 1
                  ? <MenuTransactionsOn className='menuIcon' />
                  : <MenuTransactionsOff className='menuIcon' />
              }

              <label
                className={
                  this.props.navigationDuck.navigationPointer === 1 ||
                    this.state.hover === 1
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
              >
                {i18n.t("SideBar.Transactions")}
              </label>
            </div>
            <div className="sidebarIcon">
              {this.props.navigationDuck.navigationPointer === 1 ||
                this.state.hover === 1
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }

            </div>
          </div>
        </button>

        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 2 ||
            this.state.hover === 2) &&
            "selected"
            }`}
          onClick={() => {
            this.props.dispatch(setNav(2));
            this.props.navigationFunc(2);
          }}
          onMouseOver={() => this.setState({ hover: 2 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">
            <div className="sidebarLabel">

              {
                this.props.navigationDuck.navigationPointer === 2 ||
                  this.state.hover === 2
                  ? <MenuStatementsOn className='menuIcon' />
                  : <MenuStatementsOff className='menuIcon' />
              }

              <label
                className={
                  this.props.navigationDuck.navigationPointer === 2 ||
                    this.state.hover === 2
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
              >
                {i18n.t("SideBar.Statements")}
              </label>
            </div>
            <div className="sidebarIcon">
              {this.props.navigationDuck.navigationPointer === 2 ||
                this.state.hover === 2
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }

            </div>
          </div>
        </button>

        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 3 ||
            this.state.hover === 3) &&
            "selected"
            }`}
          onClick={() => {
            //CardStatus controll
            if(this.props.cardStatus==="AA"|| this.props.cardStatus==="AA_006"|| this.props.cardStatus==="TS" || this.props.cardStatus==="PD" || this.props.cardStatus==="SI"){
              this.props.dispatch(setNav(3));
            this.props.navigationFunc(3);
            }else{
              let obj={
                title:i18n.t("Error.LimitedFunctionality"),
                text:"" +
                i18n.t("Error.CardStatusCase2_1") +
                Utilities.cardStatusConverter(this.props.cardStatus) +
                i18n.t("Error.CardStatusCase5_2"),
                button: i18n.t("Buttons.OK")
              }
              this.props.funcWrongCardStatus(obj)
            }
            
          }}
          onMouseOver={() => this.setState({ hover: 3 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">
            <div className="sidebarLabel">
              {
                this.props.navigationDuck.navigationPointer === 3 ||
                  this.state.hover === 3
                  ? <MenuCardSettingOn className='menuIcon' />
                  : <MenuCardSettingOff className='menuIcon' />
              }
              <label
                className={
                  this.props.navigationDuck.navigationPointer === 3 ||
                    this.state.hover === 3
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
              >
                {i18n.t("SideBar.CardSetting")}
              </label>
            </div>
            <div className="sidebarIcon">
              {this.props.navigationDuck.navigationPointer === 3 ||
                this.state.hover === 3
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }

            </div>
          </div>
        </button>

        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 4 ||
            this.state.hover === 4) &&
            "selected"
            }`}
          onClick={() => {
            this.props.dispatch(setNav(4));
            this.props.navigationFunc(4);
          }}
          onMouseOver={() => this.setState({ hover: 4 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">
            <div className="sidebarLabel">

              {
                this.props.navigationDuck.navigationPointer === 4 ||
                  this.state.hover === 4
                  ? <MenuFaqOn className='menuIcon' />
                  : <MenuFaqOff className='menuIcon' />
              }

              <label
                className={
                  this.props.navigationDuck.navigationPointer === 4 ||
                    this.state.hover === 4
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
              >
                {i18n.t("SideBar.FAQ")}
              </label>
            </div>
            <div className="sidebarIcon">
              {this.props.navigationDuck.navigationPointer === 4 ||
                this.state.hover === 4
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }
            </div>
          </div>
        </button>

        <button
          className={`singleCardContainerSidebar ${(this.props.navigationDuck.navigationPointer === 5 ||
            this.state.hover === 5) &&
            "selected"
            }`}
          onClick={() => {
            this.props.dispatch(setNav(5));
            this.props.navigationFunc(5);
          }}
          onMouseOver={() => this.setState({ hover: 5 })}
          onMouseOut={() => this.setState({ hover: null })}
        >
          <div className="singleCardItemSidebar">
            <div className="sidebarLabel">
              {
                this.props.navigationDuck.navigationPointer === 5 ||
                  this.state.hover === 5
                  ? <MenuTermsConditionsOn className='menuIcon' />
                  : <MenuTermsConditionsOff className='menuIcon' />
              }

              <label
                className={
                  this.props.navigationDuck.navigationPointer === 5 ||
                    this.state.hover === 5
                    ? "sidebar_labelSelect"
                    : "sidebar_labelDeSelect"
                }
                style={{
                  textAlign: "left",
                }}
              >
                {i18n.t("SideBar.TermsAndConditions")}
              </label>
            </div>
            <div className="sidebarIcon">
              {this.props.navigationDuck.navigationPointer === 5 ||
                this.state.hover === 5
                ? <ButtonRedOn />
                : <ButtonGreyOff />
              }

            </div>
          </div>
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
});

export default connect(mapStateToProps)(sideBar);
