import React, { useEffect, useState } from "react";
//STYLE
import "./css/biometry_pop_up.css";
//ANTD
import "antd/dist/antd.css";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
//TRANSLATIONS
import i18n from "../../I18next";
//IMAGE
import biometry from "../../asset/img/biometry.png";
//UiComponent
import UiOTP from "./UiComponents/UiOTP";
import UiButton from "./UiComponents/UiButton";
import UiCardTitle from "../funcComponent/UiComponents/UiCardTitle";
//REDUX
import { connect } from "react-redux";
import { initBcAuth } from "../../redux/ducks/bcAuthDuck";
import { setBcToken } from "../../redux/ducks/bcTokenDuck";
import {
  setCloseBiometry,
  setSMS,
  setNewCode,
  initNewCode
} from "../../redux/ducks/iframeComunicationDuck";
import Utilities from "../../utils/utilities";
//
import ActivationServices from "../../services/ActivationServices";
import { PROPERTIES } from "../../utils/properties";
import BcAuthorize from "../../utils/bcAuthorize";
//COMPONENT
import BiometrySendNewCode from "../classComponent/Biometry_SendNewCode";
import SpinnerLogin from "../funcComponent/SpinnerLogin";

const Biometry_pop_up = (props) => {
  const [acr, setAcr] = useState("");
  const [OTP, setOTP] = useState("");
  const [bcAuth, set_BcAuth] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  var pollingBreak = false;
  var TokenPollingCall;

  const tokenCall = async () => {
    let querystring = require("querystring");
    TokenPollingCall = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/token",
      querystring.stringify({
        auth_req_id: bcAuth.auth_req_id
          ? bcAuth.auth_req_id
          : props.bcAuthDuck.bcauth.auth_req_id,
        grant_type: "auth_req_id",
      })
    );
  };

  const pollingToken = async () => {
    if (pollingBreak === false && acr === "mfa") {
      await tokenCall();
      
      if (TokenPollingCall.status === 200) {
        if (TokenPollingCall.data?.pending_response?.message==="PENDING") {
          setTimeout(() => {
            pollingToken();
          }, 1000);
        } else if (TokenPollingCall.data?.pending_response?.message === "EXPIRED") {
          pollingBreak = true;
          props.dispatch(initBcAuth());
          props.setError(i18n.t("Error.TimeIsUp"), false ,i18n.t("Error.ExpiredRequest"))    
        }else {
          let new_access_token = TokenPollingCall.data.access_token;
          // inizializzo bcAuth
          props.dispatch(initBcAuth());
          let data = {
            token: new_access_token,
            scope: props.bcAuthDuck.bcauth.scope,
          };
          // redux per poter ripetere la chiamata
          props.dispatch(setBcToken(data));
        }
      } else {
        if (TokenPollingCall.status === 401) {
          pollingBreak = true;
          props.dispatch(initBcAuth());
        }
        let testError = Utilities.callErrorTest(TokenPollingCall);
        if (testError !== false)
          props.setError(testError.textMessage, testError.isCorse, testError.title);
      }
    }
  };

  const goToOTP = async () => {
    if (type === "ViewCard" || type === "ViewPin") {
      // change acr comunication with iframe
      props.dispatch(setSMS(true));
    } else {
      setIsLoading(true);
      let bcAuthTest = await BcAuthorize.call(
        props.bcAuthDuck.bcauth.scope,
        "sms"
      );
      set_BcAuth(bcAuthTest.data);
      setAcr("sms");
      setIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    setIsLoading(true);
    let querystring = require("querystring");
    let verifyOTPCall = await ActivationServices.post(
      "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/verifyotp",
      querystring.stringify({
        auth_req_id: bcAuth.auth_req_id
          ? bcAuth.auth_req_id
          : props.bcAuthDuck.bcauth.auth_req_id,
        encryptedInfo: OTP,
      })
    );
    if (verifyOTPCall.status === 200) {
      if (!type) {
        await tokenCall();
        if (TokenPollingCall.status === 200) {
          let new_access_token = TokenPollingCall.data.access_token;
          // inizializzo bcAuth
          props.dispatch(initBcAuth());
          // redux per poter ripetere la chiamata
          let data = {
            token: new_access_token,
            scope: props.bcAuthDuck.bcauth.scope,
          };
          props.dispatch(setBcToken(data));
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
      let testError = Utilities.callErrorTest(verifyOTPCall);
      if (testError !== false)
        props.setError(testError.textMessage, testError.isCorse, testError.title);
    }
  };
  useEffect(() => {
    props.dispatch(initNewCode())
  }, [])

  useEffect(() => {
    if (acr === "mfa" && !type) {
      pollingToken();
    }
  }, [acr]);

  useEffect(() => {
    setAcr(props.bcAuthDuck.bcauth?.acr);
    setType(props.bcAuthDuck.bcauth?.type);
    setTitle(props.bcAuthDuck.bcauth?.title);
    setDescription(props.bcAuthDuck.bcauth?.description);
    setPhone(props.bcAuthDuck.bcauth?.phoneNumber);
  }, [props.bcAuthDuck.bcauth]);

  const onPressEnter=()=>{
    // console.log("dentro la funzione, OTP:",OTP)
    if(OTP.length === 6){
      verifyOTP()
    }
  }
  return (
    <div className="overlayBiometry">
      {isLoading &&
        <div className="loaderBiometryContainer">
          <SpinnerLogin />
        </div>
      }
      <div className="biometryCardContainer">
        <div className="biometryCard">
          <div className="iconsContainer" style={{ display: isLoading && "none", justifyContent: (!props.iframeComunicationDuck.sendNewCode) && "flex-end" }}>
            <LeftOutlined
              className="backIcon"
              style={{ display: (!props.iframeComunicationDuck.sendNewCode) && "none" }}
              onClick={() => props.dispatch(setNewCode(false))}
            />
            <CloseOutlined
              className="closeIcon"
              onClick={() => {
                props.dispatch(initBcAuth());
                if (type === "ViewCard" || type === "ViewPin" ) {
                  props.dispatch(setCloseBiometry(true));
                }
                pollingBreak = true;
              }}
            />
          </div>
          <div className="cardContainer" style={{ display: isLoading && "none" }}>
            {( props.iframeComunicationDuck.sendNewCode)  ? (
              <BiometrySendNewCode
                setError={(modalText, isCorse, title) =>
                  props.setError(modalText, isCorse, title)
                }
                auth_req_id={
                  bcAuth.auth_req_id
                    ? bcAuth.auth_req_id
                    : props.bcAuthDuck.bcauth.auth_req_id
                }
                success={() => {
                  props.dispatch(setNewCode(false));
                }}
                sendIsLoading={(value) => setIsLoading(value)}
              />
            ) : (
              <>
                <UiCardTitle text={title ? title : "Athorization required"} />
                {acr === "sms" ? (
                  <>
                    <label
                      className="smsDescription"
                      style={{ marginBottom: "1rem" }}
                    >
                      {i18n.language==="en"?
                      i18n.t("CardDetail.SubTitle2") +
                        Utilities.maskerNumber(bcAuth?.phoneNumber || phone)  
                      :
                      i18n.t("CardDetail.SubTitle2") +
                        Utilities.maskerNumber(bcAuth?.phoneNumber || phone) +
                        i18n.t("CardDetail.SubTitle22") 
                      }
                    </label>

                    <UiOTP length={6} saveFunc={(val) => setOTP(val)} onPressEnter={()=>onPressEnter()} />

                    <button
                      className="link_Button"
                      onClick={() => {
                        props.dispatch(setNewCode(true));
                      }}
                      style={{ margin: "1rem 0" }}
                    >
                      {i18n.t("PopUp.otpLink")}
                    </button>

                    <div className="containerConfirm">
                      <UiButton
                        title={i18n.t("Buttons.Confirm")}
                        disabled={OTP.length < 6}
                        press={() => {
                          //function for login mockup biometry
                          // props.setProgressive(109);
                          verifyOTP();
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label className="mfaDescription">
                      {description
                        ? description
                        : "Open the S-Kreditpartner App or notification to [show card details]"}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        alt="Biometry"
                        className="imgContainer"
                        src={biometry}
                      />
                    </div>

                    <button
                      className="link_Button"
                      onClick={() => {
                        goToOTP();
                        pollingBreak = true;
                      }}
                    >
                      {i18n.t("PopUp.mfaLink")}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bcAuthDuck: state.bcAuthDuck,
  iframeComunicationDuck: state.iframeComunicationDuck,
});

export default connect(mapStateToProps)(Biometry_pop_up);
