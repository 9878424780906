import * as React from "react"

function Pdf(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"pdf"}</title>
      <g transform="translate(4 1)" fill="none" fillRule="evenodd">
        <text
          fontFamily="Sparkasse-Bold, .\x7F"
          fontSize={7}
          fontWeight="bold"
          fill="red"
        >
          <tspan x={5.124} y={16}>
            {"PDF"}
          </tspan>
        </text>
        <path
          d="M20.683 7.5l-5.348-5.231c0 1.508-.005 3.068.004 4.627.003.362.283.598.674.602.738.006 1.476.002 2.214.002h2.456zM14.06 1.26c-.137-.006-.234-.014-.332-.014H2.113c-.62 0-.84.22-.84.837v25.832c0 .626.22.839.869.84h18.712c.66 0 .873-.212.873-.865V8.745h-.394c-1.727 0-3.454-.032-5.18.01-1.2.03-2.137-.853-2.104-2.07.046-1.677.012-3.358.012-5.037V1.26zM14.914 0c.181.164.369.322.543.492 2.439 2.38 4.877 4.758 7.305 7.148.13.13.228.356.228.538.012 6.63.011 13.262.008 19.892 0 1.115-.828 1.927-1.964 1.928-6.359.003-12.718.003-19.076 0-1.12-.001-1.956-.814-1.956-1.906C0 19.362 0 10.632.002 1.902c0-.868.53-1.56 1.37-1.827.063-.02.124-.05.186-.075h13.356z"
          fill="red"
        />
        <circle fill="red" cx={20.5} cy={26.5} r={8.5} />
        <path
          d="M21.414 29.175l2.633-2.84c.414-.448.809-.531 1.138-.237.339.302.3.696-.12 1.149a3088.081 3088.081 0 01-3.732 4.01c-.44.471-.818.476-1.255.007-1.26-1.35-2.518-2.703-3.773-4.058-.107-.116-.219-.25-.267-.396a.637.637 0 01.21-.727.63.63 0 01.752-.071c.127.071.238.181.34.29.802.852 1.601 1.708 2.399 2.565.071.076.126.168.19.252l.1-.042v-.364c0-2.277 0-4.554.002-6.831 0-.517.17-.777.546-.865.336-.078.689.113.78.445.043.152.055.317.055.477.004 2.266.002 4.533.002 6.8v.436"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default Pdf