import React, { Component } from "react";
import "./css/faq.css";

//GENERIC SERVICES
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
//UI COMPONENT
import UiButtonCarousel from "../funcComponent/UiComponents/UiButtonCarousel";
import UiFAQCollapsable from "../funcComponent/UiComponents/UiFAQCollapsable";
//Spinner
import Spinner1 from "../funcComponent/Spinner1";
//Utils
import Utilities from "../../utils/utilities";
//Translations
import i18n from "../../I18next";

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: "",
      isReady: false,
      filterFaq: "",
      lang: i18n.language.toUpperCase()
    };
  }

  componentDidMount = async () => {
    let contentID = "b2ad6445-afa8-4f63-8534-e24464c4577c";
    let faqCall = await GenericServices.get(
      "https://" + PROPERTIES.BASE_URL_NPC +
      "/sbcontent/v1/content/" +
      contentID +
      "/digest/faq"
    );
    if (faqCall.status === 200) {
      let text = JSON.parse(faqCall.data.content);
      this.setState({ faq: text.faqs, isReady: true });
      this.languageFilter()
    }else{
      let testError=Utilities.callErrorTest(faqCall)
      if(testError!== false) this.props.setError(testError.textMessage, testError.isCorse, testError.title);
    }
  };
 
  componentDidUpdate = (PrevProps) => {
    if (this.state.lang !== i18n.language.toUpperCase()) {
      this.setState({ lang: i18n.language.toUpperCase() })
      this.languageFilter()
    }
  }

  languageFilter = () => {
    let filtered = [...this.state.faq].filter(
      (faq) => faq.language === i18n.language.toUpperCase()
    );
    this.setState({ filterFaq: filtered })
  }


  emptyControll = () => {
    let filtred = [...this.state.filterFaq].filter(
      (category) => category.selected === true
    );
    if (filtred.length === 0) {
      return true;
    }
    return false;
  };
  render() {
    return (
      <div className="FAQ_Page_Container">
        <div className="FAQ_Header_Container">
          <label>{"FAQ"}</label>
        </div>
        {
          this.state.isReady &&
          <div className="FAQ_Carosel_Box">
            <UiButtonCarousel
              // data={this.state.faq}
              data={this.state.filterFaq}
              callBackFunc={(value) => {
                this.setState({ filterFaq: value });
              }}
            />
          </div>
        }


        <div className="FAQ_Body_Container">
          {this.state.faq === "" ? (
            <div className="faq_secondondarySpinner_Continer">
              <Spinner1 />
            </div>
          ) : (
            <UiFAQCollapsable
              // data={this.state.faq}
              data={this.state.filterFaq}
              empty={this.emptyControll()}
            />
          )}
        </div>
      </div>
    );
  }
}
