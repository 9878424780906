import React, {Component} from "react";

import GenericServices from "../../services/GenericServices";
import {PROPERTIES} from "../../utils/properties";
import Utilities from "../../utils/utilities";
import {connect} from "react-redux";
import { render } from "@testing-library/react";
import { setPromotions } from "../../redux/ducks/promotionsDuck";
import UiButtonCarousel from "../funcComponent/UiComponents/UiButtonCarousel";
import UiPromoCarousel from "../funcComponent/UiComponents/UiPromoCarousel";


class PromotionBanner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recordsNumber: null,
			promotionsBannerList: null
		};
	}

	promotionsCall = async () => {
		let promotionStatusCall = await GenericServices.get(
			PROPERTIES.BASE_URL_PROMO_SEARCH_PROMOTION
		);

		if(promotionStatusCall.status == 200){
		  this.props.dispatch(setPromotions(promotionStatusCall.data));
		}
		else{
		  this.props.dispatch(setPromotions([]));
		}
	  };

	deletePromotionsCall = async (promotionId) => {

		let promotionStatusCall = await GenericServices.post(
			PROPERTIES.BASE_URL_PROMO_DELETE_PROMOTION + promotionId.toString()
		);

		if(promotionStatusCall.status == 200){
			await this.promotionsCall();
		}
	};

	onDeleteUserPromotion = async (promotion) => {
		this.deletePromotionsCall(promotion.idPromotion);
	};


	render() {
		return <UiPromoCarousel data={this.props.promotions} 
					deleteCallback={(promotion) => { this.onDeleteUserPromotion(promotion)}}
					setError={(modalText, isCorse, title) =>
						this.props.setError(modalText, isCorse, title)
					}
				></UiPromoCarousel>
	}

}

const mapStateToProps = (state) => ({
	navigationDuck: state.navigationDuck,
});


export default connect(mapStateToProps)(PromotionBanner);