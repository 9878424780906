import React, { useState } from "react";

//MOMENTJS
import moment from "moment";
//STYLE
import "./css/uiCollapsable.css";

//TRANSLATE
import i18n from "../../../I18next";
//UTILS
import Utilities from "../../../utils/utilities";

//icons
import TransactionsIn from "../../../asset/svg/TransactionsIn";
import TransactionOut from "../../../asset/svg/TransactionOut";
import ButtonOffBottom from "../../../asset/svg/ButtonOffBottom";
import ButtonOnTop from "../../../asset/svg/ButtonOnTop";

const UiCollapsable = (props) => {
  const [expanded, setExpanded] = useState(null);

  const handleClick = (index) => {
    if (props.size >= 768) {
      if (index === expanded) {
        setExpanded(null);
      } else {
        setExpanded(index);
      }
    } else if (props.mobileOnClick) {
      props.mobileOnClick(props.data[index]);
    }
  };

  const typeDetection = (type) => {
    if (type === "T") {
      return i18n.t("Transactions.Transaction");
    }
    if (type === "A") {
      return i18n.t("Transactions.AuthorizationOutstanding");
    }
    if (type === "D") {
      return i18n.t("Transactions.AuthorizationDenied");
    }
    return null;
  };

  const typeDescription=(t)=>{
    if(t.messageType==="A"){
      if(t?.authType==="CV"){
        return i18n.t("Transactions.CV");
      }else{
        return i18n.t("Transactions.NotYetCleared");
      }
    }
  }

  const transactionDateTime=(t)=>{
    if(t.messageType==="T"){
      let authDateTime = moment(t.authDateTime, 'DD MMMM YYYY HH:mm:ss').valueOf();
      let receivingTimestamp = moment(t.receivingTimestamp, 'DD MMMM YYYY HH:mm:ss').valueOf();
      if(authDateTime != receivingTimestamp){
        return moment(t.authDateTime)
              .locale(i18n.language)
              .format("DD MMMM YYYY HH:mm")
              .toUpperCase();
      }
      else{
        return moment(t.transDate)
              .locale(i18n.language)
              .format("DD MMMM YYYY")
              .toUpperCase();
      }
    }
    else{
      return moment(t.authDateTime)
      .locale(i18n.language)
      .format("DD MMMM YYYY HH:mm")
      .toUpperCase();
    }
  }


  const dateControl = (t, index) => {
    if (index !== 0) {
      let prevDate = moment(props.data[index - 1].authDateTime).format(
        "MMMM YYYY"
      );
      let nextDate = moment(t.authDateTime).format("MMMM YYYY");
      if (prevDate !== nextDate) return true;
    }
    return false;
  };

  return (
    <>
      {props.data &&
        props.data.map((t, index) => (
          <>
            {!props.isHome && (index === 0 || dateControl(t, index)) && (
              <div className="collapsable_date_Container">
                <label>{moment(t.authDateTime).locale(i18n.language).format("MMMM YYYY")}</label>
              </div>
            )}
            <div
              className={`collapsable  ${props.tDetails && "Mob"} ${
                index === props.data.length - 1 && "Last"
              }`}
              style={{
                backgroundColor: expanded === index ? "#f0f0f0" : "white",
              }}
              key={index}
            >
              <div
                className={`collapsable-header ${props.tDetails && "Mob"}`}
                onClick={() => handleClick(index)}
              >
                <div className="collapsable-header_icon-leftContainer">
                  {t?.authType === "CV"  || t?.amount<0 ? (
                    <TransactionsIn />
                  ) : (
                    <TransactionOut />
                  )}
                  <div className="collapsable-header_leftContainer">
                    <span className="collapsable-header_leftContainer_description">
                      { props.size < 400
                        ? Utilities.textControl(t.merchantName? t.merchantName : t.description)
                        : t.merchantName? t.merchantName : t.description}
                    </span>

                    <span className="collapsable-header_leftContainer_authDateTime">
                      {transactionDateTime(t)}
                    </span>
                  </div>
                </div>
                <div className="collapsable-header_rightContainer">
                  <div
                    className={`collapsable-header_rightContainer_amountAndDescription
                                     ${(t.messageType==="A"&& t?.authType === "CV") && "positive"}
                                     ${(t.messageType==="A"&& t?.authType !== "CV") && "notCleared"}
                                     ${props.size < 768 && "mob"}
                                     `}
                  >
                    <span
                      className={`collapsable-header_rightContainer_amount 
                                        ${props.size < 768 && "mob"}
                                        ${(t.amount<0 && !(t.messageType==="A"&& t?.authType !== "CV")) && "positive"}
                                        ${(t.messageType==="A"&& t?.authType !== "CV") && "notCleared"}
                                        `}
                    >
                      {Utilities.availableAmountFormatter(-1*t.amount)}
                    </span>
                    <span>{typeDescription(t)}</span>
                  </div>

                  {expanded === index && props.size >= 768 ? (
                    <ButtonOnTop />
                  ) : (
                    props.size >= 768 && <ButtonOffBottom />
                  )}
                </div>
              </div>
              {((expanded === index && props.size >= 768) ||
                props.tDetails) && (
                <div
                  className={`collapsable-details  ${props.tDetails && "Mob"}`}
                >
                  <div
                    className={`collapsable-details_singleItem ${
                      props.tDetails && "Mob"
                    }`}
                  >
                    <div className="collapsable-details_lable">
                      <label>{i18n.t("Transactions.PaymentStaus")}</label>
                    </div>
                    <div className="collapsable-details_info">
                      <span>{typeDetection(t.messageType)}</span>
                    </div>
                  </div>
                  <div
                    className={`collapsable-details_singleItem ${
                      props.tDetails && "Mob"
                    }`}
                  >
                    <div className="collapsable-details_lable">
                      <label>{i18n.t("Transactions.Circuit")}</label>
                    </div>
                    <div className="collapsable-details_info">
                      <span>Visa</span>
                    </div>
                  </div>
                  <div
                    className={`collapsable-details_singleItem ${
                      props.tDetails && "Mob"
                    }`}
                  >
                    <div className="collapsable-details_lable">
                      <label>{i18n.t("Transactions.Description")}</label>
                    </div>
                    <div className="collapsable-details_info">
                      <span>{t.description}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ))}
    </>
  );
};
export default UiCollapsable;
