import React, { Component } from "react";
import axios from "axios/index";
//LODASH
import { get } from "lodash";
//STYLE
import "./css/cardSetting.css";
//TRANSLATIONS
import i18n from "../../I18next";
//UICOMPONENT
import UiButton from "../funcComponent/UiComponents/UiButton";
import UiModal from "../funcComponent/UiComponents/UiModal";
//SCREEN COMPONENT
import ChangeEPin from "./ChangeEPin";
//ANTD
import { RightCircleOutlined, RightCircleFilled } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Checkbox } from "antd";
//GeneralServices
import GenericServices from "../../services/GenericServices";
//PROPERTIES
import { PROPERTIES } from "../../utils/properties";
//Utilities
import Utilities from "../../utils/utilities";
//BCAUTH TEST
import BcAuthorize from "../../utils/bcAuthorize";
import ScopeListTester from "../../utils/scopeListTester";
// REDUX
import { connect } from "react-redux";
import { setBcAuth } from "../../redux/ducks/bcAuthDuck";
import { initBcToken } from "../../redux/ducks/bcTokenDuck";
import { setChangePinView } from "../../redux/ducks/navigationDuck";
import IframePinParent from "../funcComponent/IframePinParent";
// SPINNER
import Spinner1 from "../funcComponent/Spinner1";
import SpinnerLogin from "../funcComponent/SpinnerLogin";
import SuccessChangePin from "../funcComponent/SuccessChangePin";

class CardSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardStatus: this.props.cardStatus,
      eCommerceStatus: null,
      //Modal
      showSuspendCardModal: false,
      showSuspendeCommPayModal: false,
      showGeoBlockModal: false,
      //Button
      SuspendCardButton: false,
      suspendeCommPayButton: false,
      DOMCheck: false,
      WDWCheck: false,
      buttonHover: null,
      //Screen
      showChangeEPinScreen: false,
      showSuccessChangePINScreen: false,
      //Wait Call controll button
      cardStatusButtonCall: true,
      eCommerceButtonCall: true,
      //GeoOBJ
      geoObj: null,
      callInfo: null,
      test: null,
      geoChange: false,
      callType: "",
      cardStatusDone: false,
      geoInitState: [],
      error: false,
      //CHANGE PIN
      // iframePinData: null,
      changePinSpecialToken: null,
      //Loader
      isLoading: false,
    };
    this.noGeoLoop = false;
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.props.dispatch(setChangePinView(false));

    //card Status CAll
    this.cardStatusInitCall();

    //geoBlocking call
    this.geoBlockingInitCall();

    //e-Commerce CAll
    this.eCommerceInitCall();
  };

  //card Status CAll
  cardStatusInitCall = async (spinner) => {
    if(spinner){
      this.setState({ isLoading: true });
    }
    let call = await GenericServices.get(
      "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        this.props.cardInfo.cardId +
        "/state"
    );
    if (call.status === 200) {
      let cardStatus = call.data.state;
      this.setState({ cardStatus: cardStatus, isLoading: false });
      //Home status Update
      this.props.cardStatusUpdateFunc(cardStatus);
      if (cardStatus !== "AA" && cardStatus !== "AA_006" && cardStatus !== "SI" && cardStatus !== "PD") {
        this.setState({
          cardInfo: this.props.cardInfo,
          SuspendCardButton: true,
          cardStatusDone: true,
        });
      }else{
        this.setState({
          cardInfo: this.props.cardInfo,
          SuspendCardButton: false,
          cardStatusDone: false,
        });
      }
    } else {

      let testError = Utilities.callErrorTest(call);
      if (testError !== false){
        this.props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
      }
      if(spinner){
        this.setState({ isLoading: false });
      }

    }
  };

  //geoBlocking call
  geoBlockingInitCall = async () => {
    let call = await GenericServices.get(
      "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        this.props.cardInfo.cardId +
        "/mycontrol/geoblocking"
    );
    if (call.status === 200) {
      let wdwState;
      let domState;
      call.data.geolist.map((g, i) => {
        if (g.zone === "WDW") {
          wdwState = g.enabled;
        }
        if (g.zone === "DOM") {
          domState = g.enabled;
        }
      });
      this.setState({
        WDWCheck: wdwState,
        DOMCheck: domState,
        geoBlockingDone: true,
        geoInitState: [wdwState, domState],
      });
    } else {
      let testError = Utilities.callErrorTest(call);
      if (testError !== false)
        this.props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };

  //e-Commerce CAll
  eCommerceInitCall = async () => {
    let eCommerceP = await GenericServices.get(
      "https://" +
        PROPERTIES.BASE_URL_OBP +
        "/sbcams/v1/card/" +
        this.props.cardInfo.cardId +
        "/mycontrol/ecommerce"
    );
    if (eCommerceP.status === 200) {
      this.setState({
        eCommerceStatus: eCommerceP.data?.ecommerceEnabled,
        eCommerceButtonCall: false,
        eCommerceDone: true,
      });

      if (eCommerceP.data?.ecommerceEnabled === false) {
        this.setState({
          suspendeCommPayButton: true,
        });
      }
    } else {
      let testError = Utilities.callErrorTest(eCommerceP);
      if (testError !== false)
        this.props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
    }
  };
  changeCardStatus = async () => {
    //Start spinner
    let callType = "changeCardStatus";

    let obj;
    if (this.state.cardStatus === "AA" || this.state.cardStatus === "AA_006" || this.state.cardStatus === "SI" || this.state.cardStatus === "PD") {
      obj = { state: "TS" };
    } else {
      obj = { state: "AA" };
    }
    let url =
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbcams/v1/card/" +
      this.props.cardInfo.cardId +
      "/state";

    let method = "put";

    let callInfo = {
      method: method,
      url: url,
      data: obj,
    };

    let test = await ScopeListTester.callTester(
      this.props.scopeListDuck.scopeList,
      method,
      url
    );

    this.setState({ callInfo, test, callType });

    if (!test) {
      this.setState({ isLoading: false });
      this.ChangePermitionsCall(callType);
    } else {
      let BCACall = await BcAuthorize.call(test.scope);
      this.setState({ isLoading: false });
      if (BCACall.status === 200) {
        if (this.state.cardStatus === "AA" || this.state.cardStatus === "AA_006" || this.state.cardStatus === "SI" || this.state.cardStatus === "PD") {
          BCACall.data.title = i18n.t("PopUp.SuspendTheCard");
          BCACall.data.description = i18n.t("PopUp.mfaSuspendTheCard");
        } else {
          BCACall.data.title = i18n.t("PopUp.ResumeTheCard");
          BCACall.data.description = i18n.t("PopUp.mfaResumeTheCard");
        }

        BCACall.data.scope = test.scope;

        if (BCACall?.showModal) {
          console.log("bcAuth error:", BCACall.modalMessage);
        } else {
          this.props.dispatch(setBcAuth(BCACall.data));
        }
      } else {
        let testError = Utilities.callErrorTest(BCACall);
        if (testError !== false) this.setState({ error: true });
        this.props.setError(
          testError.textMessage,
          testError.isCorse,
          testError.title
        );
      }
    }
  };
  changeECommercePermission = async () => {
    let callType = "changeECommerce";
    //Start spinner
    let obj;
    this.state.eCommerceStatus
      ? //suspend
        (obj = {
          ecommerceEnabled: false,
        })
      : (obj = {
          ecommerceEnabled: true,
        });

    let url =
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbcams/v1/card/" +
      this.props.cardInfo.cardId +
      "/mycontrol/ecommerce";

    let method = "put";

    let callInfo = {
      method: method,
      url: url,
      data: obj,
    };

    let test = await ScopeListTester.callTester(
      this.props.scopeListDuck.scopeList,
      method,
      url
    );

    this.setState({ callInfo, test, callType });

    if (!test) {
      this.ChangePermitionsCall(callType);
      this.setState({ isLoading: false });
    } else {
      let BCACall = await BcAuthorize.call(test.scope);
      this.setState({ isLoading: false });

      if (BCACall.status === 200) {
        if (this.state.eCommerceStatus) {
          BCACall.data.title = i18n.t("PopUp.SuspendECommercePayments");
          BCACall.data.description = i18n.t(
            "PopUp.mfaSuspendECommerceDescription"
          );
        } else {
          BCACall.data.title = i18n.t("PopUp.ResumeECommercePayments");
          BCACall.data.description = i18n.t(
            "PopUp.mfaResumeECommerceDescription"
          );
        }
        BCACall.data.scope = test.scope;

        if (BCACall?.showModal) {
          console.log("bcAuth error:", BCACall.modalMessage);
        } else {
          this.props.dispatch(setBcAuth(BCACall.data));
        }
      } else {
        let testError = Utilities.callErrorTest(BCACall);
        if (testError !== false)
          this.props.setError(
            testError.textMessage,
            testError.isCorse,
            testError.title
          );
      }
    }
  };

  changeGeoBlocking = async () => {
    //start Spinner
    let callType = "geoBlocking";

    let obj = [
      {
        enabled: this.state.WDWCheck,
        zone: "WDW",
      },
      {
        enabled: this.state.DOMCheck,
        zone: "DOM",
      },
    ];
    let url =
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbcams/v1/card/" +
      this.props.cardInfo.cardId +
      "/mycontrol/geoblocking";

    let method = "put";

    let callInfo = {
      method: method,
      url: url,
      data: obj,
    };

    let test = await ScopeListTester.callTester(
      this.props.scopeListDuck.scopeList,
      method,
      url
    );

    this.setState({ callInfo, test, callType });

    let geoNewState = [...this.state.geoInitState];

    if (!test) {
      let success = await this.ChangePermitionsCall(callType, null, obj[0]);
      if (success) {
        geoNewState[0] = this.state.WDWCheck;
        let success = this.ChangePermitionsCall(callType, null, obj[1]);
        if (success) {
          geoNewState[1] = this.state.DOMCheck;
        }
      }
      this.setState({
        geoInitState: geoNewState,
        WDWCheck: geoNewState[0],
        DOMCheck: geoNewState[1],
        isLoading: false,
      });
    } else {
      let BCACall = await BcAuthorize.call(test.scope);
      this.setState({ isLoading: false });
      if (BCACall.status === 200) {
        BCACall.data.title = i18n.t("PopUp.geoblocking");
        BCACall.data.description = i18n.t("PopUp.mfaGeoblockingDescription");
        BCACall.data.scope = test.scope;

        if (BCACall?.showModal) {
          console.log("bcAuth error:", BCACall.modalMessage);
        } else {
          this.props.dispatch(setBcAuth(BCACall.data));
        }
      } else {
        let testError = Utilities.callErrorTest(BCACall);
        if (testError !== false)
          this.props.setError(
            testError.textMessage,
            testError.isCorse,
            testError.title
          );
      }
    }
  };

  ChangePermitionsCall = async (callType, bctoken, checkBox) => {
    this.setState({ isLoading: true });
    let token;

    if (!bctoken) {
      const { access_token } = Utilities.getToken();
      token = access_token;
    } else {
      token = bctoken;
    }

    let reactiveCall = await axios({
      method: this.state.callInfo.method,
      url: this.state.callInfo.url,
      data: checkBox ? checkBox : this.state.callInfo.data,
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
        Language: "" + i18n.language.toUpperCase(),
      },
    })
      .catch((error) => {
        if (typeof error.response === "undefined") {
          this.setState({ isLoading: false });
          this.props.setError(i18n.t("Error.CorsRedirect"), true);
        } else {
          this.setState({ isLoading: false });
          let testError = Utilities.callErrorTest(error.response);
          if (testError !== false)
            this.props.setError(
              testError.textMessage,
              testError.isCorse,
              testError.title
            );
        }
      })
      .then((response) => {
        if (response?.status === 200) {
          if (callType === "changeCardStatus") {
            //BE status controll
            this.cardStatusInitCall();
            
            this.setState({
              isLoading: false,
            });
          }
          if (callType === "geoBlocking") {
            return true;
          }

          if (callType === "changeECommerce") {
            if (this.state.eCommerceStatus === true) {
              this.setState({
                showSuspendeCommPayModal: false,
                suspendeCommPayButton: true,
                eCommerceStatus: false,
                isLoading: false,
              });
            } else {
              this.setState({
                eCommerceStatus: true,
                suspendeCommPayButton: false,
                isLoading: false,
              });
            }
          }
          //   if (callType === "getPinIframe") {
          //     this.props.dispatch(setChangePinView(true));
          //     this.setState({
          //       iframePinData: response.data,
          //       isLoading: false,
          //     });
          //   }
        }
      });
    return reactiveCall;
  };

  getPinIframe = async () => {
    //Start loader
    this.setState({ isLoading: true });
    let callType = "getPinIframe";

    let url =
      "https://" +
      PROPERTIES.BASE_URL_OBP +
      "/sbcardimage/v1/getPinBlockChangePage/" +
      this.props.cardInfo.cardId;

    let method = "get";

    let callInfo = {
      method: method,
      url: url,
    };
    let test = await ScopeListTester.callTester(
      this.props.scopeListDuck.scopeList,
      method,
      url
    );

    this.setState({ callInfo, test, callType });
    if (!test) {
      let temp = JSON.parse(window.sessionStorage?.getItem("logged"));
      let access_token = get(temp, "access_token", null);
      this.setState({
        isLoading: false,
        changePinSpecialToken: access_token,
      });
      this.props.dispatch(setChangePinView(true));
      // this.ChangePermitionsCall(callType);
    } else {
      let BCACall = await BcAuthorize.call(test.scope);
      this.setState({ isLoading: false });
      if (BCACall.status === 200) {
        BCACall.data.title = i18n.t("PopUp.GetChangeCardPin");
        BCACall.data.description = i18n.t(
          "PopUp.mfaGetChangeCardPinDescription"
        );
        BCACall.data.scope = test.scope;

        if (BCACall?.showModal) {
          console.log("bcAuth error:", BCACall.modalMessage);
        } else {
          this.props.dispatch(setBcAuth(BCACall.data));
        }
      } else {
        let testError = Utilities.callErrorTest(BCACall);
        if (testError !== false)
          this.props.setError(
            testError.textMessage,
            testError.isCorse,
            testError.title
          );
      }
    }
  };

  componentDidUpdate = async () => {
    if (!this.state.error) {
      if (
        this.props.bcTokenDuck.bctoken?.token &&
        this.props.bcTokenDuck.bctoken?.scope === this.state.test?.scope
      ) {
        if (
          this.state.callType === "geoBlocking" &&
          !(
            this.state.WDWCheck === this.state.geoInitState[0] &&
            this.state.DOMCheck === this.state.geoInitState[1]
          ) &&
          !this.noGeoLoop
        ) {
          this.noGeoLoop = true;
          let myToken = this.props.bcTokenDuck.bctoken.token;
          let geoNewState = [...this.state.geoInitState];
          let success = await this.ChangePermitionsCall(
            this.state.callType,
            myToken,
            this.state.callInfo.data[0]
          );
          if (success) {
            geoNewState[0] = this.state.WDWCheck;
            let success2 = await this.ChangePermitionsCall(
              this.state.callType,
              myToken,
              this.state.callInfo.data[1]
            );
            if (success2) {
              geoNewState[1] = this.state.DOMCheck;
            }
          }
          this.setState({
            geoInitState: geoNewState,
            isLoading: false,
          });
          this.noGeoLoop = false;
        } else {
          if (this.state.callType !== "geoBlocking") {
            //Change Pin controll
            if (this.state.callType === "getPinIframe") {
              this.setState({
                changePinSpecialToken: this.props.bcTokenDuck.bctoken.token,
              });
              this.props.dispatch(setChangePinView(true));
            } else {
              this.ChangePermitionsCall(
                this.state.callType,
                this.props.bcTokenDuck.bctoken.token
              );
            }
          }
        }
        this.props.dispatch(initBcToken());
        this.setState({ test: null });
      }
    } else {
      this.setState({ error: false });
    }
  };

  render() {
    return this.state.showChangeEPinScreen ? (
      //CHANGE E-PIN SCREEN
      <ChangeEPin
        cardID={this.props.cardInfo.cardId}
        goBackFunc={() => {
          this.cardStatusInitCall(true)
          this.setState({ showChangeEPinScreen: false });
        }}
        setError={(text, isCorse, title) =>
          this.props.setError(text, isCorse, title)
        }
      />
    ) : (
      //BASE VIEW + SPINNER OR PRINCIPAL SCREEN OR IFRAMEPIN
      <div className="CSPrincContainer">
        <div
          className="headerRow"
          style={{
            display:
              (this.props.navigationDuck.changePinView ||
                this.state.showSuccessChangePINScreen) &&
              "none",
          }}
        >
          <label>{i18n.t("SideBar.CardSetting")}</label>
        </div>
        {!this.state.eCommerceDone ||
        !this.state.geoBlockingDone ||
        !this.state.cardStatus ? (
          <div className="cs_secondondarySpinner_Continer">
            <Spinner1 />
          </div>
        ) : //CHANGE CARD PIN IFRAME
        this.props.navigationDuck.changePinView ? (
          <div className="changePin_Princ_Container">
            <div className="changePin_headerRow">
              <div className="changePin_headerRow_Title">
                <label>{i18n.t("ChangeCardPIN.Title")}</label>
              </div>
              <div className="changePin_headerRow_History">
                <label
                  className="changePin_Navigability"
                  onClick={() => {
                    this.props.dispatch(setChangePinView(false));
                  }}
                >
                  {i18n.t("CardSetting.CardSetting")}
                </label>
                <label style={{ color: "red", fontWeight: "700" }}>
                  {" > "}
                </label>
                <label className="changePin_Navigability_right">
                  {i18n.t("ChangeCardPIN.Title")}
                </label>
              </div>
            </div>
            <IframePinParent
              changePinSpecialToken={this.state.changePinSpecialToken}
              cardId={this.props.cardInfo.cardId}
              onSuccess={() =>
                this.setState({ showSuccessChangePINScreen: true })
              }
              setError={(text, isCorse, title) =>
                this.props.setError(text, isCorse, title)
              }
            />
          </div>
        ) : //SUCCESS CHANGE PIN SCREEN
        this.state.showSuccessChangePINScreen ? (
          <SuccessChangePin
            goBackFunc={() => {
              this.setState({ showSuccessChangePINScreen: false });
            }}
          />
        ) : (
          //PRINCIPAL SCREEN
          <>
            {
              //AFTER FIRST LOGIN SPINNER
              this.state.isLoading && (
                <div className="CS_AfterCall_Spinner">
                  <SpinnerLogin />
                </div>
              )
            }

            <div className="cardConfiguration_Container">
              <div className="cardConfiguration_Title">
                <label>{i18n.t("CardSetting.CardConfiguration")}</label>
              </div>

              {/* Temporarily suspend the card box */}
              <div className="cardConfiguration_SuspendTheCard_Container">
                <div className="cardConfiguration_SuspendTheCard_Text_Container">
                  <div className="cardConfiguration_SuspendTheCard_SubTitle">
                    <label>
                      {i18n.t("CardSetting.TemporarilySuspendTheCard")}
                    </label>
                  </div>

                  <div className="cardConfiguration_SuspendTheCard_StatusLabel_Container">
                    <p>{i18n.t("CardSetting.CardStatus")}</p>
                    <p
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: "700",
                        fontFamily: "Sparkasse_Bd",
                        color:
                          (this.state.cardStatus === "AA") ? "darkgreen" : (this.state.cardStatus === "PD" || this.state.cardStatus === "SI" || this.state.cardStatus === "AA_006")? "gray" :"red"
                      }}
                    >
                      {Utilities.cardStatusConverter(this.state.cardStatus)}
                    </p>
                  </div>
                </div>

                {/*Switch button controller (Button for Suspend or Resume card)*/}
                <div className="cardConfiguration_SuspendTheCard_Button_Container">
                  <UiButton
                    title={
                      this.state.SuspendCardButton
                        ? i18n.t("Buttons.ResumeTheCard")
                        : i18n.t("Buttons.SuspendTheCard")
                    }
                    disabled={!this.state.cardStatusButtonCall}
                    press={() => {
                      this.setState({ showSuspendCardModal: true });
                    }}
                  />
                </div>
              </div>

              {/* Select Geographic Area box */}
              <div className="cardConfiguration_SelectGeographicArea_Container">
                <div className="cardConfiguration_SelectGeographicArea_Text_Container">
                  <div className="cardConfiguration_SelectGeographicArea_Title">
                    <label>{i18n.t("CardSetting.GeographicArea")}</label>
                  </div>
                  <div className="cardConfiguration_SelectGeographicArea_SubTitle">
                    <label>{i18n.t("CardSetting.GeographicAreaSubTitle")}</label>
                  </div>
                  <div className="cardConfiguration_SelectGeographicArea_CheckBox_Conteiner">
                    <div>
                      <Checkbox
                        defaultChecked={true}
                        disabled={this.state.cardStatus !== "AA" && this.state.cardStatus !== "AA_006" && this.state.cardStatus !== "PD" && this.state.cardStatus !== "SI"}
                        onChange={(e) => {
                          this.setState({
                            DOMCheck: !this.state.DOMCheck,
                          });
                        }}
                        checked={this.state.DOMCheck}
                      >
                        {i18n.t("Checkboxes.Germany")}
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          this.setState({
                            WDWCheck: !this.state.WDWCheck,
                          });
                        }}
                        disabled={this.state.cardStatus !== "AA" && this.state.cardStatus !== "AA_006" && this.state.cardStatus !== "PD" && this.state.cardStatus !== "SI"}
                        checked={this.state.WDWCheck}
                      >
                        {i18n.t("Checkboxes.RestOfTheWorld")}
                      </Checkbox>
                    </div>
                  </div>
                </div>

                <div className="cardConfiguration_SelectGeographicArea_Button_Container">
                  <UiButton
                    title={i18n.t("Buttons.Confirm")}
                    disabled={
                      (this.state.WDWCheck === this.state.geoInitState[0] &&
                        this.state.DOMCheck === this.state.geoInitState[1]) ||
                      (this.state.cardStatus !== "AA" && this.state.cardStatus !== "AA_006" && this.state.cardStatus !== "PD" && this.state.cardStatus !== "SI")
                    }
                    press={() => {
                      this.setState({ showGeoBlockModal: true });
                    }}
                  />
                </div>
              </div>

              {/* Change card pin button box */}
              <div className="cardConfiguration_ChangeCardPin_Container">
                <div
                  className="cardConfiguration_ChangeCardPin_Item"
                  style={{
                    cursor:
                      (this.state.cardStatus === "AA" || this.state.cardStatus ==="AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI")
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onMouseOver={() => this.setState({ buttonHover: 0 })}
                  onMouseOut={() => this.setState({ buttonHover: null })}
                  onClick={() => {
                    if (this.state.cardStatus === "AA" || this.state.cardStatus === "AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI") this.getPinIframe();
                  }}
                >
                  <label
                    style={{
                      cursor:
                        (this.state.cardStatus === "AA" || this.state.cardStatus === "AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI")
                          ? "pointer"
                          : "not-allowed",
                      paddingRight: "20px",
                      fontFamily: "Sparkasse_Bd",
                    }}
                  >
                    {i18n.t("CardSetting.ChangeCardPin")}
                  </label>
                  {this.state.buttonHover === 0 &&
                  (this.state.cardStatus === "AA" || this.state.cardStatus ==="AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI") ? (
                    <RightCircleFilled />
                  ) : (
                    <RightCircleOutlined />
                  )}
                </div>
              </div>

              {/* Show Suspend Card (not custom test )Modal */}
              <UiModal
                visible={this.state.showSuspendCardModal}
                title={
                  this.state.SuspendCardButton
                    ? i18n.t("CardSetting.ResumeTheCard")
                    : i18n.t("CardSetting.TemporarilySuspendTheCard")
                }
                text={
                  this.state.SuspendCardButton
                    ? i18n.t("CardSetting.AreYouSureYouWantResumeTheCard")
                    : i18n.t("CardSetting.AreYouSureYouWantSuspendTheCard")
                }
                noButtonText={i18n.t("Buttons.NO")}
                noButtonFunc={() => {
                  this.setState({ showSuspendCardModal: false });
                }}
                yesButtonText={i18n.t("Buttons.YES")}
                yesButtonFunc={() => {
                  this.changeCardStatus();
                  this.setState({
                    showSuspendCardModal: false,
                    isLoading: true,
                  });
                }}
              />
              {/* Show Geographic (GeoBlock) Modal */}
              <UiModal
                visible={this.state.showGeoBlockModal}
                title={i18n.t("CardSetting.ModalGeoBlockingTitle")}
                text={i18n.t("CardSetting.ModalGeoBlockingDescription")}
                noButtonText={i18n.t("Buttons.NO")}
                noButtonFunc={() => {
                  this.setState({ showGeoBlockModal: false });
                }}
                yesButtonText={i18n.t("Buttons.YES")}
                yesButtonFunc={() => {
                  this.changeGeoBlocking();
                  this.setState({ showGeoBlockModal: false, isLoading: true });
                }}
              />

              {/* E-COMMERCE PAYMENTS */}
              <div className="eCommerceP_Container">
                <div className="eCommerceP_Title">
                  <label>{i18n.t("CardSetting.eCommercePayments")}</label>
                </div>

                <div className="eCommerceP_Suspend_Container">
                  <div className="eCommerceP_Text_Container">
                    <div className="eCommerceP_SubTitle">
                      <label>{i18n.t("CardSetting.ECommercePayments")}</label>
                    </div>

                    <div className="eCommerceP_StatusLabel_Container">
                      <p>{i18n.t("CardSetting.ECommercePayments")}</p>
                      <p
                        style={{
                          marginLeft: "0.5rem",
                          fontWeight: "700",
                          fontFamily: "Sparkasse_Bd",
                          color: this.state.eCommerceStatus
                            ? "darkgreen"
                            : "red",
                        }}
                      >
                        {this.state.eCommerceStatus
                          ? i18n.t("CardSetting.Active")
                          : i18n.t("CardSetting.Inactive")}
                      </p>
                    </div>
                  </div>

                  {/*Switch button controller (Button for Resume or suspend e-Commerce Payments)*/}
                  <div className="eCommerceP_Suspend_Button_Container">
                    <UiButton
                      title={
                        this.state.suspendeCommPayButton
                          ? i18n.t("Buttons.ResumeECommercePayments")
                          : i18n.t("Buttons.SuspendECommercePayments")
                      }
                      disabled={
                        this.state.eCommerceButtonCall ||
                        (this.state.cardStatus !== "AA" && this.state.cardStatus !== "AA_006" && this.state.cardStatus !== "PD" && this.state.cardStatus !== "SI")
                      }
                      press={() => {
                        this.setState({ showSuspendeCommPayModal: true });
                      }}
                    />
                  </div>
                </div>

                <div className="eCommerceP_ChangeEPin_Container">
                  <div
                    className="eCommerceP_ChangeEPin_Item"
                    style={{
                      cursor:
                        (this.state.cardStatus === "AA" || this.state.cardStatus ==="AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI")
                          ? "pointer"
                          : "not-allowed",
                    }}
                    onClick={() => {
                      if (this.state.cardStatus === "AA" || this.state.cardStatus === "AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI")
                        this.setState({ showChangeEPinScreen: true });
                    }}
                    onMouseOver={() => this.setState({ buttonHover: 1 })}
                    onMouseOut={() => this.setState({ buttonHover: null })}
                  >
                    <label
                      style={{
                        cursor:
                          (this.state.cardStatus === "AA" ||this.state.cardStatus ==="AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI")
                            ? "pointer"
                            : "not-allowed",
                      }}
                    >
                      {i18n.t("CardSetting.ChangeEPin")}
                    </label>
                    {this.state.buttonHover === 1 &&
                    (this.state.cardStatus === "AA" || this.state.cardStatus ==="AA_006" || this.state.cardStatus === "PD" || this.state.cardStatus === "SI") ? (
                      <RightCircleFilled />
                    ) : (
                      <RightCircleOutlined />
                    )}
                  </div>
                </div>
              </div>
              {/* Show Suspend e-Commerce Payment (not custom test )Modal */}
              <UiModal
                visible={this.state.showSuspendeCommPayModal}
                title={
                  this.state.suspendeCommPayButton
                    ? i18n.t("Buttons.ResumeECommercePayments")
                    : i18n.t("Buttons.SuspendECommercePayments")
                }
                text={
                  this.state.suspendeCommPayButton
                    ? i18n.t(
                        "CardSetting.AreYouSureYouWanttoResumeECommercePayments"
                      )
                    : i18n.t(
                        "CardSetting.AreYouSureYouWanttoSuspendECommercePayments"
                      )
                }
                noButtonText={i18n.t("Buttons.NO")}
                noButtonFunc={() => {
                  this.setState({ showSuspendeCommPayModal: false });
                }}
                yesButtonText={i18n.t("Buttons.YES")}
                yesButtonFunc={() => {
                  //Yes button (suspend eCommerce Payments)
                  this.changeECommercePermission();
                  this.setState({
                    showSuspendeCommPayModal: false,
                    isLoading: true,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    );
    //END OF PRINCIPAL SCREEN
  }
}
const mapStateToProps = (state) => ({
  navigationDuck: state.navigationDuck,
  scopeListDuck: state.scopeListDuck,
  bcTokenDuck: state.bcTokenDuck,
});

export default connect(mapStateToProps)(CardSetting);
