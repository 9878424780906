//Actions
const SET_PROMOTIONS='web/promotions/SET_PROMOTIONS'
const SET_LOADER='web/promotions/SET_LOADER'

//Actiond Set_Promotions creator
export function setPromotions(valueObj){
    return{
        type: SET_PROMOTIONS,
        paylod:{
            promotionsBannerList:valueObj,
            recordsNumber:valueObj.length
        }
    }
}
export function setLoader(value){
    return {
        type: SET_LOADER,
        paylod:{
            isLoading:value
        }
    }
}
const INIT_STATE = {
    recordsNumber: null,
    promotionsBannerList: null,
    isLoading: false
}

//Reducer
export default function promotionsDuck(state = INIT_STATE, action){
    var newState
    switch (action.type) {
        case SET_PROMOTIONS:
            newState = Object.assign({}, state)
            newState.promotionsBannerList = action.paylod.promotionsBannerList
            newState.recordsNumber = action.paylod.recordsNumber
            return newState;
        case SET_LOADER:
            newState = Object.assign({}, state)
            newState.isLoading = action.paylod.isLoading
            return newState;
        default:
            return state;
    }
}