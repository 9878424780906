import React, { Component } from "react";
//Ui_COMPONENT
import UiButton from "../../funcComponent/UiComponents/UiButton";
import UiCardTitle from "../../funcComponent/UiComponents/UiCardTitle";
//TRANSLATIONS
import i18n from "../../../I18next";
//icons
import SendNewCodeIco from "../../../asset/svg/SendNewCodeIco";
import IcoPhone from "../../../asset/svg/IcoPhone";

//ActivationServices
import ActivationServices from "../../../services/ActivationServices";
import { PROPERTIES } from "../../../utils/properties";
//Temporary icon
import { LeftOutlined } from "@ant-design/icons";

export default class SendNewCode extends Component {
  sendNewCall = async () => {
    if (this.props.popUpModal === true) {
      this.props.isLoading(true);
      let querystring = require("querystring");
      let newModalOTP = await ActivationServices.post(
        "https://" + PROPERTIES.BASE_URL_NPC + "/sbauth/v1/generateotp",
        querystring.stringify({
          auth_req_id: this.props.auth_req_id,
        })
      );
      if (newModalOTP.status === 667) {
        this.props.isLoading(false);
        let isCorse = true;
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
      }
      if (newModalOTP.status === 200) {
        this.props.isLoading(false);
        if (this.props.isFromEPin) {
          this.props.setProgressive(108);
        } else {
          this.props.setProgressive("_succes_Login");
        }
      }
      if (newModalOTP.status === 400) {
        this.props.isLoading(false);
        let isCorse = false;
        let title = ""
        let modalText = "";

        //Omega control
        if (newModalOTP.data?.errorMessage?.split("Ω").length === 2) {
          let message = newModalOTP.data?.errorMessage.split("Ω")
          title = message[0]
          modalText = message[1]
        } else {
          modalText = newModalOTP.data?.errorMessage
        }
        this.props.setError(modalText, isCorse, title);
      }
      if (newModalOTP.status === 500) {
        this.props.isLoading(false);
        let title = ""
        let modalText = "";

        //Omega control
        if (newModalOTP.data?.errorMessage?.split("Ω").length === 2) {
          let message = newModalOTP.data?.errorMessage.split("Ω")
          title = message[0]
          modalText = message[1]
        } else {
          //"500 null" CONTROLL message
          if (
            newModalOTP.data.errorMessage === "500 null" ||
            newModalOTP.data?.errorMessage === undefined ||
            newModalOTP.data?.errorMessage.substring(0, 21) === "Internal server error"
          ) {
            modalText = i18n.t("Error.NewOtp_generic");
          } else {
            modalText = newModalOTP.data.errorMessage;
            title = i18n.t("Error.TooManyAttempts");
          }
        }
        let isCorse = false;
        this.props.setError(modalText, isCorse, title);
      }
    } else {
      this.props.isLoading(true);
      let newCodeCall = await ActivationServices.get(
        this.props.url + "/sendnewotpsms"
      );
      if (newCodeCall.status === 667) {
        this.props.isLoading(false);
        let isCorse = true;
        this.props.setError(i18n.t("Error.CorsRedirect"), isCorse);
      }
      if (newCodeCall.status === 200) {
        this.props.isLoading(false);
        this.props.setProgressive(newCodeCall.data.state);
      }

      if (newCodeCall.status === 400 || newCodeCall.status === 500) {
        this.props.isLoading(false);
        let title = ""
        let modalText = "";

        //Omega control
        if (newCodeCall.data?.errorMessage?.split("Ω").length === 2) {
          let message = newCodeCall.data?.errorMessage.split("Ω")
          title = message[0]
          modalText = message[1]
        } else {
          //"500 null" CONTROLL message
          if (
            newCodeCall.data.errorMessage === "500 null" ||
            newCodeCall.data?.errorMessage === undefined ||
            newCodeCall.data?.errorMessage.substring(0, 21) === "Internal server error"
          ) {
            modalText = i18n.t("Error.NewOtp_generic");
          } else {
            modalText = newCodeCall.data.errorMessage;
            title = i18n.t("Error.TooManyAttempts");
          }
        }
        let isCorse = false;
        this.props.setError(modalText, isCorse, title);
      }
    }
  };

  render() {
    return (
      <>
        <div
          className="goBackIcon"
          style={{ display: this.props.display && "none" }}
        >
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => this.props.goBack()}
          />
        </div>
        <div
          className="loginInputCardContainer"
          style={{ display: this.props.display && "none" }}
        >
          <UiCardTitle text={i18n.t("NewCode.TitleText")} />
          <p
            style={{
              marginBottom: "1.3rem",
              textAlign: "left",
              fontFamily: "Sparkasse_Rg"
            }}
          >
            {i18n.t("NewCode.Description")}
          </p>

          <div className="loginButtonContainer">
            <div className="loginButtonItem">
              <UiButton
                title={i18n.t("Buttons.NewCode")}
                press={() => {
                  this.sendNewCall();
                }}
              />
            </div>
          </div>
          <div className="loginImgContainer">
            <SendNewCodeIco />
          </div>

          <p
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              textAlign: "left",
              fontFamily: "Sparkasse_Rg"
            }}
          >
            {i18n.t("NewCode.FooterDescription")}
          </p>
          <div className="newCodePhoneRaw">
            <IcoPhone />
            <p style={{ margin: "1rem", textAlign: "left" }}>
              {i18n.t("NewCode.Phone") + " +49 30 620 080 8008"}
            </p>
          </div>
        </div>
      </>
    );
  }
}
