import React, { useState, useRef } from "react";

import "./css/uiButtonCarousel.css";
//ICON
import ButtonGrayOn from "../../../asset/svg/ButtonGreyOn";
import ButtonGreyOff from "../../../asset/svg/ButtonGreyOff";
import ButtonGreyOffReverse from "../../../asset/svg/ButtonGreyOffReverse"
import ButtonGreyOnReverse from "../../../asset/svg/ButtonGreyOnReverse";

export default function UiButtonCarousel(props) {
  const [nextHover, setNextHover] = useState(false);
  const [backHover, setBackHover]=useState(false)
  const [cont, setCont] = useState(0)
  const [initial, setInitial] = useState(0)
  const mask = useRef()


  const nextButton = () => {
    var min;
    var amount = 11;
    var stop=mask.current.clientWidth >= (props.data.length+1)*amount*16;
    if(window.innerWidth<= 408){
      //mobile vercion
      min = -1 * props.data.length + 1;
    } else if (window.innerWidth >= 1464 ) {
      //Desktop
      min = -1 * props.data.length + 6;
    } else if (window.innerWidth >= 1290 && window.innerWidth < 1464) {
      //Desktop windows big
      min = -1 * props.data.length + 5;
    }else if (window.innerWidth >= 1115 && window.innerWidth < 1290) {
      //Desktop windows medium
      min = -1 * props.data.length + 4;
    }else if (window.innerWidth >= 933 && window.innerWidth < 1115) {
      //Desktop windows small
      min = -1 * props.data.length + 3;
    }else{
      //ipad & web vercions
      min= -1 * props.data.length + 2;
    } 
    
    if (cont > min && !stop) {
      let init= initial - amount
      setCont(cont - 1)
      document.getElementById("carouselArray").style.transform = "translateX(" + init + "rem)";
      document.getElementById("carouselArray").style.transition = "0.5s";
      setInitial(initial - amount)
    }
  }

  const backButton = () =>{
    var max = props.data.length - 2;
    var amount = 11;

    if (cont < max && cont !== 0) {
      let init= initial + amount
      setCont(cont + 1)
      document.getElementById("carouselArray").style.transform = "translateX(" + init + "rem)";
      document.getElementById("carouselArray").style.transition = "0.5s";
      setInitial(initial + amount)
    }
  }


  return (
    <>
      <div
        id={"backButtonCarousel"}
        className={"carouselDirectionalButtonArrow"}
        onMouseOver={() => {
          setBackHover(true);
        }}
        onMouseOut={() => {
          setBackHover(false);
        }}
        onClick = {()=>backButton()}
      >
        {backHover ? <ButtonGreyOnReverse /> : <ButtonGreyOffReverse />}
      </div>

      <div ref={mask} className="buttonArrayMask" style={{maxWidth:"calc("+ props.data.length*11+"rem)"}}>
        <div className={"buttonCarouselContainer"} id="carouselArray">
          {props.data &&
            props.data.map((item, index) => (
              <button
                key={index}
                type="button"
                className={`uiButtonCarousel_button ${
                  item.selected === true && "selected"
                } `}
                onClick={() => {
                  let temp = props.data;
                  for(let i=0; i<props.data.length; i++){
                    if(temp[i].selected && i!==index){
                      temp[i].selected= false
                    }
                  }
                  temp[index].selected = !temp[index].selected;
                  props.callBackFunc(temp);
                }}
              >
                {item.category}
              </button>
            ))}
        </div>
      </div>
      {/* Next button */}
      <div
        id={"nextButtonCarousel"}
        className={"carouselDirectionalButtonArrow"}
        onMouseOver={() => {
          setNextHover(true);
        }}
        onMouseOut={() => {
          setNextHover(false);
        }}
        onClick = {()=>nextButton()}
      >
        {nextHover ? <ButtonGrayOn /> : <ButtonGreyOff />}
      </div>
    </>
  );
}
