//Action
const SET_SCOPELIST = 'web/scopeList/SET_SCOPELIST'
const INIT_SCOPELIST = 'web/scopeList/INIT_SCOPELIST'

//Action Set_ScopeList Creators
export function setScopeList(valueObj) {
    return {
        type: SET_SCOPELIST,
        paylod: {
            scopeList: valueObj
        }
    }
}
//Action init_ScopeList Creators
export function initScopeList() {
    return {
        type: INIT_SCOPELIST,
        paylod: {}
    }
}

const INIT_STATE = {
    scopeList: null
}

//Reducer
export default function scopeListDuck(state = INIT_STATE, action) {
    var newState
    switch (action.type) {
        case SET_SCOPELIST:
            newState = Object.assign({}, state)
            newState.scopeList = action.paylod.scopeList
            return newState;
        case INIT_SCOPELIST:
            newState = Object.assign({}, state)
            newState.scopeList = {}
            return newState
        default:
            return state;
    }
}